import { DisplayMode, LowerLegOptionKey, RegionTemplate } from './types';

export const lowerLeg: { lower_leg: RegionTemplate<LowerLegOptionKey> } = {
  lower_leg: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'knee',
        label: 'Knee',
      },
      {
        key: 'bone',
        label: 'Bone',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'dislocated_knee',
        label: 'Dislocated knee',
        tabs: ['knee'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
        ],
      },
      {
        key: 'hyperextension_knee',
        label: 'Hyperextended knee',
        tabs: ['knee'],
      },
      {
        key: 'sprain_strain',
        label: 'Sprain / strain',
        tabs: ['knee'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
        ],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['knee'],
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
      },
      {
        key: 'acl_meniscus',
        label: 'Torn ACL / meniscus',
        tabs: ['knee'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
        ],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['bone'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['bone'],
        popular_in_modes: [DisplayMode.PROPERTY],
      },
      {
        key: 'severed_lower_leg',
        label: 'Severed',
        tabs: ['bone'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'amputation',
        label: 'Amputated',
        tabs: ['bone'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'crushed_lower_leg',
        label: 'Crushed',
        tabs: ['bone'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'burn_scald',
        label: 'Burn / scald',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'majorBurn',
            label: 'Major burn?',
          },
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'dermatitis',
        label: 'Dermatitis',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection',
        label: 'Infection',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
  },
};
