const FuzzySet = require('fuzzyset.js').default || require('fuzzyset.js');

type CodeToStateMapping = typeof codeToStateMapping;
export const codeToStateMapping = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FM: 'Federated States Of Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const states = Object.values(codeToStateMapping);
export const stateAbbreviations = Object.keys(codeToStateMapping);

const _states = new FuzzySet(states);
export const fuzzyState = (raw: string) => {
  if (!raw) {
    return undefined;
  }
  const found = _states.get(raw);
  if (found?.length) {
    return found[0][1];
  }
};

// TODO this is returning a value or empty string. That is not good. But we'll have to
// fix all the places that call it.
export const stateToCode = (raw?: string) => {
  if (!raw) {
    return '';
  }
  const name = fuzzyState(raw);
  return (
    Object.entries(codeToStateMapping)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([_, value]) => value === name)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([key, _]) => key)
      // findFirstOrUndefine;
      .find(x => x) || ''
  );
};

// TODO this is returning value or NULL which is not good be less not good. When we fix
// the above we should standardize this on undefined
export const codeToState = (code?: string) => {
  if (code && code.toUpperCase() in codeToStateMapping) {
    return codeToStateMapping[code.toUpperCase() as keyof CodeToStateMapping];
  }
  return null;
};
