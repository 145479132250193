import Highlighter from 'react-highlight-words';

interface SearchTermAndInput {
  /** Input string to search */
  input: string;

  /** Term to highlight */
  term: string;
}

export interface HighlightedTextProps extends SearchTermAndInput {
  /** `className` to apply to the highlighted term */
  classNameHighlighted: string;
}

export const HighlightedText = ({
  classNameHighlighted,
  input,
  term,
}: HighlightedTextProps) => {
  return (
    <Highlighter
      highlightClassName={classNameHighlighted}
      searchWords={term.split(' ')}
      textToHighlight={input}
      highlightTag="span"
      autoEscape={true}
    />
  );
};
