import { lazy } from 'react';
import { config } from '~/src/config';
import { useTenantConfigWithFallback } from '~/src/hooks';

const GuidedPhotoCapture = lazy(() =>
  import(
    '@assured/guided-photo-capture/src/components/GuidedPhotoCapture'
  ).then(module => ({
    default: module.GuidedPhotoCapture,
  })),
);

export const GuidedPhotoCaptureRoutes = () => {
  const tenantConfig = useTenantConfigWithFallback();
  if (!tenantConfig) {
    return null;
  }

  return <GuidedPhotoCapture config={config} tenantConfig={tenantConfig} />;
};
