import { gql } from '@apollo/client';

export const FIND_WORKFLOW_DRAFT = gql`
  mutation findWorkflowDraft($type: WorkflowType) {
    findWorkflowDraft(type: $type) {
      success
      errors
      data {
        requires2Fac
        claimId
      }
    }
  }
`;
