import type { StepComponentSpec } from '../stepComponentSpec';
import { RichLocation } from '@assured/shared-types/Location/Location';

type SimpleLocation = Required<Pick<RichLocation, 'addressText'>> &
  Partial<RichLocation>;
export interface UnderwritingMemoAddressFields {
  garagingAddress?: SimpleLocation;
  mailingAddress?: SimpleLocation;
  isMailingAddressTheSame: boolean;
}

export function isReadyToContinue(value: UnderwritingMemoAddressFields) {
  return (
    !!value?.garagingAddress?.addressText &&
    (!!value?.mailingAddress?.addressText || value?.isMailingAddressTheSame)
  );
}

type UnderwritingMemoAddressStepComponentSpec = StepComponentSpec<
  {
    existing_value: UnderwritingMemoAddressFields;
  },
  'underwriting_memo_address'
>;

export default UnderwritingMemoAddressStepComponentSpec;
