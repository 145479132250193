import React, { useEffect, useState } from 'react';

import NumberSegmentedInput from '@assured/ui/components/NumberSegmentedInput';

import { formatPhoneNumber } from '../../utils';
import Modal from '../Modal';

interface ITwoFactorModalProps {
  open: boolean;
  data?: {
    recipient: string;
    success: boolean;
  };
  onSubmitCode: (code: string) => void;
  onDiscard: () => void;
}

const TwoFactorModal: React.FC<ITwoFactorModalProps> = props => {
  const [enteredTwoFactorCode, setEnteredTwoFactorCode] = useState('');

  useEffect(() => {
    setEnteredTwoFactorCode('');
  }, [props.data]);
  return (
    <Modal
      open={props.open}
      bigFormat
      title={<>Verify your identity</>}
      body={
        <div>
          <div>
            {!props.data?.success ? (
              <span className="text-red-600">
                The code did not match. Please try again.
              </span>
            ) : (
              <>
                To make sure it's really you, please enter the 6 digit code
                texted to{' '}
                {props.data?.recipient
                  ? formatPhoneNumber(props.data.recipient)
                  : 'you'}
                .
              </>
            )}
          </div>
          <div className="mt-6 mb-8">
            <NumberSegmentedInput
              digits={6}
              chunkSize={6}
              value={enteredTwoFactorCode}
              onChange={code => setEnteredTwoFactorCode(code)}
              onFinish={() => props.onSubmitCode(enteredTwoFactorCode)}
              autoFocus
            />
          </div>
        </div>
      }
      actions={[
        {
          title: 'Start over instead',
          primary: false,
          onClick: () => props.onDiscard(),
        },
      ]}
    />
  );
};

export default TwoFactorModal;
