import { DateTime } from 'luxon';

import Datepicker from '@assured/ui/components/Datepicker';

type DesktopDateTimeProps = {
  dateMustBeBefore?: DateTime;
  dateMustBeAfter?: DateTime;
  wideDisplay?: boolean;
  dateOnly?: boolean;
  value?: DateTime;
  hashedField?: string;
  onChange: (date?: Date) => void;
};

export const DesktopDateTime = ({
  dateMustBeBefore,
  dateMustBeAfter,
  wideDisplay,
  dateOnly,
  value,
  hashedField,
  onChange,
}: DesktopDateTimeProps) => {
  return (
    <div>
      <Datepicker
        value={value?.toJSDate()}
        id={hashedField}
        ariaLabel="select date"
        ariaLabelledBy="date-label"
        wide={wideDisplay}
        onChange={onChange}
        showTimeSelect={!dateOnly}
        maxDate={dateMustBeBefore?.toJSDate()}
        minDate={dateMustBeAfter?.toJSDate()}
      />
    </div>
  );
};
