import React, { lazy } from 'react';

import { IllustrationType } from '@assured/step-renderer';
import { snakeToSentence } from '@assured/step-renderer/helpers/stringsUtils';

const Lottie = lazy(() => import('lottie-react'));

interface ExampleIllustrationProps {
  /** String that is used to determine which value to grab from the dict */
  type: IllustrationType;
}

const IllustrationPaths: { [key: string]: any } = {
  accident_wide_scene: require('../../images/example_illustrations/accident_wide_scene.svg'),
  close_up_damage: require('../../images/example_illustrations/damage_closeup.svg'),
  drivers_license: require('../../images/example_illustrations/drivers_license.svg'),
  insurance_card: require('../../images/example_illustrations/insurance_card_animation_data.json'),
  license_plate: require('../../images/example_illustrations/license_plate.svg'),
  collision_reconstruction: require('../../images/example_illustrations/collision_reconstruction_animation_data.json'),
  receipt: require('../../images/example_illustrations/receipt.svg'),
};

const ExampleIllustration: React.FC<ExampleIllustrationProps> = ({ type }) => {
  return (
    <>
      {type === 'insurance_card' || type === 'collision_reconstruction' ? (
        <Lottie
          // TODO @{lbarrick} change the animationData prop to path when lottie-react releases v3
          animationData={IllustrationPaths[type]}
          className="mb-4 w-full"
        />
      ) : (
        <img
          alt={snakeToSentence(type)}
          className="mb-4 w-full"
          src={IllustrationPaths[type]}
        />
      )}
    </>
  );
};

export default ExampleIllustration;
