import React from 'react';
import { Link } from 'react-router-dom';

import { ApolloError } from '@apollo/client';

import { useTenantConfig } from '../hooks';
import Base from '../layouts/Base';
import { useAuth0 } from '../services/auth';
import { formatPhoneNumber } from '../utils';
import Heading from './Heading';
import Modal from './Modal';

interface GenericErrorPageProps {
  error?: ApolloError;
  isExpiredToken?: boolean;

  title?: string;
  errorMessage?: string;
  showRestartButton?: boolean;

  // Maintenance window message
  maintenanceWindowMessage?: string;

  /** Display the error as a modal instead of inline */
  modal?: boolean;
}

const ErrorContainer = ({
  modal,
  children,
}: {
  modal?: boolean;
  children: React.ReactNode;
}) => {
  if (modal) {
    return (
      <Modal
        open
        body={children}
        title={null}
        actions={[
          {
            title: 'Reload',
            primary: true,
            onClick: () => window.location.reload(),
          },
        ]}
      />
    );
  }

  return <Base className="Home">{children}</Base>;
};

const ExpiredErrorContent = ({ modal }: { modal?: boolean }) => {
  const tenantConfig = useTenantConfig();
  const { logoutMagic } = useAuth0();

  const cannotStartOver = !!tenantConfig?.investigationOnlyMode;

  return (
    <ErrorContainer modal={modal}>
      <Heading className="text-lg text-cool-gray-600">
        Your session has expired.
      </Heading>
      <Heading className="mt-2 text-sm text-cool-gray-600">
        Please use the original link texted to you to resume your session where
        you left off
        {cannotStartOver ? (
          <>.</>
        ) : (
          <>
            , or{' '}
            <Link
              className="text-blue-600 hover:underline"
              to="/"
              onClick={() => {
                logoutMagic();
              }}
            >
              start over
            </Link>
            .
          </>
        )}
      </Heading>
    </ErrorContainer>
  );
};

const MaintenanceWindowContent = ({ message }: { message: string }) => {
  return (
    <ErrorContainer>
      <Heading className="text-lg text-cool-gray-600">
        Scheduled Maintenance
      </Heading>
      <Heading className="mt-2 text-sm text-cool-gray-600">{message}</Heading>
    </ErrorContainer>
  );
};

const GenericErrorPage: React.FC<GenericErrorPageProps> = ({
  error,
  isExpiredToken,
  title,
  errorMessage,
  showRestartButton,
  modal,
  maintenanceWindowMessage,
}) => {
  const { logoutMagic } = useAuth0();
  const tenantConfig = useTenantConfig();

  let messages: string[];

  if (errorMessage) {
    messages = [errorMessage];
  } else if (
    error &&
    error.graphQLErrors &&
    error.graphQLErrors.length &&
    error.graphQLErrors.every(e => e && e.message)
  ) {
    messages = error.graphQLErrors.map(({ message }) =>
      message === 'jwt expired'
        ? 'Your access to the claims portal has expired.'
        : message,
    );
  } else if (error && error.networkError) {
    //https://github.com/apollographql/apollo-link/issues/300

    const result = (error.networkError as any).result;
    messages = result
      ? result.message
        ? [result.message]
        : result.errors
        ? result.errors.map((e: any) => e.message)
        : JSON.stringify(result)
      : ['An unknown error occured. Please check your network connection.'];
  } else if (isExpiredToken) {
    return <ExpiredErrorContent />;
  } else if (maintenanceWindowMessage) {
    return <MaintenanceWindowContent message={maintenanceWindowMessage} />;
  } else {
    messages = [`Your access may have expired.`];
  }

  if (error?.message && error?.message?.indexOf('401') !== -1) {
    return <ExpiredErrorContent modal={modal} />;
  }

  return (
    <ErrorContainer modal={modal}>
      <Heading className="text-lg text-cool-gray-600">
        {title || 'Unable to connect'}
      </Heading>
      <Heading className="mt-2 text-sm text-cool-gray-600">
        {messages.map((message, i) => (
          <span key={i}>{message}</span>
        ))}
      </Heading>
      {tenantConfig?.features.genericErrorPage_showTenantPhoneFallback ? (
        <div className="mt-2 text-center text-sm text-cool-gray-600">
          Having trouble? Give us a call at{' '}
          <a
            className="hover:underline"
            href={`tel:${tenantConfig.phoneNumber}`}
          >
            {formatPhoneNumber(tenantConfig.phoneNumber)}
          </a>
          .
        </div>
      ) : null}
      {showRestartButton ? (
        <div className="mt-2 text-center">
          <Link
            className="inline-block btn btn-blue"
            to="/"
            onClick={() => {
              logoutMagic();
            }}
          >
            Start over
          </Link>
        </div>
      ) : null}
    </ErrorContainer>
  );
};
export default GenericErrorPage;
