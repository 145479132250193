import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import {
  BodilyInjuryDisplayMode,
  BodilyInjuryPayload,
  BodilyInjuryRegionTabs,
} from '@assured/shared-types/ClaimWorkflow/BodilyInjury';

import Modal from '../../Modal';
import Toggle from '../../Toggle';
import BodilyInjuryDetailModalTabs from './BodilyInjuryDetailModalTabs';
import { buildOptions, buildTabs } from './buildBodilyInjuryTemplateData';
import icons from './icons';
import iconsUsaa from './iconsUsaa';

interface BodilyInjuryDetailModalProps {
  open: boolean;
  onClose: () => void;
  specification?: BodilyInjuryRegionTabs;
  displayMode: BodilyInjuryDisplayMode;
  isLowSeverity?: boolean;
  iconTheme?: string;
  allowOtherEntry?: boolean;
  singleRegionPicker?: BodilyInjuryPayload['regions'][number]['key'];
  value?: BodilyInjuryPayload['regions'][number] | null;
  onChange: (v: BodilyInjuryPayload['regions'][number]) => void;
}
const BodilyInjuryDetailModal: React.FC<BodilyInjuryDetailModalProps> = ({
  open,
  onClose,
  specification,
  isLowSeverity,
  displayMode,
  value,
  onChange,
  iconTheme,
  singleRegionPicker,
  allowOtherEntry,
}) => {
  const [currentTab, setCurrentTab] = useState('popular');

  useEffect(() => {
    setCurrentTab('popular');
  }, [specification]);

  if (!specification) {
    return null;
  }

  const tabLabel = specification.data.tabs
    ?.find(t => t.key === currentTab)
    ?.label?.toLowerCase();

  const body = (
    <div className="mb-7 px-2 flex flex-col">
      {specification.data.tabs?.length ? (
        <BodilyInjuryDetailModalTabs
          tabs={buildTabs(specification.data, displayMode, value || undefined)}
          currentTab={currentTab}
          onTabSelect={t => setCurrentTab(t)}
          singleRegionPicker={!!singleRegionPicker}
        />
      ) : null}
      {buildOptions(
        specification.data,
        currentTab,
        displayMode,
        allowOtherEntry,
        isLowSeverity,
      ).map(o => {
        const optionValue = value?.options.find(item => item.key === o.key);
        const isChecked = !!optionValue;

        const onCheck = () => {
          if (isChecked) {
            onChange({
              key: specification.key,
              options:
                value?.options.filter(existing => existing.key !== o.key) || [],
            });
          } else {
            onChange({
              key: specification.key,
              options: (value?.options || []).concat([
                {
                  key: o.key,
                  data: {},
                },
              ]),
            });
          }
        };

        const useBigButtons = currentTab === 'popular';

        const followups =
          isChecked && !singleRegionPicker && o.followups?.length
            ? o.followups.map(f => {
                return (
                  <div
                    key={f.key}
                    className={classNames(
                      'flex items-center mb-2',
                      useBigButtons ? 'text-center flex-col' : 'flex-row',
                    )}
                  >
                    <div
                      className={classNames(
                        'flex-1 text-xs leading-4',
                        useBigButtons ? '' : 'mr-3',
                      )}
                    >
                      {f.label}
                    </div>
                    <div className={classNames(useBigButtons && 'mt-2')}>
                      <Toggle
                        options={[
                          { value: false, label: 'No' },
                          { value: true, label: 'Yes' },
                        ]}
                        value={optionValue?.data[f.key] === true}
                        onChange={v =>
                          onChange({
                            key: specification.key,
                            options: (value?.options || []).map(option => {
                              const opt = option;
                              if (opt.key === optionValue?.key) {
                                opt.data[f.key] = v;
                              }
                              return opt;
                            }),
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })
            : [];

        if (useBigButtons) {
          const iconMap = iconTheme === 'usaa' ? iconsUsaa : icons;
          let iconKey = o.key;
          if (iconKey === 'soreness') {
            switch (specification.key) {
              case 'arm_left':
              case 'arm_right':
                iconKey = 'muscle_soreness_arm';
                break;
              case 'upper_leg_left':
              case 'upper_leg_right':
                iconKey = 'muscle_soreness_upper_leg';
                break;
              case 'lower_leg_left':
              case 'lower_leg_right':
                iconKey = 'muscle_soreness_lower_leg';
                break;
              case 'back':
                iconKey = 'muscle_soreness_back';
                break;
              case 'torso':
                iconKey = 'muscle_soreness_torso';
                break;
              case 'chest':
                iconKey = 'muscle_soreness_chest';
                break;
              default:
                break;
            }
          }

          const iconSrc =
            iconKey in iconMap ? iconMap[iconKey] : iconMap.default;
          return (
            <label
              key={o.key}
              htmlFor={o.key}
              className={classNames(
                'flex flex-row relative border-solid border-2 rounded-lg p-2 px-4 hover:shadow cursor-pointer items-center transition duration-150 ease-in-out mt-6 mx-6 text-center',
                isChecked && 'bg-blue-100 border-blue-300',
              )}
            >
              <input
                id={o.key}
                data-testid={o.key}
                type="checkbox"
                className={classNames(
                  'absolute form-checkbox focus:shadow-none focus:border-gray-300 cursor-pointer h-8 w-8 text-blue-600 transition duration-150 ease-in-out transform',
                  isChecked && 'h-10 w-10',
                )}
                style={{
                  top: '-1rem',
                  right: '-1rem',
                  ...(isChecked && { transform: `rotate(7.5deg)` }),
                }}
                checked={isChecked}
                onChange={onCheck}
              />
              <img className="w-12 h-12" src={iconSrc} alt="" />
              <div
                className={classNames(
                  'font-medium text-cool-gray-600 text-base leading-4 text-center flex-1 px-4',
                )}
              >
                <div className="py-2">{o.label}</div>
                {followups?.length ? <div className="">{followups}</div> : null}
              </div>
            </label>
          );
        }

        return (
          <div key={o.key} className="mt-6">
            <label key={o.key} htmlFor={o.key} className="mb-5 cursor-pointer">
              <div className="flex items-center">
                <input
                  id={o.key}
                  type="checkbox"
                  checked={isChecked}
                  onChange={onCheck}
                  className="form-checkbox focus:shadow-none focus:border-gray-300 cursor-pointer h-7 w-7 text-blue-600 transition duration-150 ease-in-out mr-3"
                />
                <div className="text-left text-base flex-1">
                  <div
                    className={classNames(
                      '',
                      isChecked
                        ? 'font-medium text-blue-600'
                        : 'font-medium text-cool-gray-600',
                    )}
                  >
                    {o.label}
                  </div>
                </div>
              </div>
            </label>
            {followups?.length ? (
              <div className="ml-10 text-left">{followups}</div>
            ) : null}
            {isChecked && o.key === `${currentTab}_other` ? (
              <div className="ml-10 mt-1 text-left">
                <input
                  className="border border-gray-300 focus:outline-none focus:border-blue-600 rounded px-3 py-2 w-full bg-white text-gray-800"
                  type="text"
                  placeholder={`Describe the ${tabLabel} injury...`}
                  value={optionValue?.manual_input || ''}
                  onChange={e =>
                    onChange({
                      key: specification.key,
                      options: (value?.options || []).map(option => {
                        const opt = option;
                        if (opt.key === optionValue?.key) {
                          opt.manual_input = e.target.value;
                        }
                        return opt;
                      }),
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );

  if (singleRegionPicker) {
    return <div>{body}</div>;
  }

  return (
    <Modal
      open={open}
      title={<div className="-mt-1 mb-3">{specification.label} injury</div>}
      fullWidth
      showDismissButton
      body={body}
      allowBackgroundDismiss
      customDismiss={() => onClose()}
      actions={[
        {
          title: 'Save',
          primary: true,
          disabled: !value?.options.length,
          onClick: () => {
            onClose();
          },
        },
      ]}
    />
  );
};

export default BodilyInjuryDetailModal;
