import { useHistory, useLocation } from 'react-router-dom';

import GenericErrorPage from '../components/GenericErrorPage';
import { BeginPageLocationState } from '../interfaces';

export const ProgressiveNLIBegin = ({}) => {
  const { search } = useLocation();
  const history = useHistory();
  const params = new URLSearchParams(search);
  const sessionId = params.get('FNOLSessionId');
  const carrierDeviceId = params.get('UniqueDeviceId') || undefined;
  if (sessionId) {
    const locationState: BeginPageLocationState = {
      unauthenticatedDeepLinkOptions: {
        carrierSessionId: sessionId,
        carrierDeviceId,
        workflowType: 'standard',
      },
      isDeepLinkNLI: true,
    };
    history.replace(`/begin`, locationState);
  }
  return (
    <GenericErrorPage errorMessage="Session not found. Please start over." />
  );
};
