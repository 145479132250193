import React, { createElement, useEffect, useState } from 'react';

let instances = 0;

interface HeadingProps {
  /** Child components for the buttons, generally text */
  children: React.ReactNode;
  /** Existing className that may/may not be appended to */
  className?: string;
  id?: string;
  /** React's styling object; can be empty */
  style?: React.CSSProperties;
  /** onClick handler for the button */
  onClick?: React.MouseEvent<HTMLButtonElement, MouseEvent>;
}

const Heading: React.FC<HeadingProps> = ({
  children,
  className,
  style = {},
  onClick,
  id,
}) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    instances++;
    setCount(instances);

    return () => instances--;
  }, []);

  const props = { className, onClick, style, id };

  const countValid = !!count && !isNaN(count) && count <= 6;
  const nodeType = countValid ? `h${count}` : 'p';

  return createElement(nodeType, props, children);
};

export default Heading;
