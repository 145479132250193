import { StringStepComponentSpec } from '@assured/step-renderer';

export const correctCapitalization = (
  step_component: StringStepComponentSpec,
  value: string,
) => {
  let v = value;
  if (
    [
      'license_plate',
      'insurance_policy_number',
      'insurance_claim_number',
    ].includes(step_component.mode || '') &&
    v
  ) {
    v = v.toUpperCase();
  }

  if (step_component.mode === 'insurance_policy_number') {
    // eslint-disable-next-line no-useless-escape
    v = v.replace(/[^a-zA-Z0-9\-]/g, '');
  }

  if (v?.length && step_component.mode !== 'email') {
    v = v[0].toUpperCase() + v.substring(1);
  }
  return v;
};
