import { kebabCase } from 'case-anything';

import { AnyStepComponentSpec } from '../types/step-components';

export function snakeToSentence(str: string) {
  // Replace underscores with spaces and make everything lowercase
  const sentence = str.replace(/_/g, ' ').toLowerCase();

  // Capitalize the first letter of the sentence
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export function makeAriaLabeledBy(step_component?: AnyStepComponentSpec) {
  // tries to match field label and falls back to page title
  return makeStepComponentLabelID(step_component) ?? 'page-title';
}

export function makeAriaLabel(step_component?: AnyStepComponentSpec) {
  if (!step_component) {
    return null;
  }

  return (
    ('label' in step_component ? step_component.label : undefined) ||
    ('placeholder' in step_component
      ? step_component.placeholder
      : undefined) ||
    step_component.subtitle ||
    step_component.title ||
    null
  );
}

export function makeStepComponentFieldID(
  step_component?: AnyStepComponentSpec,
) {
  if (!step_component) {
    return null;
  }

  const idBase =
    ('label' in step_component ? step_component.label : undefined) ||
    step_component.field;

  return typeof idBase === 'string' ? `${kebabCase(idBase)}-field` : null;
}

export function makeStepComponentLabelID(
  step_component?: AnyStepComponentSpec,
) {
  if (!step_component) {
    return null;
  }

  return 'label' in step_component && step_component.label
    ? `${kebabCase(step_component.label)}-label`
    : null;
}
