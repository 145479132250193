import Modal from '../Modal';

interface IExistingDraftModalProps {
  open: boolean;
  firstName?: string;
  onContinueFiling: () => void;
  onDiscard: () => void;
}

const ExistingDraftModal = ({
  open,
  firstName,
  onContinueFiling,
  onDiscard,
}: IExistingDraftModalProps) => {
  return (
    <Modal
      open={open}
      title={<>Welcome back{firstName ? `, ${firstName}` : ''} 👋</>}
      body={<>It looks like you already have a claim in progress.</>}
      bigFormat
      actions={[
        {
          title: 'Continue filing',
          primary: true,
          onClick: () => onContinueFiling(),
        },
        {
          title: 'Discard and start over',
          primary: false,
          onClick: () => onDiscard(),
        },
      ]}
    />
  );
};

export default ExistingDraftModal;
