import { lazy } from 'react';
import { config } from '~/src/config';
import { useTenantConfigWithFallback } from '~/src/hooks';

const GuidedPhotoCaptureProperty = lazy(() =>
  import(
    '@assured/guided-photo-capture/src/components/GuidedPhotoCaptureProperty'
  ).then(module => ({
    default: module.GuidedPhotoCaptureProperty,
  })),
);

export const GuidedPhotoCapturePropertyRoutes = () => {
  const tenantConfig = useTenantConfigWithFallback();
  if (!tenantConfig) {
    return null;
  }

  return (
    <GuidedPhotoCaptureProperty config={config} tenantConfig={tenantConfig} />
  );
};
