import { gql } from '@apollo/client';

export const START_NEW_CLAIM_WORKFLOW = gql`
  mutation StartNewClaimWorkflow(
    $type: WorkflowType
    $terminateDraftClaimId: ID
    $authenticatedDeepLinkToken: String
    $policyNumber: String
    $policyLocation: LocationInput
    $catastropheId: ID
    $isCommercialClaim: Boolean
    $catastropheIncidentClaimInvitationToken: String
    $carrierSessionId: String
    $carrierDeviceId: String
    $dispatchId: String
    $isDeepLink: Boolean
    $isDeepLinkNLI: Boolean
  ) {
    startNewClaimWorkflow(
      type: $type
      terminateDraftClaimId: $terminateDraftClaimId
      authenticatedDeepLinkToken: $authenticatedDeepLinkToken
      policyNumber: $policyNumber
      policyLocation: $policyLocation
      catastropheId: $catastropheId
      catastropheIncidentClaimInvitationToken: $catastropheIncidentClaimInvitationToken
      carrierSessionId: $carrierSessionId
      carrierDeviceId: $carrierDeviceId
      dispatchId: $dispatchId
      isDeepLink: $isDeepLink
      isDeepLinkNLI: $isDeepLinkNLI
      isCommercialClaim: $isCommercialClaim
    ) {
      data {
        id
      }
    }
  }
`;
