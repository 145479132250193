import React, { ReactNode } from 'react';
import reactStringReplace from 'react-string-replace';

import { InlineComponentType } from '@assured/step-renderer';

import { useInlineComponentLookup } from '../../hooks/useWidgetAndInlineLookup';

type InlineProps = {
  nodes: string | ReactNode[];
  injectors?: Record<string, Injector>;
  className?: any;
} & Record<string, any>;

type Injector = {
  type: InlineComponentType;
  value: string;
};

export const hydrateInlineComponents = (
  text: string | ReactNode[],
  injectionMap: Record<string, Injector>,
) => {
  return reactStringReplace(text, /\{\{(.*?)\}\}/g, (match, i) => {
    const mapped_value = injectionMap[match];
    if (!mapped_value)
      return (
        <div>
          KeyError: <b>{match}</b> not found in injection map
        </div>
      );
    const Component = useInlineComponentLookup(
      mapped_value.type as InlineComponentType,
    );
    return (
      <span key={i}>
        <Component value={mapped_value} />
      </span>
    );
  });
};

const InjectInline: React.FC<InlineProps> = ({ nodes, injectors }) => {
  return (
    <React.Fragment>
      {injectors ? (
        <div>{hydrateInlineComponents(nodes, injectors)}</div>
      ) : (
        nodes
      )}
    </React.Fragment>
  );
};

export default InjectInline;
