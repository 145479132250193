import { useEffect, useState } from 'react';

import { gql, useLazyQuery } from '@apollo/client';
import {
  HealthSearchLocation,
  HealthSearchProvider,
  HealthSearchResult,
  RibbonHealthSearchPhoneNumber,
} from '@assured/shared-types/Services';
import { formatPhoneNumber } from '@assured/utilities/src/formatPhoneNumber';

import { getTransformedHealthSearchResults, Mode } from './useHealthSearchNlm';
import {
  DEFAULT_EXCLUDED_LOCATION_TYPES,
  DEFAULT_EXCLUDED_PROVIDER_TYPES,
} from './useHealthSearchRibbonConstants';

const TEXT_PROVIDER_GENERIC = 'Health Provider';

const SEARCH_HEALTH_MAIN_FIELDS = gql`
  fragment SearchHealthMainFields on HealthSearchLocation {
    address
    addressDetails {
      city
      state
      zip
    }
    phoneNumbers {
      detail
      details
      phone
    }
  }
`;

const SEARCH_HEALTH_LOCATIONS = gql`
  ${SEARCH_HEALTH_MAIN_FIELDS}
  query SearchHealthLocations(
    $distance: Int
    $excludedLocationTypes: [String!]!
    $fields: [String!]!
    $location: String
    $locationTypes: [String!]!
    $name: String!
    $pageSize: Int
  ) {
    searchHealthLocations(
      distance: $distance
      excludedLocationTypes: $excludedLocationTypes
      fields: $fields
      location: $location
      locationTypes: $locationTypes
      name: $name
      pageSize: $pageSize
    ) {
      ...SearchHealthMainFields
      locationTypes
      name
      uuid
    }
  }
`;

const SEARCH_HEALTH_PROVIDERS = gql`
  ${SEARCH_HEALTH_MAIN_FIELDS}
  query SearchHealthProviders(
    $distance: Int
    $excludedProviderTypes: [String!]!
    $fields: [String!]!
    $location: String
    $name: String!
    $pageSize: Int
    $providerTypes: [String!]!
  ) {
    searchHealthProviders(
      distance: $distance
      excludedProviderTypes: $excludedProviderTypes
      fields: $fields
      location: $location
      name: $name
      pageSize: $pageSize
      providerTypes: $providerTypes
    ) {
      firstName
      lastName
      locations {
        ...SearchHealthMainFields
      }
      npi
      providerTypes
    }
  }
`;

export type SetIsLoading = (isLoading: boolean) => void;

export const getFormattedPrimaryPhoneNumber = (
  phoneNumberData: RibbonHealthSearchPhoneNumber[],
): string | undefined => {
  if (!Array.isArray(phoneNumberData)) {
    return;
  }

  // try to find the primary phone number
  const primaryPhoneNumberData = phoneNumberData.find(
    current => current.detail === 'primary' || current.details === 'primary',
  );

  // otherwise just use the first one from the list
  const phoneNumber =
    primaryPhoneNumberData?.phone || phoneNumberData[0]?.phone;

  // bad times
  if (typeof phoneNumber !== 'string') {
    return;
  }
  return formatPhoneNumber(phoneNumber);
};

export function useHealthSearchRibbon({
  distance = 5000,
  isLoading,
  excludedLocationTypes = DEFAULT_EXCLUDED_LOCATION_TYPES,
  excludedProviderTypes = DEFAULT_EXCLUDED_PROVIDER_TYPES,
  location,
  locationSearchFields = [
    'address',
    'address_details',
    'location_types',
    'name',
    'phone_numbers',
    'uuid',
  ],
  locationTypes = [],
  mode,
  pageSize = 10,
  providerSearchFields = [
    'first_name',
    'last_name',
    'locations.address',
    'locations.address_details',
    'locations.name',
    'locations.phone_numbers',
    'locations.uuid',
    'npi',
    'provider_types',
  ],
  providerTypes = [],
  searchTerm,
  setIsLoading,
  skip,
  showMoreResults,
}: {
  distance?: number;
  excludedLocationTypes?: string[];
  excludedProviderTypes?: string[];
  isLoading: boolean;
  location?: string;
  locationSearchFields?: string[];
  locationTypes?: string[];
  mode: Mode;
  pageSize?: number;
  providerSearchFields?: string[];
  providerTypes?: string[];
  searchTerm: string;
  setIsLoading: SetIsLoading;
  skip?: boolean;
  showMoreResults?: boolean;
}): [
  HealthSearchResult | null,
  (searchRes: HealthSearchResult | null) => void,
] {
  const [searchRes, setSearchRes] = useState<HealthSearchResult | null>(null);
  const [
    searchHealthLocations,
    { data: dataLocations, loading: loadingLocations },
  ] = useLazyQuery<{
    searchHealthLocations: HealthSearchLocation[];
  }>(SEARCH_HEALTH_LOCATIONS);
  const [
    searchHealthProviders,
    { data: dataProviders, loading: loadingProviders },
  ] = useLazyQuery<{
    searchHealthProviders: HealthSearchProvider[];
  }>(SEARCH_HEALTH_PROVIDERS);

  useEffect(() => {
    if (loadingLocations || loadingProviders) {
      if (!isLoading) {
        setIsLoading(true);
      }
    } else if (isLoading) {
      setIsLoading(false);
    }
  }, [loadingLocations, loadingProviders]);

  useEffect(() => {
    if (!skip) {
      if (!searchTerm) {
        setSearchRes({
          term: searchTerm,
          results: [],
        });
      } else if (mode === 'npi_hospital' || mode === 'npi_organization') {
        searchHealthLocations({
          variables: {
            distance,
            excludedLocationTypes,
            fields: locationSearchFields,
            location,
            name: searchTerm,
            locationTypes,
            pageSize,
          },
        });
      } else if (mode === 'npi_individual') {
        searchHealthProviders({
          variables: {
            distance,
            excludedProviderTypes,
            fields: providerSearchFields,
            location,
            name: searchTerm,
            pageSize,
            providerTypes,
          },
        });
      }
    }
  }, [searchTerm, skip]);

  useEffect(() => {
    if (searchTerm) {
      if (
        dataLocations &&
        Array.isArray(dataLocations?.searchHealthLocations)
      ) {
        setSearchRes({
          term: searchTerm,
          results: getTransformedHealthSearchResults(
            dataLocations?.searchHealthLocations.map(current => ({
              _id: current.uuid,
              'addr_practice.city': current.addressDetails?.city,
              'addr_practice.full': current.address,
              'addr_practice.phone': getFormattedPrimaryPhoneNumber(
                current.phoneNumbers,
              ),
              'addr_practice.state': current.addressDetails?.state,
              'addr_practice.zip': current.addressDetails?.zip,
              'name.full': current.name,
              provider_type:
                current.locationTypes?.[0] || TEXT_PROVIDER_GENERIC,
            })),
            showMoreResults,
          ),
        });
      } else if (
        dataProviders &&
        Array.isArray(dataProviders?.searchHealthProviders)
      ) {
        setSearchRes({
          term: searchTerm,
          results: getTransformedHealthSearchResults(
            dataProviders.searchHealthProviders.map(current => ({
              _id: current.npi,
              'addr_practice.city':
                current.locations?.[0]?.addressDetails?.city,
              'addr_practice.full': current.locations?.[0]?.address,
              'addr_practice.phone': getFormattedPrimaryPhoneNumber(
                current.locations?.[0]?.phoneNumbers,
              ),
              'addr_practice.state':
                current.locations?.[0]?.addressDetails?.state,
              'addr_practice.zip': current.locations?.[0]?.addressDetails?.zip,
              'name.full': `${current.lastName}, ${current.firstName}`,
              NPI: current.npi,
              provider_type:
                current.providerTypes?.[0] || TEXT_PROVIDER_GENERIC,
            })),
          ),
        });
      }
    }
  }, [dataLocations, dataProviders]);

  return [searchRes, setSearchRes];
}
