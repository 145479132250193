// manually curated list of location types to exclude from search
// pulled from: https://ribbon.readme.io/docs/location-types-endpoint
// GET https://api.ribbonhealth.com/v1/location_types
export const DEFAULT_EXCLUDED_LOCATION_TYPES = [
  // 'Ambulatory Surgery Center',
  'Assisted Living Facility',
  // 'Clinic',
  // 'Clinic - Dental',
  // 'Clinic - Vision',
  'Dialysis Center',
  // 'Emergency Room',
  'Home Health Agency',
  // 'Hospice',
  // 'Hospital',
  // 'Hospital - Children Chronic Disease',
  // 'Hospital - Children General Acute Care',
  // 'Hospital - Children Rehabilitation',
  // 'Hospital - Chronic Disease',
  // 'Hospital - General Acute Care',
  // 'Hospital - Long Term Care',
  // 'Hospital - Psychiatric',
  // 'Hospital - Rehabilitation',
  'Imaging Center',
  // 'Independent Clinic',
  'Laboratory - Dental',
  'Laboratory - Medical',
  'Laboratory - Physiological',
  'Pharmacy',
  'Physical Therapy',
  // 'Primary Care Clinic',
  'Residential Treatment Facility - Mental or Developmental Disabilities',
  'Residential Treatment Facility - Physical Disabilities',
  'Residential Treatment Facility - Psychiatric',
  'Residential Treatment Facility - Substance Abuse',
  'Respite Care',
  // 'Retail Health Clinic',
  'Skilled Nursing Facility',
  'Skilled Nursing Facility - Pediatric',
  // 'Telemedicine',
  // 'Urgent Care',
];

// manually curated list of provider types to exclude from search
// pulled from: https://ribbon.readme.io/docs/provider-types-endpoint
// GET https://api.ribbonhealth.com/v1/provider_types
export const DEFAULT_EXCLUDED_PROVIDER_TYPES = [
  'Behavioral Health and Social Services',
  // 'Chiropractic Providers',
  // 'Dental Providers',
  'Diet & Nutrition',
  // 'Doctor',
  // 'Emergency Services',
  'Nursing',
  'Nursing Related',
  // 'Optometry',
  // 'Other Provider Type',
  'Pharmacy',
  'Physician Assistants',
  'Respiratory, Developmental, Restorative and Rehab',
  'Respiratory, Developmental, Restorative and Rehabilitation',
  'Speech, Language, or Hearing',
  'Student',
  'Technologist',
];
