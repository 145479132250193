import type {
  TelephoneNumberCTAStepComponentSpec,
  StepComponentSharedProps,
} from '@assured/step-renderer';
import { formatPhoneNumber } from '../../utils';

type TelephoneNumberCTAProps = StepComponentSharedProps<
  TelephoneNumberCTAStepComponentSpec,
  string | null
>;
const TelephoneNumberCTA = ({ step_component }: TelephoneNumberCTAProps) => {
  return (
    <div className="flex justify-center align-center ClaimWorkflowInner">
      <a href={`tel:${step_component.telephone_number}`}>
        <div className="px-5 py-3 rounded-lg border btn btn-blue sm:order-last flex">
          <div className="pr-1">{step_component.pre_text}</div>
          {formatPhoneNumber(step_component.telephone_number)}
          <div className="pl-1">{step_component.post_text}</div>
        </div>
      </a>
    </div>
  );
};
export default TelephoneNumberCTA;
