{
  "v": "5.7.4",
  "fr": 41.2221527099609,
  "ip": 0,
  "op": 328.001215100364,
  "w": 222,
  "h": 133,
  "nm": "Insurance Card Animation",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 3,
      "nm": "NULL CONTROL ",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 0, "ix": 11 },
        "r": {
          "a": 1,
          "k": [
            {
              "i": { "x": [0.58], "y": [1] },
              "o": { "x": [0.42], "y": [0] },
              "t": 5,
              "s": [123]
            },
            {
              "i": { "x": [0.58], "y": [1] },
              "o": { "x": [0.42], "y": [0] },
              "t": 13,
              "s": [66]
            },
            {
              "i": { "x": [0.58], "y": [1] },
              "o": { "x": [0.42], "y": [0] },
              "t": 75,
              "s": [66]
            },
            {
              "i": { "x": [0.58], "y": [1] },
              "o": { "x": [0.42], "y": [0] },
              "t": 91,
              "s": [0]
            },
            {
              "i": { "x": [0.58], "y": [1] },
              "o": { "x": [0.42], "y": [0] },
              "t": 201.001,
              "s": [0]
            },
            {
              "i": { "x": [0.58], "y": [1] },
              "o": { "x": [0.42], "y": [0] },
              "t": 217.001,
              "s": [-66]
            },
            {
              "i": { "x": [0.58], "y": [1] },
              "o": { "x": [0.42], "y": [0] },
              "t": 314.001,
              "s": [-66]
            },
            { "t": 327.001211395789, "s": [-112] }
          ],
          "ix": 10
        },
        "p": { "a": 0, "k": [111, 310, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [50, 50, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "ip": 0,
      "op": 334.001237327809,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 4,
      "nm": "Group 4",
      "td": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [111, 66.5, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485],
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0],
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485]
                  ],
                  "v": [
                    [104.98, 64.328],
                    [-104.98, 64.328],
                    [-109.48, 59.828],
                    [-109.48, -59.635],
                    [-104.98, -64.135],
                    [104.98, -64.135],
                    [109.48, -59.635],
                    [109.48, 59.828]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.525490224361, 0.525490224361, 0.525490224361, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.534, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.847058832645, 0.847058832645, 0.847058832645, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 330.001222509512,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 4,
      "nm": "paper",
      "parent": 1,
      "tt": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": -66, "ix": 10 },
        "p": { "a": 0, "k": [33.869, 42.683, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [175, 350, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [24.825, 1.319],
                    [-24.825, 1.319],
                    [-24.825, -1.319],
                    [24.825, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [233.611, 113.238], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [12.13, 1.903],
                    [-12.13, 1.903],
                    [-12.13, -1.903],
                    [12.13, -1.903]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [220.915, 106.686], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.598, 1.319],
                    [-18.598, 1.319],
                    [-18.598, -1.319],
                    [18.598, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [227.284, 129.091], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [9.699, 1.903],
                    [-9.699, 1.903],
                    [-9.699, -1.903],
                    [9.699, -1.903]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [218.386, 122.539], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 4",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [23.493, 1.319],
                    [-23.493, 1.319],
                    [-23.493, -1.319],
                    [23.493, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [171.629, 134.376], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 5",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 5,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.287, 1.319],
                    [-27.287, 1.319],
                    [-27.287, -1.319],
                    [27.287, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [227.056, 146.045], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 6",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 6,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [27.54, 1.319],
                    [-27.54, 1.319],
                    [-27.54, -1.319],
                    [27.54, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [227.309, 158.155], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 7",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 7,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [30.176, 1.319],
                    [-30.176, 1.319],
                    [-30.176, -1.319],
                    [30.176, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [229.945, 152.1], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 8",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 8,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [30.176, 1.319],
                    [-30.176, 1.319],
                    [-30.176, -1.319],
                    [30.176, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [117.872, 151.55], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 9",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 9,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.615, 1.903],
                    [-3.615, 1.903],
                    [-3.615, -1.903],
                    [3.615, -1.903]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [91.311, 144.998], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 10",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 10,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.598, 1.319],
                    [-18.598, 1.319],
                    [-18.598, -1.319],
                    [18.598, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [106.293, 134.376], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 11",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 11,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.928, 1.903],
                    [-5.928, 1.903],
                    [-5.928, -1.903],
                    [5.928, -1.903]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [93.624, 127.824], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 12",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 12,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.598, 1.319],
                    [-18.598, 1.319],
                    [-18.598, -1.319],
                    [18.598, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [166.734, 129.091], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 13",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 13,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.928, 1.903],
                    [-5.928, 1.903],
                    [-5.928, -1.903],
                    [5.928, -1.903]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [154.064, 122.539], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 14",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 14,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [24.825, 1.319],
                    [-24.825, 1.319],
                    [-24.825, -1.319],
                    [24.825, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [172.961, 113.238], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 15",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 15,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.928, 1.903],
                    [-5.928, 1.903],
                    [-5.928, -1.903],
                    [5.928, -1.903]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [154.064, 106.686], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 16",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 16,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [21.846, 1.319],
                    [-21.846, 1.319],
                    [-21.846, -1.319],
                    [21.846, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [109.333, 118.743], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 17",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 17,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [24.825, 1.319],
                    [-24.825, 1.319],
                    [-24.825, -1.319],
                    [24.825, -1.319]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [112.312, 113.238], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 18",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 18,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [26.653, 1.328],
                    [-26.653, 1.328],
                    [-26.653, -1.328],
                    [26.653, -1.328]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [113.783, 96.954], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 19",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 19,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [21.537, 1.328],
                    [-21.537, 1.328],
                    [-21.537, -1.328],
                    [21.537, -1.328]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [108.667, 92.04], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 20",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 20,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [12.13, 1.903],
                    [-12.13, 1.903],
                    [-12.13, -1.903],
                    [12.13, -1.903]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [99.617, 106.686], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 21",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 21,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.288, 0],
                        [0, 0.288],
                        [0, 0],
                        [0.288, 0],
                        [0, -0.288]
                      ],
                      "o": [
                        [0, 0.288],
                        [0.288, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.468, 1.242],
                        [0, 1.747],
                        [0.468, 1.242],
                        [0.468, 0.162],
                        [0, -0.342],
                        [-0.468, 0.162]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.289, 0],
                        [0, 0],
                        [0, 0],
                        [0.54, 0],
                        [0, 0.864],
                        [0, 0],
                        [-0.612, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.287, 0],
                        [0, -0.252]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.323, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [0, 0],
                        [0, -0.864],
                        [0.54, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.289, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.477, 2.071],
                        [0.972, 2.539],
                        [0.504, 2.25],
                        [0.432, 2.25],
                        [-0.36, 2.611],
                        [-1.477, 1.242],
                        [-1.477, 0.162],
                        [-0.36, -1.207],
                        [0.396, -0.882],
                        [0.468, -0.882],
                        [0.468, -2.143],
                        [0.972, -2.611],
                        [1.477, -2.143]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [190.619, 93.48], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.432, 0]
                      ],
                      "o": [
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.252, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.702, -1.854],
                        [1.17, -1.423],
                        [0.702, -0.99],
                        [0.306, -0.99],
                        [-0.163, -0.486],
                        [-0.163, 1.387],
                        [-0.667, 1.854],
                        [-1.17, 1.387],
                        [-1.17, -1.351],
                        [-0.667, -1.818],
                        [-0.199, -1.53],
                        [-0.126, -1.53],
                        [0.486, -1.854]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [187.757, 94.165], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 2",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, -0.217],
                        [-0.216, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [-0.216, 0],
                        [0, 0.216],
                        [0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.451, 0.324],
                        [-0.054, 0.324],
                        [-0.45, 0.72],
                        [-0.054, 1.116],
                        [0.451, 0.864]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [0.505, 0],
                        [0, 0.647],
                        [-0.9, 0],
                        [0, 0],
                        [0, 0],
                        [0.252, 0],
                        [0.433, 0],
                        [0, 0.216],
                        [-0.792, 0],
                        [0, -0.72]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [0, -0.648],
                        [0, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.396, 0],
                        [-0.252, 0],
                        [0, -0.288],
                        [0.828, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.458, 1.369],
                        [0.955, 1.836],
                        [0.487, 1.548],
                        [0.415, 1.548],
                        [-0.342, 1.909],
                        [-1.458, 0.756],
                        [-0.054, -0.396],
                        [0.451, -0.396],
                        [0.451, -0.577],
                        [0.018, -1.044],
                        [-0.846, -0.72],
                        [-1.278, -1.117],
                        [0.018, -1.909],
                        [1.458, -0.577]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [184.409, 94.183], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 3",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -0.468],
                        [0.324, 0],
                        [0.648, 0],
                        [0, -0.541],
                        [0, 0],
                        [-0.468, 0],
                        [-0.504, 0],
                        [0, -0.289],
                        [1.044, 0],
                        [0, 1.08],
                        [0, 0],
                        [-1.116, 0]
                      ],
                      "o": [
                        [0, 0.288],
                        [-0.504, 0],
                        [-0.468, 0],
                        [0, 0],
                        [0, 0.54],
                        [0.685, 0],
                        [0.324, 0],
                        [0, 0.468],
                        [-1.116, 0],
                        [0, 0],
                        [0, -1.081],
                        [1.009, 0]
                      ],
                      "v": [
                        [1.746, -1.549],
                        [1.206, -1.045],
                        [0.09, -1.657],
                        [-0.702, -0.792],
                        [-0.702, 0.792],
                        [0.09, 1.656],
                        [1.278, 1.008],
                        [1.818, 1.513],
                        [0.09, 2.665],
                        [-1.818, 0.792],
                        [-1.818, -0.792],
                        [0.09, -2.665]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [180.809, 93.462], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 4",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0.252, 0],
                        [0, -0.324],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, -0.324],
                        [-0.252, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.468, -0.396],
                        [0.468, -0.504],
                        [0, -1.044],
                        [-0.469, -0.504],
                        [-0.469, -0.396]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.287, 0],
                        [0, 0],
                        [0, 0],
                        [-0.288, 0],
                        [-0.396, 0],
                        [0, -0.18],
                        [0.864, 0],
                        [0, 0.757],
                        [0, 0],
                        [-0.828, 0],
                        [0, -0.756]
                      ],
                      "o": [
                        [0, 0.252],
                        [0, 0],
                        [0, 0],
                        [0, 0.324],
                        [0.54, 0],
                        [0.216, 0],
                        [0, 0.36],
                        [-0.864, 0],
                        [0, 0],
                        [0, -0.756],
                        [0.828, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.477, -0.108],
                        [0.973, 0.36],
                        [-0.469, 0.36],
                        [-0.469, 0.503],
                        [0.036, 1.044],
                        [0.973, 0.72],
                        [1.368, 1.08],
                        [0.036, 1.909],
                        [-1.477, 0.503],
                        [-1.477, -0.504],
                        [0, -1.909],
                        [1.477, -0.504]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [175.21, 94.183], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 5",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.864, 0],
                        [0, -0.433],
                        [0.252, 0],
                        [0.504, 0],
                        [0, -0.324],
                        [0, 0],
                        [-0.288, 0],
                        [-0.433, 0],
                        [0, -0.216],
                        [0.792, 0],
                        [0, 0.757]
                      ],
                      "o": [
                        [0, -0.756],
                        [0.792, 0],
                        [0, 0.216],
                        [-0.433, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0.324],
                        [0.504, 0],
                        [0.252, 0],
                        [0, 0.433],
                        [-0.864, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-1.44, -0.504],
                        [0.072, -1.909],
                        [1.368, -0.972],
                        [0.937, -0.577],
                        [0.072, -1.044],
                        [-0.433, -0.504],
                        [-0.433, 0.503],
                        [0.072, 1.044],
                        [1.009, 0.503],
                        [1.44, 0.9],
                        [0.072, 1.909],
                        [-1.44, 0.503]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [171.861, 94.183], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 6",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.54, 0],
                        [0, -0.864],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, -0.288]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0.612, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.468, 1.405],
                        [-0.972, 1.873],
                        [-1.476, 1.405],
                        [-1.476, -1.333],
                        [-0.972, -1.8],
                        [-0.504, -1.512],
                        [-0.432, -1.512],
                        [0.36, -1.873],
                        [1.476, -0.504],
                        [1.476, 1.405],
                        [0.972, 1.873],
                        [0.468, 1.405],
                        [0.468, -0.504],
                        [0, -1.008],
                        [-0.468, -0.504]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [168.296, 94.146], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 7",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 7,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, -0.217],
                        [-0.216, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [-0.216, 0],
                        [0, 0.216],
                        [0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.451, 0.324],
                        [-0.054, 0.324],
                        [-0.45, 0.72],
                        [-0.054, 1.116],
                        [0.451, 0.864]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [0.505, 0],
                        [0, 0.647],
                        [-0.899, 0],
                        [0, 0],
                        [0, 0],
                        [0.252, 0],
                        [0.433, 0],
                        [0, 0.216],
                        [-0.792, 0],
                        [0, -0.72]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, 0],
                        [-0.611, 0],
                        [0, -0.648],
                        [0, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.396, 0],
                        [-0.252, 0],
                        [0, -0.288],
                        [0.828, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.458, 1.369],
                        [0.955, 1.836],
                        [0.486, 1.548],
                        [0.415, 1.548],
                        [-0.342, 1.909],
                        [-1.458, 0.756],
                        [-0.054, -0.396],
                        [0.451, -0.396],
                        [0.451, -0.577],
                        [0.018, -1.044],
                        [-0.846, -0.72],
                        [-1.278, -1.117],
                        [0.018, -1.909],
                        [1.458, -0.577]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [164.642, 94.183], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 8",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 8,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.433, 0]
                      ],
                      "o": [
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.253, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.702, -1.854],
                        [1.17, -1.423],
                        [0.702, -0.99],
                        [0.306, -0.99],
                        [-0.163, -0.486],
                        [-0.163, 1.387],
                        [-0.667, 1.854],
                        [-1.17, 1.387],
                        [-1.17, -1.351],
                        [-0.667, -1.818],
                        [-0.199, -1.53],
                        [-0.126, -1.53],
                        [0.486, -1.854]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [161.942, 94.165], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 9",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 9,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.288, 0],
                        [0, -0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [0.54, 0],
                        [0, 0.864],
                        [0, 0],
                        [-0.288, 0],
                        [0, -0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0.288]
                      ],
                      "o": [
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, 0.288],
                        [0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.468, -1.405],
                        [0.972, -1.873],
                        [1.477, -1.405],
                        [1.477, 1.333],
                        [0.972, 1.8],
                        [0.504, 1.512],
                        [0.432, 1.512],
                        [-0.36, 1.873],
                        [-1.477, 0.503],
                        [-1.477, -1.405],
                        [-0.973, -1.873],
                        [-0.469, -1.405],
                        [-0.469, 0.503],
                        [0, 1.008],
                        [0.468, 0.503]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [158.575, 94.219], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 10",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 10,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0.828, 0],
                        [0, 0.359],
                        [-0.252, 0],
                        [-0.433, 0],
                        [0, 0.181],
                        [0, 1.225],
                        [-0.792, 0],
                        [0, -0.324],
                        [0.252, 0],
                        [0.396, 0],
                        [0, -0.18],
                        [0, -1.224]
                      ],
                      "o": [
                        [-0.864, 0],
                        [0, -0.217],
                        [0.432, 0],
                        [0.216, 0],
                        [0, -0.54],
                        [0, -0.54],
                        [0.793, 0],
                        [0, 0.216],
                        [-0.433, 0],
                        [-0.18, 0],
                        [0, 0.54],
                        [0, 0.541]
                      ],
                      "v": [
                        [0.018, 1.909],
                        [-1.386, 1.044],
                        [-0.955, 0.648],
                        [0.018, 1.116],
                        [0.377, 0.828],
                        [-1.279, -0.829],
                        [0.053, -1.909],
                        [1.314, -1.117],
                        [0.882, -0.72],
                        [0.053, -1.117],
                        [-0.27, -0.829],
                        [1.386, 0.828]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [155.172, 94.183], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 11",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 11,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.54, 0],
                        [0, -0.864],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, -0.288]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0.612, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.469, 1.405],
                        [-0.973, 1.873],
                        [-1.477, 1.405],
                        [-1.477, -1.333],
                        [-0.973, -1.8],
                        [-0.505, -1.512],
                        [-0.433, -1.512],
                        [0.359, -1.873],
                        [1.477, -0.504],
                        [1.477, 1.405],
                        [0.972, 1.873],
                        [0.468, 1.405],
                        [0.468, -0.504],
                        [0, -1.008],
                        [-0.469, -0.504]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [151.806, 94.146], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 12",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 12,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.324, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.323, 0],
                        [0, -0.252]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.323, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.541, 2.089],
                        [0, 2.593],
                        [-0.541, 2.089],
                        [-0.541, -2.089],
                        [0, -2.593],
                        [0.541, -2.089]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [148.96, 93.462], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 13",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 13,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [148.96, 93.462], "ix": 2 },
              "a": { "a": 0, "k": [148.96, 93.462], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 22",
          "np": 13,
          "cix": 2,
          "bm": 0,
          "ix": 22,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [98.415, 42.18],
                    [-98.415, 42.18],
                    [-98.415, -42.18],
                    [98.415, -42.18]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.525490224361, 0.525490224361, 0.525490224361, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.534, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.949019610882, 0.949019610882, 0.949019610882, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [175, 125], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 23",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 23,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 334.001237327809,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 4,
      "nm": "Group 3",
      "td": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [111, 66.5, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485],
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0],
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485]
                  ],
                  "v": [
                    [104.98, 64.328],
                    [-104.98, 64.328],
                    [-109.48, 59.828],
                    [-109.48, -59.635],
                    [-104.98, -64.135],
                    [104.98, -64.135],
                    [109.48, -59.635],
                    [109.48, 59.828]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.525490224361, 0.525490224361, 0.525490224361, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.534, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.847058832645, 0.847058832645, 0.847058832645, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 330.001222509512,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 4,
      "nm": "card",
      "parent": 1,
      "tt": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 66, "ix": 10 },
        "p": { "a": 0, "k": [66.04, 43.589, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [175, 350, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [15.589, 1.096],
                    [-15.589, 1.096],
                    [-15.589, -1.096],
                    [15.589, -1.096]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [201.292, 142.638], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [4.543, 1.353],
                    [-4.543, 1.353],
                    [-4.543, -1.353],
                    [4.543, -1.353]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [190.246, 138.527], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.177, 1.096],
                    [-18.177, 1.096],
                    [-18.177, -1.096],
                    [18.177, -1.096]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [155.995, 146.206], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [20.814, 1.096],
                    [-20.814, 1.096],
                    [-20.814, -1.096],
                    [20.814, -1.096]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [158.631, 142.638], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 4",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [9.463, 1.353],
                    [-9.463, 1.353],
                    [-9.463, -1.353],
                    [9.463, -1.353]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [147.28, 138.527], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 5",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 5,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [7.431, 1.053],
                    [-7.431, 1.053],
                    [-7.431, -1.053],
                    [7.431, -1.053]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [172.363, 132.225], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 6",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 6,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [11.95, 1.053],
                    [-11.95, 1.053],
                    [-11.95, -1.053],
                    [11.95, -1.053]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [197.812, 121.855], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 7",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 7,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.451, 1.353],
                    [-3.451, 1.353],
                    [-3.451, -1.353],
                    [3.451, -1.353]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [189.312, 117.787], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 8",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 8,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [15.242, 1.053],
                    [-15.242, 1.053],
                    [-15.242, -1.053],
                    [15.242, -1.053]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [201.104, 132.225], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 9",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 9,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.586, 1.353],
                    [-6.586, 1.353],
                    [-6.586, -1.353],
                    [6.586, -1.353]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [192.448, 128.157], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 10",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 10,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [12.532, 1.053],
                    [-12.532, 1.053],
                    [-12.532, -1.053],
                    [12.532, -1.053]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [150.35, 132.225], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 11",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 11,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.538, 1.353],
                    [-5.538, 1.353],
                    [-5.538, -1.353],
                    [5.538, -1.353]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [143.356, 128.157], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 12",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 12,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [21.095, 1.096],
                    [-21.095, 1.096],
                    [-21.095, -1.096],
                    [21.095, -1.096]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [158.913, 121.898], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 13",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 13,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [7.229, 1.353],
                    [-7.229, 1.353],
                    [-7.229, -1.353],
                    [7.229, -1.353]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [145.047, 117.787], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 14",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 14,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [19.888, 1.293],
                    [-19.888, 1.293],
                    [-19.888, -1.293],
                    [19.888, -1.293]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [174.366, 108.577], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 15",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 15,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [12.13, 1.127],
                    [-12.13, 1.127],
                    [-12.13, -1.127],
                    [12.13, -1.127]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [174.366, 112.634], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 16",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 16,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.245, 0],
                        [0, 0.244],
                        [0, 0],
                        [0.244, 0],
                        [0, -0.245]
                      ],
                      "o": [
                        [0, 0.244],
                        [0.244, 0],
                        [0, 0],
                        [0, -0.245],
                        [-0.245, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.397, 1.056],
                        [0, 1.484],
                        [0.398, 1.056],
                        [0.398, 0.138],
                        [0, -0.291],
                        [-0.397, 0.138]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.244, 0],
                        [0, 0],
                        [0, 0],
                        [0.458, 0],
                        [0, 0.733],
                        [0, 0],
                        [-0.521, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.245, 0],
                        [0, -0.215]
                      ],
                      "o": [
                        [0, 0.214],
                        [-0.275, 0],
                        [0, 0],
                        [0, 0],
                        [-0.521, 0],
                        [0, 0],
                        [0, -0.734],
                        [0.458, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.215],
                        [0.244, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.254, 1.759],
                        [0.827, 2.157],
                        [0.428, 1.913],
                        [0.368, 1.913],
                        [-0.305, 2.218],
                        [-1.254, 1.056],
                        [-1.254, 0.138],
                        [-0.305, -1.025],
                        [0.336, -0.75],
                        [0.398, -0.75],
                        [0.398, -1.82],
                        [0.827, -2.218],
                        [1.254, -1.82]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [192.253, 102.47], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, -0.214],
                        [0.245, 0],
                        [0, 0],
                        [0, -0.214],
                        [0, 0],
                        [0.244, 0],
                        [0, 0.214],
                        [0, 0],
                        [-0.245, 0],
                        [0, 0],
                        [0, 0],
                        [-0.367, 0]
                      ],
                      "o": [
                        [0.245, 0],
                        [0, 0.215],
                        [0, 0],
                        [-0.214, 0],
                        [0, 0],
                        [0, 0.214],
                        [-0.245, 0],
                        [0, 0],
                        [0, -0.214],
                        [0.275, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.597, -1.575],
                        [0.994, -1.209],
                        [0.597, -0.842],
                        [0.26, -0.842],
                        [-0.138, -0.413],
                        [-0.138, 1.178],
                        [-0.565, 1.575],
                        [-0.994, 1.178],
                        [-0.994, -1.147],
                        [-0.565, -1.545],
                        [-0.168, -1.301],
                        [-0.106, -1.301],
                        [0.413, -1.575]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [189.819, 103.052], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 2",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, -0.184],
                        [-0.184, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [-0.184, 0],
                        [0, 0.184],
                        [0.244, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.382, 0.276],
                        [-0.045, 0.276],
                        [-0.382, 0.612],
                        [-0.045, 0.949],
                        [0.382, 0.734]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.244, 0],
                        [0, 0],
                        [0, 0],
                        [0.428, 0],
                        [0, 0.551],
                        [-0.766, 0],
                        [0, 0],
                        [0, 0],
                        [0.214, 0],
                        [0.367, 0],
                        [0, 0.184],
                        [-0.673, 0],
                        [0, -0.611]
                      ],
                      "o": [
                        [0, 0.214],
                        [-0.275, 0],
                        [0, 0],
                        [0, 0],
                        [-0.521, 0],
                        [0, -0.55],
                        [0, 0],
                        [0, 0],
                        [0, -0.244],
                        [-0.336, 0],
                        [-0.214, 0],
                        [0, -0.244],
                        [0.704, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.239, 1.163],
                        [0.811, 1.56],
                        [0.413, 1.316],
                        [0.352, 1.316],
                        [-0.291, 1.622],
                        [-1.239, 0.642],
                        [-0.045, -0.336],
                        [0.382, -0.336],
                        [0.382, -0.49],
                        [0.015, -0.887],
                        [-0.719, -0.612],
                        [-1.086, -0.949],
                        [0.015, -1.622],
                        [1.239, -0.49]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [186.971, 103.067], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 3",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -0.397],
                        [0.275, 0],
                        [0.551, 0],
                        [0, -0.459],
                        [0, 0],
                        [-0.397, 0],
                        [-0.429, 0],
                        [0, -0.245],
                        [0.888, 0],
                        [0, 0.918],
                        [0, 0],
                        [-0.948, 0]
                      ],
                      "o": [
                        [0, 0.245],
                        [-0.428, 0],
                        [-0.397, 0],
                        [0, 0],
                        [0, 0.459],
                        [0.581, 0],
                        [0.275, 0],
                        [0, 0.397],
                        [-0.948, 0],
                        [0, 0],
                        [0, -0.918],
                        [0.856, 0]
                      ],
                      "v": [
                        [1.483, -1.315],
                        [1.024, -0.887],
                        [0.076, -1.407],
                        [-0.597, -0.673],
                        [-0.597, 0.673],
                        [0.076, 1.407],
                        [1.086, 0.856],
                        [1.545, 1.285],
                        [0.076, 2.264],
                        [-1.545, 0.673],
                        [-1.545, -0.673],
                        [0.076, -2.264]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [183.908, 102.455], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 4",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0.214, 0],
                        [0, -0.275],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, -0.275],
                        [-0.214, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.398, -0.336],
                        [0.398, -0.428],
                        [0, -0.887],
                        [-0.397, -0.428],
                        [-0.397, -0.336]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.244, 0],
                        [0, 0],
                        [0, 0],
                        [-0.244, 0],
                        [-0.337, 0],
                        [0, -0.152],
                        [0.734, 0],
                        [0, 0.643],
                        [0, 0],
                        [-0.704, 0],
                        [0, -0.643]
                      ],
                      "o": [
                        [0, 0.214],
                        [0, 0],
                        [0, 0],
                        [0, 0.275],
                        [0.459, 0],
                        [0.184, 0],
                        [0, 0.307],
                        [-0.734, 0],
                        [0, 0],
                        [0, -0.643],
                        [0.703, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.254, -0.091],
                        [0.827, 0.306],
                        [-0.397, 0.306],
                        [-0.397, 0.428],
                        [0.031, 0.887],
                        [0.827, 0.612],
                        [1.163, 0.917],
                        [0.031, 1.622],
                        [-1.254, 0.428],
                        [-1.254, -0.428],
                        [0, -1.622],
                        [1.254, -0.428]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [179.146, 103.067], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 5",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.734, 0],
                        [0, -0.367],
                        [0.214, 0],
                        [0.429, 0],
                        [0, -0.275],
                        [0, 0],
                        [-0.244, 0],
                        [-0.367, 0],
                        [0, -0.184],
                        [0.673, 0],
                        [0, 0.643]
                      ],
                      "o": [
                        [0, -0.643],
                        [0.673, 0],
                        [0, 0.184],
                        [-0.367, 0],
                        [-0.244, 0],
                        [0, 0],
                        [0, 0.275],
                        [0.429, 0],
                        [0.214, 0],
                        [0, 0.367],
                        [-0.734, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-1.224, -0.428],
                        [0.061, -1.622],
                        [1.162, -0.826],
                        [0.795, -0.49],
                        [0.061, -0.887],
                        [-0.367, -0.428],
                        [-0.367, 0.428],
                        [0.061, 0.887],
                        [0.857, 0.428],
                        [1.224, 0.765],
                        [0.061, 1.622],
                        [-1.224, 0.428]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [176.299, 103.067], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 6",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.245, 0],
                        [0, 0.214],
                        [0, 0],
                        [-0.245, 0],
                        [0, 0],
                        [0, 0],
                        [-0.459, 0],
                        [0, -0.734],
                        [0, 0],
                        [0.245, 0],
                        [0, 0.214],
                        [0, 0],
                        [0.245, 0],
                        [0, -0.245]
                      ],
                      "o": [
                        [0, 0.214],
                        [-0.245, 0],
                        [0, 0],
                        [0, -0.214],
                        [0.275, 0],
                        [0, 0],
                        [0, 0],
                        [0.52, 0],
                        [0, 0],
                        [0, 0.214],
                        [-0.245, 0],
                        [0, 0],
                        [0, -0.245],
                        [-0.245, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.398, 1.193],
                        [-0.826, 1.591],
                        [-1.254, 1.193],
                        [-1.254, -1.132],
                        [-0.826, -1.529],
                        [-0.428, -1.285],
                        [-0.367, -1.285],
                        [0.306, -1.591],
                        [1.254, -0.428],
                        [1.254, 1.193],
                        [0.826, 1.591],
                        [0.398, 1.193],
                        [0.398, -0.428],
                        [0, -0.856],
                        [-0.398, -0.428]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [173.266, 103.036], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 7",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 7,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, -0.184],
                        [-0.184, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [-0.184, 0],
                        [0, 0.184],
                        [0.245, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.383, 0.276],
                        [-0.046, 0.276],
                        [-0.383, 0.612],
                        [-0.046, 0.949],
                        [0.383, 0.734]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.245, 0],
                        [0, 0],
                        [0, 0],
                        [0.428, 0],
                        [0, 0.551],
                        [-0.765, 0],
                        [0, 0],
                        [0, 0],
                        [0.214, 0],
                        [0.367, 0],
                        [0, 0.184],
                        [-0.673, 0],
                        [0, -0.611]
                      ],
                      "o": [
                        [0, 0.214],
                        [-0.275, 0],
                        [0, 0],
                        [0, 0],
                        [-0.52, 0],
                        [0, -0.55],
                        [0, 0],
                        [0, 0],
                        [0, -0.244],
                        [-0.336, 0],
                        [-0.214, 0],
                        [0, -0.244],
                        [0.704, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.239, 1.163],
                        [0.811, 1.56],
                        [0.413, 1.316],
                        [0.352, 1.316],
                        [-0.291, 1.622],
                        [-1.239, 0.642],
                        [-0.046, -0.336],
                        [0.383, -0.336],
                        [0.383, -0.49],
                        [0.015, -0.887],
                        [-0.719, -0.612],
                        [-1.086, -0.949],
                        [0.015, -1.622],
                        [1.239, -0.49]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [170.158, 103.067], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 8",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 8,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, -0.214],
                        [0.245, 0],
                        [0, 0],
                        [0, -0.214],
                        [0, 0],
                        [0.245, 0],
                        [0, 0.214],
                        [0, 0],
                        [-0.245, 0],
                        [0, 0],
                        [0, 0],
                        [-0.367, 0]
                      ],
                      "o": [
                        [0.245, 0],
                        [0, 0.215],
                        [0, 0],
                        [-0.214, 0],
                        [0, 0],
                        [0, 0.214],
                        [-0.245, 0],
                        [0, 0],
                        [0, -0.214],
                        [0.275, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.597, -1.575],
                        [0.994, -1.209],
                        [0.597, -0.842],
                        [0.26, -0.842],
                        [-0.138, -0.413],
                        [-0.138, 1.178],
                        [-0.566, 1.575],
                        [-0.994, 1.178],
                        [-0.994, -1.147],
                        [-0.566, -1.545],
                        [-0.168, -1.301],
                        [-0.107, -1.301],
                        [0.413, -1.575]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [167.861, 103.052], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 9",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 9,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.245, 0],
                        [0, -0.214],
                        [0, 0],
                        [0.245, 0],
                        [0, 0],
                        [0, 0],
                        [0.459, 0],
                        [0, 0.733],
                        [0, 0],
                        [-0.245, 0],
                        [0, -0.214],
                        [0, 0],
                        [-0.245, 0],
                        [0, 0.244]
                      ],
                      "o": [
                        [0, -0.214],
                        [0.245, 0],
                        [0, 0],
                        [0, 0.214],
                        [-0.275, 0],
                        [0, 0],
                        [0, 0],
                        [-0.52, 0],
                        [0, 0],
                        [0, -0.214],
                        [0.245, 0],
                        [0, 0],
                        [0, 0.244],
                        [0.245, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.398, -1.193],
                        [0.826, -1.591],
                        [1.254, -1.193],
                        [1.254, 1.132],
                        [0.826, 1.529],
                        [0.428, 1.285],
                        [0.367, 1.285],
                        [-0.306, 1.591],
                        [-1.254, 0.429],
                        [-1.254, -1.193],
                        [-0.826, -1.591],
                        [-0.398, -1.193],
                        [-0.398, 0.429],
                        [0, 0.856],
                        [0.398, 0.429]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [164.997, 103.098], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 10",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 10,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0.704, 0],
                        [0, 0.306],
                        [-0.214, 0],
                        [-0.367, 0],
                        [0, 0.153],
                        [0, 1.04],
                        [-0.673, 0],
                        [0, -0.275],
                        [0.214, 0],
                        [0.336, 0],
                        [0, -0.153],
                        [0, -1.04]
                      ],
                      "o": [
                        [-0.734, 0],
                        [0, -0.184],
                        [0.367, 0],
                        [0.184, 0],
                        [0, -0.459],
                        [0, -0.459],
                        [0.673, 0],
                        [0, 0.184],
                        [-0.367, 0],
                        [-0.153, 0],
                        [0, 0.459],
                        [0, 0.459]
                      ],
                      "v": [
                        [0.015, 1.622],
                        [-1.178, 0.887],
                        [-0.811, 0.55],
                        [0.015, 0.949],
                        [0.321, 0.704],
                        [-1.086, -0.704],
                        [0.046, -1.622],
                        [1.117, -0.949],
                        [0.75, -0.612],
                        [0.046, -0.949],
                        [-0.229, -0.704],
                        [1.178, 0.704]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [162.104, 103.067], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 11",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 11,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.245, 0],
                        [0, 0.214],
                        [0, 0],
                        [-0.245, 0],
                        [0, 0],
                        [0, 0],
                        [-0.459, 0],
                        [0, -0.734],
                        [0, 0],
                        [0.245, 0],
                        [0, 0.214],
                        [0, 0],
                        [0.245, 0],
                        [0, -0.245]
                      ],
                      "o": [
                        [0, 0.214],
                        [-0.245, 0],
                        [0, 0],
                        [0, -0.214],
                        [0.275, 0],
                        [0, 0],
                        [0, 0],
                        [0.52, 0],
                        [0, 0],
                        [0, 0.214],
                        [-0.245, 0],
                        [0, 0],
                        [0, -0.245],
                        [-0.245, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.398, 1.193],
                        [-0.826, 1.591],
                        [-1.254, 1.193],
                        [-1.254, -1.132],
                        [-0.826, -1.529],
                        [-0.428, -1.285],
                        [-0.367, -1.285],
                        [0.306, -1.591],
                        [1.254, -0.428],
                        [1.254, 1.193],
                        [0.826, 1.591],
                        [0.398, 1.193],
                        [0.398, -0.428],
                        [0, -0.856],
                        [-0.398, -0.428]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [159.24, 103.036], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 12",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 12,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.275, 0],
                        [0, 0.214],
                        [0, 0],
                        [-0.275, 0],
                        [0, -0.214]
                      ],
                      "o": [
                        [0, 0.214],
                        [-0.275, 0],
                        [0, 0],
                        [0, -0.214],
                        [0.275, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.459, 1.774],
                        [0, 2.203],
                        [-0.459, 1.774],
                        [-0.459, -1.774],
                        [0, -2.203],
                        [0.459, -1.774]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [156.821, 102.455], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 13",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 13,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [156.821, 102.455], "ix": 2 },
              "a": { "a": 0, "k": [156.821, 102.455], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 17",
          "np": 13,
          "cix": 2,
          "bm": 0,
          "ix": 17,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [5.758, 0],
                    [0, 0],
                    [0, 5.758],
                    [0, 0],
                    [-5.758, 0],
                    [0, 0],
                    [0, -5.758],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-5.758, 0],
                    [0, 0],
                    [0, -5.758],
                    [0, 0],
                    [5.758, 0],
                    [0, 0],
                    [0, 5.758]
                  ],
                  "v": [
                    [35.845, 30.826],
                    [-35.845, 30.826],
                    [-46.27, 20.401],
                    [-46.27, -20.401],
                    [-35.845, -30.826],
                    [35.845, -30.826],
                    [46.27, -20.401],
                    [46.27, 20.401]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.525490224361, 0.525490224361, 0.525490224361, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.534, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.949019610882, 0.949019610882, 0.949019610882, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [175, 125], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 18",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 18,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 334.001237327809,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 4,
      "nm": "Group 2",
      "td": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [111, 66.5, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485],
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0],
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485]
                  ],
                  "v": [
                    [104.98, 64.328],
                    [-104.98, 64.328],
                    [-109.48, 59.828],
                    [-109.48, -59.635],
                    [-104.98, -64.135],
                    [104.98, -64.135],
                    [109.48, -59.635],
                    [109.48, 59.828]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.525490224361, 0.525490224361, 0.525490224361, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.534, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.847058832645, 0.847058832645, 0.847058832645, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 330.001222509512,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 4,
      "nm": "phone",
      "parent": 1,
      "tt": 1,
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [50, 64.188, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [175, 350, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [109, 109, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.288, 0],
                        [0, 0.288],
                        [0, 0],
                        [0.288, 0],
                        [0, -0.288]
                      ],
                      "o": [
                        [0, 0.288],
                        [0.288, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.468, 1.242],
                        [0, 1.747],
                        [0.468, 1.242],
                        [0.468, 0.162],
                        [0, -0.342],
                        [-0.468, 0.162]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [0.54, 0],
                        [0, 0.864],
                        [0, 0],
                        [-0.612, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.288, 0],
                        [0, -0.252]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [0, 0],
                        [0, -0.864],
                        [0.54, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.476, 2.071],
                        [0.972, 2.539],
                        [0.504, 2.25],
                        [0.432, 2.25],
                        [-0.36, 2.611],
                        [-1.476, 1.242],
                        [-1.476, 0.162],
                        [-0.36, -1.207],
                        [0.396, -0.882],
                        [0.468, -0.882],
                        [0.468, -2.143],
                        [0.972, -2.611],
                        [1.476, -2.143]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [190.436, 85.955], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 1",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 1,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.432, 0]
                      ],
                      "o": [
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.252, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.702, -1.854],
                        [1.17, -1.423],
                        [0.702, -0.99],
                        [0.306, -0.99],
                        [-0.163, -0.486],
                        [-0.163, 1.387],
                        [-0.667, 1.854],
                        [-1.17, 1.387],
                        [-1.17, -1.351],
                        [-0.667, -1.818],
                        [-0.198, -1.53],
                        [-0.126, -1.53],
                        [0.486, -1.854]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [187.574, 86.639], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 2",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 2,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, -0.217],
                        [-0.216, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [-0.216, 0],
                        [0, 0.216],
                        [0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.45, 0.324],
                        [-0.054, 0.324],
                        [-0.451, 0.72],
                        [-0.054, 1.116],
                        [0.45, 0.864]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [0.504, 0],
                        [0, 0.647],
                        [-0.9, 0],
                        [0, 0],
                        [0, 0],
                        [0.252, 0],
                        [0.432, 0],
                        [0, 0.216],
                        [-0.792, 0],
                        [0, -0.72]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [0, -0.648],
                        [0, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.396, 0],
                        [-0.252, 0],
                        [0, -0.288],
                        [0.828, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.458, 1.369],
                        [0.955, 1.836],
                        [0.486, 1.548],
                        [0.414, 1.548],
                        [-0.342, 1.909],
                        [-1.458, 0.756],
                        [-0.054, -0.396],
                        [0.45, -0.396],
                        [0.45, -0.577],
                        [0.018, -1.044],
                        [-0.846, -0.72],
                        [-1.279, -1.117],
                        [0.018, -1.909],
                        [1.458, -0.577]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [184.225, 86.657], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 3",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 3,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, -0.468],
                        [0.324, 0],
                        [0.648, 0],
                        [0, -0.541],
                        [0, 0],
                        [-0.468, 0],
                        [-0.504, 0],
                        [0, -0.289],
                        [1.045, 0],
                        [0, 1.08],
                        [0, 0],
                        [-1.116, 0]
                      ],
                      "o": [
                        [0, 0.288],
                        [-0.504, 0],
                        [-0.468, 0],
                        [0, 0],
                        [0, 0.54],
                        [0.685, 0],
                        [0.324, 0],
                        [0, 0.468],
                        [-1.116, 0],
                        [0, 0],
                        [0, -1.081],
                        [1.009, 0]
                      ],
                      "v": [
                        [1.746, -1.549],
                        [1.206, -1.045],
                        [0.09, -1.657],
                        [-0.702, -0.792],
                        [-0.702, 0.792],
                        [0.09, 1.656],
                        [1.278, 1.008],
                        [1.818, 1.513],
                        [0.09, 2.665],
                        [-1.818, 0.792],
                        [-1.818, -0.792],
                        [0.09, -2.665]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [180.624, 85.937], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 4",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 4,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0.252, 0],
                        [0, -0.324],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [0, -0.324],
                        [-0.252, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.469, -0.396],
                        [0.469, -0.504],
                        [0, -1.044],
                        [-0.468, -0.504],
                        [-0.468, -0.396]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.288, 0],
                        [-0.396, 0],
                        [0, -0.18],
                        [0.864, 0],
                        [0, 0.757],
                        [0, 0],
                        [-0.829, 0],
                        [0, -0.756]
                      ],
                      "o": [
                        [0, 0.252],
                        [0, 0],
                        [0, 0],
                        [0, 0.324],
                        [0.541, 0],
                        [0.216, 0],
                        [0, 0.36],
                        [-0.864, 0],
                        [0, 0],
                        [0, -0.756],
                        [0.828, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.476, -0.108],
                        [0.972, 0.36],
                        [-0.468, 0.36],
                        [-0.468, 0.503],
                        [0.036, 1.044],
                        [0.972, 0.72],
                        [1.369, 1.08],
                        [0.036, 1.909],
                        [-1.476, 0.503],
                        [-1.476, -0.504],
                        [0, -1.909],
                        [1.476, -0.504]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [175.025, 86.657], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 5",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 5,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.864, 0],
                        [0, -0.433],
                        [0.252, 0],
                        [0.504, 0],
                        [0, -0.324],
                        [0, 0],
                        [-0.288, 0],
                        [-0.432, 0],
                        [0, -0.216],
                        [0.792, 0],
                        [0, 0.757]
                      ],
                      "o": [
                        [0, -0.756],
                        [0.792, 0],
                        [0, 0.216],
                        [-0.432, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0.324],
                        [0.504, 0],
                        [0.252, 0],
                        [0, 0.433],
                        [-0.864, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-1.44, -0.504],
                        [0.072, -1.909],
                        [1.369, -0.972],
                        [0.936, -0.577],
                        [0.072, -1.044],
                        [-0.432, -0.504],
                        [-0.432, 0.503],
                        [0.072, 1.044],
                        [1.008, 0.503],
                        [1.44, 0.9],
                        [0.072, 1.909],
                        [-1.44, 0.503]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [171.676, 86.657], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 6",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 6,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.54, 0],
                        [0, -0.864],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, -0.288]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0.612, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.468, 1.405],
                        [-0.972, 1.873],
                        [-1.477, 1.405],
                        [-1.477, -1.333],
                        [-0.972, -1.8],
                        [-0.504, -1.512],
                        [-0.432, -1.512],
                        [0.36, -1.873],
                        [1.477, -0.504],
                        [1.477, 1.405],
                        [0.973, 1.873],
                        [0.468, 1.405],
                        [0.468, -0.504],
                        [0, -1.008],
                        [-0.468, -0.504]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [168.111, 86.621], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 7",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 7,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, 0],
                        [0, -0.217],
                        [-0.216, 0],
                        [0, 0]
                      ],
                      "o": [
                        [0, 0],
                        [-0.216, 0],
                        [0, 0.216],
                        [0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.45, 0.324],
                        [-0.054, 0.324],
                        [-0.45, 0.72],
                        [-0.054, 1.116],
                        [0.45, 0.864]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ind": 1,
                  "ty": "sh",
                  "ix": 2,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [0.504, 0],
                        [0, 0.647],
                        [-0.9, 0],
                        [0, 0],
                        [0, 0],
                        [0.252, 0],
                        [0.432, 0],
                        [0, 0.216],
                        [-0.792, 0],
                        [0, -0.72]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [0, -0.648],
                        [0, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.396, 0],
                        [-0.252, 0],
                        [0, -0.288],
                        [0.828, 0],
                        [0, 0]
                      ],
                      "v": [
                        [1.458, 1.369],
                        [0.955, 1.836],
                        [0.486, 1.548],
                        [0.415, 1.548],
                        [-0.342, 1.909],
                        [-1.458, 0.756],
                        [-0.054, -0.396],
                        [0.45, -0.396],
                        [0.45, -0.577],
                        [0.018, -1.044],
                        [-0.846, -0.72],
                        [-1.278, -1.117],
                        [0.018, -1.909],
                        [1.458, -0.577]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 2",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [164.457, 86.657], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 8",
              "np": 3,
              "cix": 2,
              "bm": 0,
              "ix": 8,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.432, 0]
                      ],
                      "o": [
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.252, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.702, -1.854],
                        [1.17, -1.423],
                        [0.702, -0.99],
                        [0.306, -0.99],
                        [-0.162, -0.486],
                        [-0.162, 1.387],
                        [-0.666, 1.854],
                        [-1.17, 1.387],
                        [-1.17, -1.351],
                        [-0.666, -1.818],
                        [-0.198, -1.53],
                        [-0.126, -1.53],
                        [0.486, -1.854]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [161.756, 86.639], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 9",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 9,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [-0.288, 0],
                        [0, -0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, 0],
                        [0, 0],
                        [0.54, 0],
                        [0, 0.864],
                        [0, 0],
                        [-0.288, 0],
                        [0, -0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0.288]
                      ],
                      "o": [
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, 0],
                        [-0.612, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.288, 0],
                        [0, 0],
                        [0, 0.288],
                        [0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.468, -1.405],
                        [0.972, -1.873],
                        [1.476, -1.405],
                        [1.476, 1.333],
                        [0.972, 1.8],
                        [0.504, 1.512],
                        [0.432, 1.512],
                        [-0.36, 1.873],
                        [-1.476, 0.503],
                        [-1.476, -1.405],
                        [-0.972, -1.873],
                        [-0.469, -1.405],
                        [-0.469, 0.503],
                        [0, 1.008],
                        [0.468, 0.503]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [158.389, 86.693], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 10",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 10,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0.829, 0],
                        [0, 0.359],
                        [-0.252, 0],
                        [-0.432, 0],
                        [0, 0.181],
                        [0, 1.225],
                        [-0.792, 0],
                        [0, -0.324],
                        [0.252, 0],
                        [0.396, 0],
                        [0, -0.18],
                        [0, -1.224]
                      ],
                      "o": [
                        [-0.864, 0],
                        [0, -0.217],
                        [0.432, 0],
                        [0.216, 0],
                        [0, -0.54],
                        [0, -0.54],
                        [0.792, 0],
                        [0, 0.216],
                        [-0.432, 0],
                        [-0.18, 0],
                        [0, 0.54],
                        [0, 0.541]
                      ],
                      "v": [
                        [0.018, 1.909],
                        [-1.386, 1.044],
                        [-0.954, 0.648],
                        [0.018, 1.116],
                        [0.378, 0.828],
                        [-1.279, -0.829],
                        [0.054, -1.909],
                        [1.314, -1.117],
                        [0.882, -0.72],
                        [0.054, -1.117],
                        [-0.27, -0.829],
                        [1.386, 0.828]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [154.987, 86.657], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 11",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 11,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.288, 0],
                        [0, 0],
                        [0, 0],
                        [-0.541, 0],
                        [0, -0.864],
                        [0, 0],
                        [0.288, 0],
                        [0, 0.252],
                        [0, 0],
                        [0.288, 0],
                        [0, -0.288]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0],
                        [0, 0],
                        [0.612, 0],
                        [0, 0],
                        [0, 0.252],
                        [-0.288, 0],
                        [0, 0],
                        [0, -0.288],
                        [-0.288, 0],
                        [0, 0]
                      ],
                      "v": [
                        [-0.469, 1.405],
                        [-0.972, 1.873],
                        [-1.476, 1.405],
                        [-1.476, -1.333],
                        [-0.972, -1.8],
                        [-0.504, -1.512],
                        [-0.432, -1.512],
                        [0.36, -1.873],
                        [1.476, -0.504],
                        [1.476, 1.405],
                        [0.972, 1.873],
                        [0.468, 1.405],
                        [0.468, -0.504],
                        [0, -1.008],
                        [-0.469, -0.504]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [151.62, 86.621], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 12",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 12,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "gr",
              "it": [
                {
                  "ind": 0,
                  "ty": "sh",
                  "ix": 1,
                  "ks": {
                    "a": 0,
                    "k": {
                      "i": [
                        [0, 0],
                        [0.324, 0],
                        [0, 0.252],
                        [0, 0],
                        [-0.324, 0],
                        [0, -0.252]
                      ],
                      "o": [
                        [0, 0.252],
                        [-0.324, 0],
                        [0, 0],
                        [0, -0.252],
                        [0.324, 0],
                        [0, 0]
                      ],
                      "v": [
                        [0.54, 2.089],
                        [0, 2.593],
                        [-0.54, 2.089],
                        [-0.54, -2.089],
                        [0, -2.593],
                        [0.54, -2.089]
                      ],
                      "c": true
                    },
                    "ix": 2
                  },
                  "nm": "Path 1",
                  "mn": "ADBE Vector Shape - Group",
                  "hd": false
                },
                {
                  "ty": "fl",
                  "c": { "a": 0, "k": [0, 0, 0, 1], "ix": 4 },
                  "o": { "a": 0, "k": 100, "ix": 5 },
                  "r": 1,
                  "bm": 0,
                  "nm": "Fill 1",
                  "mn": "ADBE Vector Graphic - Fill",
                  "hd": false
                },
                {
                  "ty": "tr",
                  "p": { "a": 0, "k": [148.775, 85.937], "ix": 2 },
                  "a": { "a": 0, "k": [0, 0], "ix": 1 },
                  "s": { "a": 0, "k": [100, 100], "ix": 3 },
                  "r": { "a": 0, "k": 0, "ix": 6 },
                  "o": { "a": 0, "k": 100, "ix": 7 },
                  "sk": { "a": 0, "k": 0, "ix": 4 },
                  "sa": { "a": 0, "k": 0, "ix": 5 },
                  "nm": "Transform"
                }
              ],
              "nm": "Group 13",
              "np": 2,
              "cix": 2,
              "bm": 0,
              "ix": 13,
              "mn": "ADBE Vector Group",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [148.775, 85.937], "ix": 2 },
              "a": { "a": 0, "k": [148.775, 85.937], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 13,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [2.411, 1.07],
                    [-2.411, 1.07],
                    [-2.411, -1.07],
                    [2.411, -1.07]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [191.022, 70.382], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 2",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 2,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.449, 1.07],
                    [-3.449, 1.07],
                    [-3.449, -1.07],
                    [3.449, -1.07]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [199.26, 70.382], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 3",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 3,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.263, 1.382],
                    [-18.263, 1.382],
                    [-18.263, -1.382],
                    [18.263, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [166.506, 194.727], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 4",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 4,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.263, 1.382],
                    [-18.263, 1.382],
                    [-18.263, -1.382],
                    [18.263, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [166.506, 189.495], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 5",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 5,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.896, 1.492],
                    [-5.896, 1.492],
                    [-5.896, -1.492],
                    [5.896, -1.492]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [154.138, 184.619], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 6",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 6,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.263, 1.382],
                    [-18.263, 1.382],
                    [-18.263, -1.382],
                    [18.263, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [166.506, 175.819], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 7",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 7,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.896, 1.492],
                    [-5.896, 1.492],
                    [-5.896, -1.492],
                    [5.896, -1.492]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [154.138, 170.943], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 8",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 8,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [18.483, 1.382],
                    [-18.483, 1.382],
                    [-18.483, -1.382],
                    [18.483, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [166.726, 160.478], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 9",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 9,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.874, 1.382],
                    [-10.874, 1.382],
                    [-10.874, -1.382],
                    [10.874, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [159.117, 155.721], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 10",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 10,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.896, 1.492],
                    [-5.896, 1.492],
                    [-5.896, -1.492],
                    [5.896, -1.492]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [154.138, 150.846], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 11",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 11,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [24.08, 1.382],
                    [-24.08, 1.382],
                    [-24.08, -1.382],
                    [24.08, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [172.323, 142.045], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 12",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 12,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.401, 1.492],
                    [-3.401, 1.492],
                    [-3.401, -1.492],
                    [3.401, -1.492]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [151.643, 137.17], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 13",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 13,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [12.074, 1.382],
                    [-12.074, 1.382],
                    [-12.074, -1.382],
                    [12.074, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [160.317, 128.369], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 14",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 14,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.896, 1.492],
                    [-5.896, 1.492],
                    [-5.896, -1.492],
                    [5.896, -1.492]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [154.138, 123.494], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 15",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 15,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [21.937, 1.382],
                    [-21.937, 1.382],
                    [-21.937, -1.382],
                    [21.937, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [170.18, 114.694], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 16",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 16,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [10.826, 1.492],
                    [-10.826, 1.492],
                    [-10.826, -1.492],
                    [10.826, -1.492]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [159.069, 109.818], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 17",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 17,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [6.743, 1.382],
                    [-6.743, 1.382],
                    [-6.743, -1.382],
                    [6.743, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [181.306, 101.018], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 18",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 18,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [11.607, 1.382],
                    [-11.607, 1.382],
                    [-11.607, -1.382],
                    [11.607, -1.382]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.823529422283, 0.823529422283, 0.823529422283, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [159.85, 101.018], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 19",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 19,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [5.896, 1.492],
                    [-5.896, 1.492],
                    [-5.896, -1.492],
                    [5.896, -1.492]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [154.138, 96.143], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 20",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 20,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  "v": [
                    [3.449, 1.07],
                    [-3.449, 1.07],
                    [-3.449, -1.07],
                    [3.449, -1.07]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [151.692, 70.382], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 21",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 21,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 3.513],
                    [0, 0],
                    [-3.513, 0],
                    [0, 0],
                    [0, -3.513],
                    [0, 0],
                    [3.513, 0]
                  ],
                  "o": [
                    [-3.513, 0],
                    [0, 0],
                    [0, -3.513],
                    [0, 0],
                    [3.513, 0],
                    [0, 0],
                    [0, 3.513],
                    [0, 0]
                  ],
                  "v": [
                    [-25.324, 69.661],
                    [-31.696, 63.289],
                    [-31.696, -63.289],
                    [-25.324, -69.661],
                    [25.324, -69.661],
                    [31.696, -63.289],
                    [31.696, 63.289],
                    [25.324, 69.661]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.525490224361, 0.525490224361, 0.525490224361, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.534, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.949019610882, 0.949019610882, 0.949019610882, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [175, 134.688], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 22",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 22,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [0, 0],
                    [0, 5.896],
                    [0, 0],
                    [-5.896, 0],
                    [0, 0],
                    [0, -5.896],
                    [0, 0],
                    [5.896, 0]
                  ],
                  "o": [
                    [-5.896, 0],
                    [0, 0],
                    [0, -5.896],
                    [0, 0],
                    [5.896, 0],
                    [0, 0],
                    [0, 5.896],
                    [0, 0]
                  ],
                  "v": [
                    [-25.324, 73.982],
                    [-36.016, 63.289],
                    [-36.016, -63.289],
                    [-25.324, -73.982],
                    [25.324, -73.982],
                    [36.016, -63.289],
                    [36.016, 63.289],
                    [25.324, 73.982]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.647058844566, 0.647058844566, 0.647058844566, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [175, 134.688], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 23",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 23,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 334.001237327809,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 4,
      "nm": "Group 1",
      "sr": 1,
      "ks": {
        "o": { "a": 0, "k": 100, "ix": 11 },
        "r": { "a": 0, "k": 0, "ix": 10 },
        "p": { "a": 0, "k": [111, 66.5, 0], "ix": 2, "l": 2 },
        "a": { "a": 0, "k": [0, 0, 0], "ix": 1, "l": 2 },
        "s": { "a": 0, "k": [100, 100, 100], "ix": 6, "l": 2 }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485],
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0]
                  ],
                  "o": [
                    [0, 0],
                    [-2.485, 0],
                    [0, 0],
                    [0, -2.485],
                    [0, 0],
                    [2.485, 0],
                    [0, 0],
                    [0, 2.485]
                  ],
                  "v": [
                    [104.98, 64.328],
                    [-104.98, 64.328],
                    [-109.48, 59.828],
                    [-109.48, -59.635],
                    [-104.98, -64.135],
                    [104.98, -64.135],
                    [109.48, -59.635],
                    [109.48, 59.828]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "st",
              "c": {
                "a": 0,
                "k": [0.525490224361, 0.525490224361, 0.525490224361, 1],
                "ix": 3
              },
              "o": { "a": 0, "k": 100, "ix": 4 },
              "w": { "a": 0, "k": 0.534, "ix": 5 },
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "bm": 0,
              "nm": "Stroke 1",
              "mn": "ADBE Vector Graphic - Stroke",
              "hd": false
            },
            {
              "ty": "fl",
              "c": {
                "a": 0,
                "k": [0.847058832645, 0.847058832645, 0.847058832645, 1],
                "ix": 4
              },
              "o": { "a": 0, "k": 100, "ix": 5 },
              "r": 1,
              "bm": 0,
              "nm": "Fill 1",
              "mn": "ADBE Vector Graphic - Fill",
              "hd": false
            },
            {
              "ty": "tr",
              "p": { "a": 0, "k": [0, 0], "ix": 2 },
              "a": { "a": 0, "k": [0, 0], "ix": 1 },
              "s": { "a": 0, "k": [100, 100], "ix": 3 },
              "r": { "a": 0, "k": 0, "ix": 6 },
              "o": { "a": 0, "k": 100, "ix": 7 },
              "sk": { "a": 0, "k": 0, "ix": 4 },
              "sa": { "a": 0, "k": 0, "ix": 5 },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 3,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 330.001222509512,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}
