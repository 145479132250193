import { isPossiblePhoneNumber } from 'react-phone-number-input';

import type { StepComponentSpec } from '../stepComponentSpec';

export type PhoneType = 'Home' | 'Mobile' | 'Work' | 'Other';

export type PhoneEntry = {
  phone_number: string;
  phone_type: PhoneType;
};

export interface ExistingPhoneEntry {
  source: 'original';
  original: PhoneEntry;
  update: PhoneEntry | null;
  shouldDelete: boolean;
}

export interface NewPhoneEntry {
  source: 'new';
  update: PhoneEntry;
}

export type UnderwritingMemoPhoneEntry = ExistingPhoneEntry | NewPhoneEntry;

interface UnderwritingMemoPhoneFields {
  existing_value?: UnderwritingMemoPhoneEntry[];
}

export function isReadyToContinue(
  entries: UnderwritingMemoPhoneEntry[] | undefined = [],
) {
  return entries.every(entry => {
    const isDeleted = entry.source === 'original' && entry.shouldDelete;
    const isUnchanged =
      entry.source === 'original' && !entry.shouldDelete && !entry.update;
    const hasValidChange =
      entry.source === 'original' &&
      isPossiblePhoneNumber(entry.update?.phone_number ?? '', 'US');
    const isValidNew =
      entry.source === 'new' &&
      isPossiblePhoneNumber(entry.update?.phone_number ?? '', 'US');
    return isDeleted || isUnchanged || hasValidChange || isValidNew;
  });
}

type UnderwritingMemoPhoneStepComponentSpec = StepComponentSpec<
  UnderwritingMemoPhoneFields,
  'underwriting_memo_phone'
>;

export default UnderwritingMemoPhoneStepComponentSpec;
