import React, { useEffect, useState } from 'react';

import { stripEmojis } from '../../utils';
import Modal from '../Modal';

interface OtherModalProps {
  showInput: boolean;
  onUpdate: (val: string) => void;
  onSave: () => void;
  onCancel?: () => void;
  prompt: string;
  value?: string;
  valueWrapper?: any;
}
const OtherModal: React.FC<OtherModalProps> = ({
  showInput,
  onUpdate,
  onSave,
  onCancel,
  prompt,
  value,
  valueWrapper,
}) => {
  const [input, setInput] = useState(value || '');

  useEffect(() => {
    setInput('');
  }, [value]);

  return (
    <>
      <Modal
        open={showInput}
        title={prompt || 'Tell us more...'}
        body={
          <div className="my-4">
            <textarea
              data-testid="otherInput"
              className="textbox text-base text-cool-gray-900"
              placeholder=""
              autoFocus
              value={input}
              onChange={e => {
                setInput(stripEmojis(e.target.value));
              }}
            />
          </div>
        }
        allowBackgroundDismiss={!!onCancel}
        customDismiss={onCancel}
        actions={(onCancel
          ? [
              {
                title: 'Cancel',
                primary: false,
                disabled: false,
                onClick: () => onCancel(),
              },
            ]
          : []
        ).concat([
          {
            title: 'Save',
            primary: true,
            disabled: !!onCancel && input?.length === 0,
            onClick: () => {
              if (input?.length === 0 && onCancel) {
                return onCancel();
              }

              if (valueWrapper) {
                onUpdate(
                  valueWrapper.replace(
                    '"__PLACEHOLDER__"',
                    JSON.stringify(input),
                  ),
                );
              } else {
                onUpdate(input);
              }
              onSave();
            },
          },
        ])}
      />
    </>
  );
};

export default OtherModal;
