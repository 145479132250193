import { YourInvolvementType } from './YourInvolvementTypes';

// Shared (FE/BE) helper functions for working with YourInvolvment

/**
 * Returns whether the candidate type is one of the vehicle types.
 *
 * @param candidateType The YourInvolvementType to check
 * @returns boolean
 */
export function isVehicleInvolvementType(
  candidateType: YourInvolvementType,
): boolean {
  return [
    YourInvolvementType.VEHICLE,
    YourInvolvementType.VEHICLE_UNSPECIFIED,
    YourInvolvementType.VEHICLE_UNKNOWN_SEAT,
    YourInvolvementType.UNINVOLVED_OWNER,
    YourInvolvementType.UNINVOLVED_NON_OWNER,
  ].includes(candidateType);
}

/**
 * Returns whether candidate type is one of the vehicle types
 * which are considered occupants.
 *
 * @param candidateType The YourInvolvementType to check
 * @returns boolean
 */
export function isVehicleOccupantInvolvementType(
  candidateType: YourInvolvementType,
): boolean {
  return [
    YourInvolvementType.VEHICLE,
    YourInvolvementType.VEHICLE_UNSPECIFIED,
    YourInvolvementType.VEHICLE_UNKNOWN_SEAT,
  ].includes(candidateType);
}
