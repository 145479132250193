import React from 'react';

import { isAndroid } from '@assured/ui/hooks/useMobileDetect';

import accident from '../images/icons/accident.svg';
import anvil_google from '../images/icons/anvil-google.png';
import anvil_mac from '../images/icons/anvil-mac.png';
import anvil_win from '../images/icons/anvil-win.png';
import atv from '../images/icons/atv.svg';
import bicycle from '../images/icons/bicycle.svg';
import boat from '../images/icons/boat.svg';
import boxtruck from '../images/icons/boxtruck.svg';
import bus from '../images/icons/bus-apple.svg';
import collisionreconstruction_4way from '../images/icons/collisionreconstruction/4_WAY.svg';
import collisionreconstruction_other from '../images/icons/collisionreconstruction/OTHER.svg';
import collisionreconstruction_tjunction from '../images/icons/collisionreconstruction/T_JUNCTION.svg';
import collisionreconstruction_trafficcircle from '../images/icons/collisionreconstruction/TRAFFIC_CIRCLE.svg';
import dirtbike from '../images/icons/dirtbike.svg';
import fence_mac from '../images/icons/fence-mac.png';
import fence_win from '../images/icons/fence-win.png';
import hit_property_google from '../images/icons/hit-property-google.svg';
import hit_property_mac from '../images/icons/hit-property-mac.svg';
import hit_property_win from '../images/icons/hit-property-win.svg';
import hit_while_parked_google from '../images/icons/hit-while-parked-google.svg';
import hit_while_parked_mac from '../images/icons/hit-while-parked-mac.svg';
import hit_while_parked_win from '../images/icons/hit-while-parked-win.svg';
import motorcycle from '../images/icons/motorcycle.svg';
import object_fell_from_vehicle_google from '../images/icons/object-fell-from-vehicle-google.svg';
import object_fell_from_vehicle_mac from '../images/icons/object-fell-from-vehicle-mac.svg';
import object_fell_from_vehicle_win from '../images/icons/object-fell-from-vehicle-win.svg';
import pencil from '../images/icons/pencil.svg';
import pickuptruck from '../images/icons/pickup-truck.png';
import pickup from '../images/icons/pickup.svg';
import plane from '../images/icons/plane.svg';
import roadside_google from '../images/icons/roadside-google.svg';
import roadside_mac from '../images/icons/roadside-mac.svg';
import roadside_win from '../images/icons/roadside-win.svg';
import rv from '../images/icons/rv.svg';
import sedan from '../images/icons/sedan.svg';
import semi from '../images/icons/semi.svg';
import suv from '../images/icons/suv.svg';
import theft from '../images/icons/theft.svg';
import van from '../images/icons/van.svg';
import { isWindows } from '../utils';

const IMAGES: Record<
  string,
  string | { mac: string; win: string; google?: string }
> = {
  atv,
  bicycle,
  boat,
  boxtruck,
  dirtbike,
  theft,
  rv,
  van,
  bus,
  accident,
  motorcycle,
  pencil,
  pickup,
  pickuptruck,
  plane,
  sedan,
  semi,
  suv,
  fence: { mac: fence_mac, win: fence_win },
  anvil: { mac: anvil_mac, win: anvil_win, google: anvil_google },
  collisionreconstruction_4way,
  collisionreconstruction_other,
  collisionreconstruction_tjunction,
  collisionreconstruction_trafficcircle,
  'hit-while-parked': {
    mac: hit_while_parked_mac,
    win: hit_while_parked_win,
    google: hit_while_parked_google,
  },
  'object-fell-from-vehicle': {
    mac: object_fell_from_vehicle_mac,
    win: object_fell_from_vehicle_win,
    google: object_fell_from_vehicle_google,
  },
  'hit-property': {
    mac: hit_property_mac,
    win: hit_property_win,
    google: hit_property_google,
  },
  roadside: {
    mac: roadside_mac,
    win: roadside_win,
    google: roadside_google,
  },
};

interface IconProps {
  icon?: string;
  text?: string;
  small?: boolean;
}

const Icon: React.FC<IconProps> = ({ icon, text, small }) => {
  if (text) {
    return (
      <div
        className="h-20 flex flex-col justify-center"
        style={{ minWidth: 60 }}
      >
        {/* text-3xl text-4xl text-5xl text-6xl */}
        <div className={`text-${Math.max(6 - text.length, 3)}xl leading-none`}>
          {text}
        </div>
      </div>
    );
  } else if (icon) {
    const iconPayload = IMAGES[icon];
    if (!iconPayload) {
      return null;
    }
    let iconSrc;

    if (typeof iconPayload === 'string') {
      iconSrc = iconPayload;
    } else {
      iconSrc = isWindows()
        ? iconPayload.win
        : isAndroid()
        ? iconPayload.google ?? iconPayload.mac
        : iconPayload.mac;
    }

    return (
      <img
        className={small ? 'w-6 h-6' : 'w-20 h-20 p-5 mx-auto'}
        src={iconSrc}
        alt={icon}
      />
    );
  }

  return null;
};

export default Icon;
