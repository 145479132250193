import UnitInput from '../UnitInput';
import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

export const feet_inches: StringInputVariety = ({
  step_component,
  primaryValue,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  const Component = UnitInput;
  const v = primaryValue ? +primaryValue : 0;

  const additionalProps = {
    value: v || undefined,
    onRawChange: (val: number) => updateValue(step_component.field, val),
    subdivisions: [
      {
        label: 'ft',
        base_value: 12,
      },
      {
        label: 'in',
        base_value: 1,
      },
    ],
    style: { textAlign: 'center' },
  };
  return {
    Component,
    additionalProps,
    narrow: !!step_component.narrow,
  };
};
