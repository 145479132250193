import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

import { TranslateIcon } from '@heroicons/react/solid';

import PageLoader from './PageLoader';

const AVAILABLE_LANGUAGES = [
  { code: 'en', label: 'English' },
  { code: 'es', label: 'Español' },
];

const LanguageSwitcher: React.FC<{
  className?: string;
  onChange?: () => void;
}> = ({ className, onChange }) => {
  const [showLoader, setShowLoader] = useState(false);
  const [language, setLanguage] = useState(
    window.Localize?.getLanguage() || 'en',
  );

  useEffect(() => {
    const handler = (data: { from: string; to: string }) => {
      setLanguage(data.to);
    };

    window.Localize?.on('setLanguage', handler);
    return () => window.Localize?.off('setLanguage', handler);
  }, []);

  return (
    <div
      className={classNames(
        'flex items-center justify-center leading-none text-base',
        className,
      )}
    >
      {showLoader ? (
        <div
          className="fixed inset-0 z-50"
          style={{ backgroundColor: 'rgba(255,255,255,0.5)' }}
        >
          <PageLoader />
        </div>
      ) : null}
      <TranslateIcon className="h-6 w-6" />
      <span className="ml-1 mr-2">Switch language:</span>
      {AVAILABLE_LANGUAGES.filter(l => l.code !== language).map(l => (
        <button
          key={l.code}
          onClick={() => {
            setShowLoader(true);
            setTimeout(() => {
              window.Localize?.setLanguage(l.code);
              setShowLoader(false);
              onChange?.();
            }, 500);
          }}
          className="bg-cool-gray-500 hover:bg-cool-gray-600 text-white px-2 py-1 rounded font-medium focus:outline-none focus:shadow-outline-gray mr-2"
        >
          {l.label}
        </button>
      ))}
    </div>
  );
};

export default LanguageSwitcher;
