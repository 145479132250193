import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import IncrementableSmallNumber from '../IncrementableSmallNumber';
import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

export const small_number: StringInputVariety = ({
  step_component,
  primaryValue,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  // TODO extract struff from IncSmallNumber and put it in here
  const Component = IncrementableSmallNumber;
  const v = primaryValue ? +primaryValue : 0;

  const makeNumericInputCleaner = (limit: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let val: number | '' = '';
      // Strip all non-numeric characters
      const strVal = e ? e.target.value.replace(/[^0-9]/g, '') : '';
      // If we're left with something numeric, use it
      if (!Number.isNaN(parseInt(strVal, 10))) {
        val = parseInt(strVal, 10);
      }
      // If val is numeric, enforce minimum and maximum values (if present)
      if (typeof val === 'number') {
        if (
          typeof step_component.minimum === 'number' &&
          val < step_component.minimum
        ) {
          val = step_component.minimum;
        }
        if (val > limit) {
          val = limit;
        }
      }
      // Update after cleaning
      updateValue(step_component.field, val);
    };
  };

  const additionalProps = {
    mask: createNumberMask({ prefix: '' }),
    type: 'tel',
    value: v || v === 0 ? v : undefined,
    placeholder: '0',
    minimum: step_component.minimum,
    onChange: makeNumericInputCleaner(step_component.maximum ?? 99),
    onRawChange: (localV: number) => updateValue(step_component.field, localV),
    maximum: step_component.maximum ?? 99,
    initialStepSize: step_component?.step_size,
    style: { textAlign: 'center', maxWidth: 60 },
    disabled: step_component.disabled,
    ...(step_component.mode === 'small_number'
      ? {
          mode: 'small_number',
        }
      : {}),
    ...(step_component.mode === 'mph_number'
      ? {
          mode: 'mph_number',
        }
      : {}),
  };

  return {
    Component,
    additionalProps,
    narrow: true,
  };
};
