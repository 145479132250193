import { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

const Go: React.FC<RouteComponentProps<{ splat: string }>> = ({ match }) => {
  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_ENDPOINT}/dispatch/${match.params.splat}`;
  });
  return <div />;
};

export default Go;
