import { useEffect, useState } from 'react';

import { gql, useApolloClient, useQuery } from '@apollo/client';
import { LocationValue } from '@assured/design-system/src/components/Input/Location';
import { LocationFragment } from '@assured/shared-types/Location';
import {
  IntersectionWizardStepComponentSpec,
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer';
import { IntersectionWizardScreen } from '@assured/step-renderer/types/step-components/IntersectionWizard';

import { LoadingOverlay } from '../LoadingOverlay';
import LocationManualInput from './Location/LocationManualInput';

export type IntersectionWizardProps = StepComponentSharedProps<
  IntersectionWizardStepComponentSpec,
  string | LocationValue
>;

/**
 * Currently, the only screens supported in self-service FNOL are street1 and street2.
 * The other screens are handled by the backend workflow.
 */
export const IntersectionWizard: StepComponentFC<IntersectionWizardProps> = (
  props: IntersectionWizardProps,
) => {
  switch (props.step_component.screen) {
    case IntersectionWizardScreen.street1:
      return <Street1Screen {...props} />;
    case IntersectionWizardScreen.street2:
      return <Street2Screen {...props} />;
  }
  return <div />;
};

IntersectionWizard.stepConfig = {
  controlsTitle: true,
};

const GEOCODE_CITY_QUERY = gql`
  query GeocodeCity($city: String!, $state: String!) {
    enrichLocation(location: { city: $city, state: $state }) {
      latitude
      longitude
      addressText
      postalCode
      country
    }
  }
`;

const Street1Screen = ({
  step_component,
  updateValue,
  primaryValue,
}: IntersectionWizardProps) => {
  const { data: geocodeCityData, loading: geocodeCityLoading } = useQuery(
    GEOCODE_CITY_QUERY,
    {
      variables: {
        city: step_component.workflowState.city,
        state: step_component.workflowState.state,
      },
    },
  );

  if (geocodeCityLoading) {
    return <LoadingOverlay />;
  }

  return (
    <>
      <h1 className="text-lg text-cool-gray-600">
        What was the first street of the intersection?
      </h1>
      <LocationManualInput
        onDevice={() => {}}
        noApartmentEntry={true}
        noGpsEntry={true}
        initialAddressText={
          typeof primaryValue === 'string'
            ? primaryValue
            : primaryValue?.addressText ??
              step_component.workflowState.intersectionStreet1 ??
              ''
        }
        initialValue={
          {
            addressText:
              typeof primaryValue === 'string'
                ? primaryValue
                : primaryValue?.addressText ??
                  step_component.workflowState.intersectionStreet1 ??
                  '',
            line1: step_component.workflowState.intersectionStreet1 ?? '',
          } as LocationFragment
        }
        includeAddressComponents={true}
        onSubmit={value => {
          updateValue(step_component.field, value?.line1);
        }}
        autoSubmitInline={false}
        placeTypes={['route']}
        expected_countries={['US']}
        placeholder="Start typing a street name..."
        locationBias={geocodeCityData?.enrichLocation ?? undefined}
      />
    </>
  );
};

const GEOCODE_INTERSECTION_QUERY = gql`
  query GeocodeIntersection(
    $city: String!
    $state: String!
    $street1: String!
    $street2: String!
  ) {
    geocodeIntersection(
      city: $city
      state: $state
      street1: $street1
      street2: $street2
    ) {
      latitude
      longitude
      addressText
      postalCode
    }
  }
`;

const Street2Screen = ({
  step_component,
  updateValue,
  primaryValue,
}: IntersectionWizardProps) => {
  const [street2, setStreet2] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const { data: geocodeCityData, loading: geocodeCityLoading } = useQuery(
    GEOCODE_CITY_QUERY,
    {
      variables: {
        city: step_component.workflowState.city,
        state: step_component.workflowState.state,
      },
    },
  );
  const [submittedAddressText, setSubmittedAddressText] = useState<
    string | null
  >(null);

  const apolloClient = useApolloClient();
  useEffect(() => {
    if (street2) {
      setLoading(true);
      apolloClient
        .query({
          query: GEOCODE_INTERSECTION_QUERY,
          variables: {
            city: step_component.workflowState.city,
            state: step_component.workflowState.state,
            street1: step_component.workflowState.intersectionStreet1,
            street2: street2,
          },
        })
        .then(({ data }) => {
          updateValue(step_component.field, {
            city: step_component.workflowState.city,
            state: step_component.workflowState.state,
            line1: `${step_component.workflowState.intersectionStreet1} & ${street2}`,
            line2: '',
            ...data.geocodeIntersection,
          });
        })
        .catch(err => setError(err.message))
        .finally(() => setLoading(false));
    } else if (submittedAddressText === '') {
      updateValue(step_component.field, '');
    }
  }, [street2]);

  if (error.includes('No intersection found')) {
    return (
      <div>
        <h1 className="text-lg text-cool-gray-600">
          <span className="font-bold">
            {step_component.workflowState?.intersectionStreet1}
          </span>{' '}
          does not intersect with <span className="font-bold">{street2}</span>
        </h1>

        <button
          className="btn btn-blue"
          onClick={() => {
            setStreet2('');
            setError('');
          }}
        >
          Try again
        </button>
      </div>
    );
  }

  return loading || geocodeCityLoading ? (
    <LoadingOverlay />
  ) : (
    <>
      <h1 className="text-lg text-cool-gray-600">
        Which street intersected with{' '}
        <span className="font-bold">
          {step_component.workflowState.intersectionStreet1}
        </span>
        ?
      </h1>

      <LocationManualInput
        onDevice={() => {}}
        noApartmentEntry={true}
        noGpsEntry={true}
        initialAddressText={
          street2 ??
          (typeof primaryValue === 'string'
            ? primaryValue
            : primaryValue?.addressText ?? '')
        }
        initialValue={{
          addressText: submittedAddressText ?? street2,
          line1:
            (primaryValue as LocationFragment)?.line1 ??
            (step_component.existing_location as LocationFragment)?.addressText
              ?.split(', ')?.[0]
              ?.split(' & ')?.[1],
        }}
        includeAddressComponents={true}
        onSubmit={value => {
          setSubmittedAddressText(value.addressText);
          setStreet2(value.line1);
        }}
        placeTypes={['route']}
        expected_countries={['US']}
        placeholder="Start typing a street name..."
        locationBias={geocodeCityData?.enrichLocation ?? undefined}
      />
    </>
  );
};
