import { BackOptionKey, DisplayMode, RegionTemplate } from './types';

export const back: { back: RegionTemplate<BackOptionKey> } = {
  back: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'spine',
        label: 'Spine',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'upper_back',
        label: 'Upper back',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'lower_back',
        label: 'Lower back',
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
    options: [
      {
        key: 'vertebrae_fracture',
        label: 'Vertebrae fracture',
        tabs: ['spine'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'herniated_disk',
        label: 'Herniated disk',
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        tabs: ['spine'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'back_nerve_pinch',
        label: 'Pinched nerve',
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        tabs: ['spine'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },

      {
        key: 'spinal_nerve_damage',
        label: 'Spinal nerve damage',
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        tabs: ['spine'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
          {
            key: 'paralysis',
            label: 'Is full or partial paralysis currently present?',
          },
        ],
      },

      {
        key: 'sprain_strain',
        label: 'Back sprain / strain',
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
        display_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
        tabs: [],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'sprain_strain_upper',
        label: 'Upper back sprain / strain',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['upper_back'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'tail_bone_fracture',
        label: 'Tail bone fracture',
        tabs: ['lower_back'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'tail_bone_bruise',
        label: 'Tail bone bruise',
        tabs: ['lower_back'],
      },
      {
        key: 'sprain_strain_lower',
        label: 'Lower back sprain / strain',
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        tabs: ['lower_back'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'soreness_pain',
        label: 'Muscle soreness / generalized pain',
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
        display_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
        tabs: [],
      },
    ],
  },
};
