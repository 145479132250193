import { getMobileDetect } from '@assured/utilities';

// Reproduced from @assured/ui/hooks/useMobileDetect to avoid a (circular) dependency on that package
const _mobileDetectData = getMobileDetect(navigator.userAgent);

export const isAndroid = () => _mobileDetectData.isAndroid;

export const useMobileDetect = () => {
  return _mobileDetectData;
};
