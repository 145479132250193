import classNames from 'classnames';
import React from 'react';

import { dataTestId } from '../utilities/dataTestId';

interface DropdownProps {
  placeholder?: string;
  value?: string;
  options: (string | { value: string; label: string })[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  hasError?: boolean;
  ariaLabelledBy?: string | null;
  ariaLabel?: string | null;
  id?: string | null;
}

const Dropdown: React.FC<DropdownProps> = ({
  placeholder,
  value,
  options,
  onChange,
  hasError,
  ariaLabelledBy,
  ariaLabel,
  id,
}) => {
  return (
    <span className="Dropdown block relative rounded-md shadow-sm text-cool-gray-900 transition ease-in-out duration-150">
      <select
        aria-labelledby={ariaLabelledBy ? ariaLabelledBy : ''}
        aria-label={ariaLabel ? ariaLabel : ''}
        value={value || ''}
        aria-invalid={hasError}
        onChange={onChange}
        id={id ? id : ''}
        className={classNames(
          'cursor-pointer appearance-none inline-flex justify-center w-full rounded-md border-2 border-cool-gray-300 px-4 py-2 bg-white focus:outline-none focus:shadow-outline transition ease-in-out duration-150 text-base',
          hasError && 'Shake border-red-500',
          !value && 'text-cool-gray-400',
        )}
      >
        <option value="" disabled selected hidden>
          {placeholder}
        </option>
        {(options || []).map(o => {
          const value = typeof o === 'string' ? o : o.value;
          const label = typeof o === 'string' ? o : o.label;

          return (
            <option data-testid={dataTestId(label)} key={value} value={value}>
              {label}
            </option>
          );
        })}
      </select>
      <svg
        className="absolute right-2 h-5 w-5 select-none pointer-events-none"
        style={{ top: 12 }}
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </span>
  );
};

export default Dropdown;
