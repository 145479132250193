/**
 * Type guard that checks if a given value is not empty (i.e. not null or undefined).
 *
 * @param value The value to check.
 * @returns True if the value is not empty, false otherwise.
 * @example
 * const arr: (number | null)[] = [0, null, 1, null];
 * const filtered = arr.filter(notEmpty); // filtered is now [0, 1]
 */
export const notEmpty = <X>(value: X | null | undefined): value is X => {
  if (value === null || value === undefined) return false;
  return true;
};

/**
 * Type guard that checks if a given string is not empty (i.e. not null or undefined and contains non-whitespace).
 *
 * @param value The string to check.
 * @returns True if the string is not empty, false otherwise.
 */
export const notEmptyString = (
  value: string | null | undefined,
): value is string => {
  if (value === null || value === undefined) return false;
  return /\S/.test(value); // returns true on the first non-whitespace character found
};

/**
 * Generates a type guard function that checks if all the specified fields on an object
 * are not empty (i.e. not null or undefined).
 *
 * @param keys The keys of the fields to check.
 * @returns A type guard function.
 * @example
 * const guard = makeNotEmptyFieldGuard(['foo', 'bar']);
 * const obj: { foo: number | null; bar: string | undefined; baz: boolean } = { foo: 0, bar: '', baz: false };
 * if (guard(obj)) {
 *  // obj is now narrowed to { foo: number; bar: string; baz: boolean }
 * }
 */
export const makeNotEmptyFieldGuard = <
  T extends object = { [key: string]: any },
  K extends keyof T = keyof T,
>(
  keys: K[],
): ((item: T) => item is T & Record<K, NonNullable<T[K]>>) => {
  return (item: T): item is T & Record<K, NonNullable<T[K]>> => {
    return keys.every(key => notEmpty(item[key]));
  };
};

/**
 * A utility type to represent the result of concatenated strings.
 */
export type Concat<T extends string[]> = T extends [
  infer F extends string,
  ...infer R extends string[],
]
  ? `${F}${Concat<R>}`
  : '';

/**
 * A utility type to recursively strip the readonly modifier from all properties of an object.
 */
export type DeepWriteable<T> = {
  -readonly [P in keyof T]: DeepWriteable<T[P]>;
};
