import { VehicleOccupantSeat } from '../Claim/Vehicle/InvolvedParty';
import { VehicleLayout } from './VehicleLayout';

/**
 * This structure is used to communicate changes to the current user's
 * involvement in an incident between front and back ends.  With the exception
 * of the isInvolved boolean, it represents underlying data from the claim
 * object and is translated both from and to the underlying claim.
 *
 * As a claim represents one particular perspective on an incident, *only* claim
 * data is modified based on the user's responses. Case data (contacts, types,
 * etc.) are shared across all perspective claims, so this case data is never
 * modified by any one individual perspective.
 *
 * The relationship of YourInvolvementData to claim data is as follows:
 *
 * isWitness comes from an all-new claim field, filerIsWitness.
 *
 * Otherwise, the involved party with the filerPartyOfClaimId field set to the claim
 * concretely identifies the filer's involved party. The fields of the party are
 * used to determine fields of this structure: type, vehicle data, isOccupant, etc.
 *
 */
export type YourInvolvementData = {
  /**
   * A way to differentiate between different YourInvolvementData objects. Primarily
   * used in the UI, which holds a mutating object with all potential involvement types
   * in memory. This identifier is used to know which one is currently selected.
   * Its value is not persisted in the database.
   *
   * Our implementation generally handles it with:
   * - Involved Parties: [type]-party-[partyId] i.e. "bicycle-party-clzzzed9s000008jrcpsg3cwk"
   * - Witness: "witness-identifier" (there should only ever be one of these)
   * - Not Involved: "not-involved-identifier" (there should only ever be one of these)
   */
  identifier: string;

  /**
   * The type of involvement the user has in the claim. This is also used in the UI to visually
   * present the involvement to the user with different labels and graphics.
   */
  involvementType: YourInvolvementType;

  /**
   * The user can state that they are not actually involved in the incident. This boolean
   * is the explicit way to state that. When selected, it removes any of the ways the current
   * user can be connected to the claim.
   */
  isInvolved: boolean;

  /**
   * Witnesses are not included in the involved parties, and this boolean is used to explicitly
   * state this other type of connection. When true, it will result in the current user's
   * name/phone number being added to the claim.witnesses array and vice versa.
   */
  isWitness: boolean;

  /**
   * The ID of the related involved party for the current user. This is determined by matching
   * the InvolvedParty.personName/personPhoneNumber to the current user's name/phone number.
   */
  involvedPartyId?: string;

  /**
   * When the involved party is a vehicle, the additional data about that
   * vehicle and the current user's involvement is contained here.
   */
  vehicleData?: YourInvolvementVehicleData;
};

/**
 * This type describes additional data about the current user's involvement in the vehicle.
 * It helps the UI to properly visualize the vehicle and the user's relationship to it.
 */
export type YourInvolvementVehicleData = {
  /**
   * Is the user the owner of the vehicle?
   */
  isOwner?: boolean;

  /**
   * Is the user an occupant of the vehicle?
   */
  isOccupant?: boolean;

  /**
   * Which seat was the user in?
   */
  seat?: VehicleOccupantSeat | null;

  /**
   * If known, the color of the vehicle as a hex string
   */
  color?: string | null;

  /**
   * If known, a human-friendly text label for the vehicle's color
   */
  colorLabel?: string | null;

  /**
   * If known, the vehicle's make
   */
  make?: string | null;

  /**
   * If known, the vehicle's model
   */
  model?: string | null;

  /**
   * The vehicle's layout, for presenting seat map visualizations.
   */
  vehicle_layout?: Omit<VehicleLayout, 'body'> & {
    // I'm struggling with some Typing errors between various different
    // enums and constants describing BodyType. This is a workaround.
    body: number;
  };
  // Ideally, we'd just use the shared dypte VehicleLayout including
  // it's definition of body: BodyType:
  // vehicle_layout?: VehicleLayout;
};

/**
 * This enumeration identifies the different way the current user can be involved in a claim.
 */
export enum YourInvolvementType {
  /**
   * Pedestrian involved party.
   */
  PEDESTRIAN = 'PEDESTRIAN',

  /**
   * Bicycle involved party.
   */
  BICYCLIST = 'BICYCLIST',

  /**
   * Witness, from the claim.witnesses array.
   */
  WITNESS = 'WITNESS',

  /**
   * This value is used when the user has selected a vehicle, but has not specifically
   * answered questions about ownership, occupancy or seat location.
   */
  VEHICLE_UNSPECIFIED = 'VEHICLE_UNSPECIFIED',

  /**
   * A vehicle involved party where the user is an occupant in a known seat.
   */
  VEHICLE = 'VEHICLE',

  /**
   * A vehicle involved party where the seat is explicitly unknown (versus unanswered)
   */
  VEHICLE_UNKNOWN_SEAT = 'VEHICLE_UNKNOWN_SEAT',

  /**
   * Owner of the vehicle, but not an occupant during the incident.
   */
  UNINVOLVED_OWNER = 'UNINVOLVED_OWNER',

  /**
   * Related to the vehicle, but not an owner, and not an occupant.
   */
  UNINVOLVED_NON_OWNER = 'UNINVOLVED_NON_OWNER',

  /**
   * Explicitly stated as not involved in the incident.
   */
  NOT_INVOLVED = 'NOT_INVOLVED',

  /**
   * Only used for fallback/error-handling cases.
   */
  UNKNOWN = 'UNKNOWN',
}
