import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import IncrementableSmallNumber from '../IncrementableSmallNumber';
import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

export const distance_number: StringInputVariety = ({
  step_component,
  primaryValue,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  // TODO extract struff from IncSmallNumber and put it in here
  const Component = IncrementableSmallNumber;
  const defaultUnits = step_component.initial_distance_units || 'feet';
  const [strValue, units] = primaryValue
    ? primaryValue.toString().split(' ')
    : [undefined, defaultUnits];
  const numericValue = parseInt(strValue || '0', 10);

  const makeNumericInputCleaner = (limit: number) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      let val: number | '' = '';
      const strVal = e ? e.target.value.replace(/[^0-9]/g, '') : '';
      val = parseInt(strVal, 10);
      if (Number.isSafeInteger(val)) {
        if (step_component.minimum && val < step_component.minimum) {
          val = step_component.minimum;
        }
        if (val > limit) {
          val = limit;
        }
      } else {
        // Non-numeric/invalid input, set to 0
        updateValue(step_component.field, `0 ${units}`);
        return;
      }
      updateValue(step_component.field, `${val} ${units}`);
    };
  };

  const additionalProps = {
    mask: createNumberMask({ prefix: '' }),
    type: 'tel',
    value: numericValue,
    placeholder: '0',
    minimum: step_component.minimum,
    onChange: makeNumericInputCleaner(9999),
    onRawChange: (localV: number | string) =>
      updateValue(step_component.field, localV),
    maximum: 9999,
    initialStepSize: step_component?.step_size,
    mode: 'distance_number',
    style: { textAlign: 'center', maxWidth: 120 },
    initialUnits: units,
  };

  return {
    Component,
    additionalProps,
    narrow: true,
  };
};
