import { StringInputVarietyProps, StringInputVarietyResult } from './types';

export const number = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  props: StringInputVarietyProps,
): StringInputVarietyResult => {
  const additionalProps = {
    type: 'number',
    placeholder: '0',
    style: { textAlign: 'center' },
  };
  return {
    Component: 'input',
    additionalProps,
    narrow: true,
  };
};
