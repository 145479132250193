import './CustomCarKnob.css';

import React, { useState } from 'react';

const { default: Knob, SkinWrap, composeTwo } = require('react-dial-knob');

export interface CustomCarKnobProps {
  diameter: number;
  value: number;
  min: number;
  max: number;
  step: number;
  spaceMaxFromZero?: boolean;
  ariaLabelledBy?: string;
  ariaValueText?: string;
  knobStyle?: React.CSSProperties;
  children?: React.ReactNode;
  onAngleChange?: (angle: number) => void;
  onInteractionChange?: (isInteracting: boolean) => void;
  onValueChange?: (value: number) => void;
  color?: string;
  interactive?: boolean;
}

const onTouchMoveBlock = (e: Event) => {
  e.preventDefault();
};

export default function MyKnobSkin(props: CustomCarKnobProps) {
  const [angle, setAngle] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const angleChangeHandler = composeTwo(setAngle, props.onAngleChange);

  const interactionChangeHandler = composeTwo(
    setIsActive,
    props.onInteractionChange,
  );

  const isTesla = document.body.classList.contains('tenant-tesla');

  const svgProps = {
    transform: `rotate(${angle})`,
    className: `${props.interactive && 'cursor-pointer'}`,
    style: { transform: `rotate(${angle}deg)`, touchAction: 'none' },
  };

  return (
    <SkinWrap>
      <Knob
        diameter={props.diameter}
        value={props.value}
        min={props.min}
        max={props.max}
        step={props.step}
        onAngleChange={angleChangeHandler}
        onInteractionChange={(isInteracting: boolean) => {
          if (props.interactive) {
            if (isInteracting) {
              document.body.classList.add('block-scroll');
            } else {
              document.body.classList.remove('block-scroll');
            }
            interactionChangeHandler(isInteracting);
          }
        }}
        onValueChange={props.onValueChange}
      >
        {isTesla ? (
          <svg
            viewBox="0 0 138 138"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...svgProps}
          >
            <g filter="url(#filter0_d)">
              <g filter="url(#filter1_d)">
                <ellipse
                  cx="69.1095"
                  cy="12.9054"
                  rx="10.1095"
                  ry="10.308"
                  fill="#CBD5E0"
                />
              </g>
              <g filter="url(#filter2_d)">
                <circle
                  cx="69"
                  cy="69"
                  r="58"
                  stroke="url(#paint0_linear)"
                  strokeWidth="6"
                />
              </g>
              <ellipse
                cx="69.1095"
                cy="12.9054"
                rx="10.1095"
                ry="10.308"
                fill="white"
              />
              <circle cx="69" cy="13" r="8" fill="url(#paint1_linear)" />
              <path
                d="M68.0013 9.17904C68.3578 8.40129 69.4629 8.40129 69.8194 9.17904L72.5134 15.0564C72.817 15.7188 72.333 16.4731 71.6043 16.4731H66.2163C65.4876 16.4731 65.0036 15.7188 65.3072 15.0564L68.0013 9.17904Z"
                fill="url(#paint2_linear)"
              />
            </g>
            <path
              d="M66.3303 78.9832L90.5956 16.2707L42.0649 16.2707L66.3303 78.9832Z"
              fill="url(#paint3_linear)"
            />
            <g clip-path="url(#clip0)">
              <path
                d="M56.0096 93.005L56.7459 92.9533C57.6243 87.4632 58.0635 82.1928 57.4822 77.4261C57.4822 77.4261 55.7771 69.12 55.3249 69.12C54.8728 69.1071 55.1441 88.0704 56.0096 93.005Z"
                fill="#313234"
              />
              <path
                d="M57.9602 99.9547L56.4488 101.195C55.8804 101.66 55.6221 102.409 55.7642 103.132L56.7588 107.977"
                stroke="#313234"
                strokeWidth="0.129178"
                strokeMiterlimit="10"
              />
              <path
                d="M83.279 73.9383C83.2661 73.8866 83.2532 73.8221 83.2403 73.7704C82.8269 72.2719 80.6438 71.2902 79.675 70.9156L79.6233 60.2326C79.6233 60.2326 79.5845 54.5358 78.3832 52.8694C77.1947 51.203 73.1386 49.4075 73.1386 49.4075L66.3567 49.1749L59.5749 49.4204C59.5749 49.4204 55.5187 51.216 54.3303 52.8823C53.1418 54.5487 53.0902 60.2455 53.0902 60.2455L53.0385 70.9285C52.0697 71.3031 49.8995 72.2848 49.4732 73.7833C49.4603 73.835 49.4474 73.8996 49.4344 73.9512C49.3957 74.145 49.5507 74.3129 49.7445 74.3C50.4291 74.2613 51.8759 73.7187 52.483 73.4862C52.651 73.4216 52.7543 73.2537 52.7414 73.0857L52.7285 72.8145L52.6897 72.0136L53.0256 71.8198L52.9998 78.55L53.0902 90.796L52.8835 102.009L55.1829 107.176L55.7771 107.512L56.1646 108.493C56.1646 108.493 61.4997 110.366 66.2276 110.366H66.4859C71.2267 110.366 76.5489 108.493 76.5489 108.493L76.9364 107.512L77.5306 107.176L79.83 102.009L79.6104 90.7831L79.7008 78.537L79.675 71.8069L80.0108 72.0007L79.9721 72.8016L79.9592 73.0728C79.9462 73.2537 80.0625 73.4087 80.2175 73.4733C80.8246 73.7058 82.2585 74.2483 82.9561 74.2871C83.1628 74.3129 83.3178 74.1321 83.279 73.9383Z"
                fill="#FAFAF8"
              />
              <path
                d="M66.2272 111.271C61.3959 111.271 56.0867 109.423 55.8542 109.346L55.4667 109.204L55.0533 108.132L54.472 107.796L51.966 102.177L52.1856 90.7702L52.0952 78.537L52.1081 74.5842C51.2813 74.8813 50.3771 75.1655 49.7958 75.1913C49.77 75.1913 49.7441 75.1913 49.7312 75.1913C49.3695 75.1913 49.0336 75.0363 48.8011 74.7521C48.5686 74.4809 48.4782 74.1192 48.5428 73.7704C48.5557 73.6929 48.5815 73.6024 48.6074 73.512C49.0466 71.949 50.8163 70.9026 52.1468 70.3213L52.1985 60.2326C52.2114 58.7987 52.3148 54.1612 53.6065 52.3527C54.8983 50.5442 58.7736 48.7874 59.2128 48.5936L59.3679 48.529L66.3564 48.2707L73.3449 48.529L73.4999 48.5936C73.9391 48.7874 77.8144 50.5313 79.1191 52.3527C80.4109 54.1612 80.5143 58.7987 80.5272 60.2326L80.5788 70.3213C81.8965 70.9026 83.6662 71.949 84.1054 73.5379C84.1312 73.6283 84.1442 73.7058 84.17 73.7833C84.2346 74.1321 84.1442 74.4938 83.9116 74.778C83.6791 75.0492 83.3433 75.2172 82.9816 75.2172C82.9557 75.2172 82.9299 75.2172 82.917 75.2172C82.3357 75.1784 81.4314 74.9071 80.6047 74.61L80.6176 78.55L80.5272 90.796L80.7468 102.202L78.2407 107.809L77.6594 108.145L77.2461 109.217L76.8585 109.359C76.6389 109.436 71.3297 111.284 66.4856 111.284H66.2272V111.271Z"
                fill="#1E1E20"
              />
              <path
                d="M66.486 110.366C71.2268 110.366 76.5489 108.493 76.5489 108.493L76.9364 107.512L77.5307 107.176L79.83 102.009L79.6104 90.7831L79.7009 78.537L79.6104 60.2326C79.6104 60.2326 79.5717 54.5358 78.3703 52.8694C77.1819 51.203 73.1257 49.4075 73.1257 49.4075L66.3568 49.1749L59.575 49.4204C59.575 49.4204 55.5188 51.216 54.3303 52.8823C53.1419 54.5487 53.0902 60.2455 53.0902 60.2455L53.3357 78.0462L53.6199 87.4374L53.1031 88.4579L53.0902 90.796L52.8835 102.009L55.1829 107.176L55.7771 107.512L56.1647 108.493C56.1647 108.493 61.4997 110.366 66.2276 110.366"
                fill="url(#paint4_linear)"
              />
              <path
                d="M66.3567 107.847C62.7138 107.835 60.6599 107.576 59.7815 107.434C59.5748 107.395 59.4198 107.615 59.5102 107.809L59.6653 108.119C59.8849 108.558 60.2982 108.868 60.7891 108.945C61.7709 109.101 63.6568 109.333 66.3696 109.359C69.0823 109.346 70.9683 109.113 71.9501 108.945C72.428 108.868 72.8543 108.558 73.0739 108.119L73.2289 107.809C73.3193 107.615 73.1643 107.408 72.9576 107.434C72.0663 107.576 70.0124 107.835 66.3567 107.847Z"
                stroke="url(#paint5_linear)"
                strokeWidth="0.129178"
                strokeMiterlimit="10"
              />
              <path
                opacity="0.56"
                d="M57.2367 64.5083C57.2108 58.9795 58.0376 55.9568 59.6006 50.2859C59.6523 50.1179 59.4456 49.9758 59.3035 50.0921L56.1257 52.6627L55.1052 65.6063L55.3248 67.8282C55.3119 67.8282 56.5908 67.3244 57.2367 64.5083Z"
                fill="url(#paint6_linear)"
              />
              <path
                opacity="0.56"
                d="M75.4894 64.5083C75.5153 58.7987 74.6369 55.5951 72.9705 49.7304L76.6004 52.6627L77.6209 65.6063L77.4013 67.8282C77.4142 67.8282 76.1353 67.3244 75.4894 64.5083Z"
                fill="url(#paint7_linear)"
              />
              <path
                opacity="0.56"
                d="M59.9883 108.868C59.9883 108.868 55.6092 102.719 54.692 87.8249C52.4831 91.2998 54.4724 104.838 54.4724 104.838C54.4724 104.838 55.6608 108.132 57.2626 108.416C58.8515 108.7 59.9883 108.868 59.9883 108.868Z"
                fill="url(#paint8_linear)"
              />
              <path
                opacity="0.56"
                d="M72.738 108.868C72.738 108.868 77.1172 102.719 78.0343 87.8249C80.2562 91.2998 78.2668 104.838 78.2668 104.838C78.2668 104.838 77.0784 108.132 75.4766 108.416C73.8748 108.7 72.738 108.868 72.738 108.868Z"
                fill="url(#paint9_linear)"
              />
              <path
                d="M66.3566 102.215C59.7944 102.177 59.0193 100.846 59.0193 100.846L58.3993 100.045C57.9472 99.4638 57.7405 98.7404 57.7792 98.0041L58.2701 90.7702V81.9602C58.2701 79.4542 57.9601 76.974 57.3529 74.5454L55.7253 68.0866C55.6478 67.7894 55.6995 67.4794 55.8803 67.2211L56.9267 65.658C57.0429 65.4901 57.1979 65.3609 57.3788 65.2705C58.3864 64.8054 61.9646 63.3328 66.3696 63.307C70.7616 63.3457 74.3527 64.8184 75.3603 65.2705C75.5412 65.348 75.6962 65.4901 75.8124 65.658L76.8588 67.2211C77.0267 67.4794 77.0784 67.7894 77.0138 68.0866L75.3862 74.5454C74.779 76.974 74.469 79.4542 74.469 81.9602V90.7702L74.9599 98.0041C75.0115 98.7404 74.7919 99.4638 74.3398 100.045L73.7198 100.846C73.6939 100.859 72.9318 102.177 66.3566 102.215Z"
                fill="#313234"
              />
              <path
                d="M66.3567 100.924C63.1143 100.911 61.4479 100.562 60.647 100.291C60.2724 100.161 59.9495 99.9289 59.704 99.6189L59.4327 99.2572C59.1744 98.9213 59.0581 98.5079 59.084 98.0946L59.5748 90.8864C59.5748 90.8477 59.5748 90.796 59.5748 90.7573V81.9732C59.5748 79.3638 59.2519 76.7673 58.6189 74.2483L57.2238 68.7324C57.0817 68.1899 57.1851 67.6086 57.4951 67.1436L57.6372 66.924C57.8568 66.5881 58.1797 66.3297 58.5414 66.1876C59.9495 65.6193 62.8818 64.6375 66.3567 64.5988C69.8316 64.6246 72.7639 65.6193 74.1719 66.1876C74.5465 66.3427 74.8566 66.601 75.0762 66.924L75.2183 67.1436C75.5283 67.6086 75.6316 68.1899 75.4895 68.7324L74.1073 74.2483C73.4744 76.7673 73.1514 79.3767 73.1514 81.9732V90.7573C73.1514 90.796 73.1514 90.8477 73.1514 90.8864L73.6423 98.0946C73.6681 98.5079 73.5519 98.9213 73.2935 99.2572L73.0222 99.6189C72.7768 99.9289 72.4539 100.174 72.0792 100.291C71.2783 100.562 69.599 100.911 66.3567 100.924Z"
                fill="url(#paint10_linear)"
                stroke="#313234"
                strokeWidth="0.129178"
                strokeMiterlimit="10"
              />
              <path
                d="M53.1032 59.2637C53.1032 59.2637 53.5553 56.7577 55.8676 54.7942C54.7567 57.5198 54.227 66.446 54.1883 68.022C54.072 73.3699 55.1829 107.163 55.1829 107.163L53.3357 103.016C53.0386 102.345 52.8836 101.621 52.8965 100.898L53.0903 89.2846C53.1032 88.7808 53.1807 88.2641 53.3486 87.7862L53.452 87.4891L53.1032 59.2637Z"
                fill="url(#paint11_linear)"
              />
              <path
                d="M79.6103 59.2637C79.6103 59.2637 78.4218 62.674 78.5252 68.022C78.6285 73.3699 77.5305 107.163 77.5305 107.163L79.8299 101.996L79.6103 59.2637Z"
                fill="url(#paint12_linear)"
              />
              <path
                d="M59.3681 50.0146C59.3681 50.0146 60.5049 49.9629 60.014 50.3117C58.5414 51.3581 55.3636 55.4142 54.0589 57.7136C53.9556 56.8869 54.1623 54.4325 54.5498 53.5153C55.2086 51.9135 59.3681 50.0146 59.3681 50.0146Z"
                fill="#696C70"
              />
              <path
                d="M73.3583 50.1438C73.3583 50.1438 72.2215 50.0921 72.7124 50.4409C74.185 51.4872 77.3628 55.5434 78.6675 57.8428C78.7708 57.016 78.5641 54.5617 78.1766 53.6445C77.5178 52.0427 73.3583 50.1438 73.3583 50.1438Z"
                fill="#696C70"
              />
              <path
                d="M56.4876 92.9145C56.6814 92.8628 56.8235 92.7078 56.8493 92.5141C57.0302 91.1448 57.7148 85.6289 57.7148 80.2034C57.7148 75.876 54.9246 68.7066 54.9246 68.7066C54.9246 68.7066 55.2088 78.4337 55.2088 82.9291C55.2088 86.5719 55.6996 90.9769 55.8805 92.5011C55.9192 92.7983 56.2034 92.992 56.4876 92.9145Z"
                fill="#313234"
              />
              <path
                d="M76.226 92.9145C76.0322 92.8628 75.8901 92.7078 75.8643 92.5141C75.6834 91.1448 74.9988 85.6289 74.9988 80.2034C74.9988 75.876 77.789 68.7066 77.789 68.7066C77.789 68.7066 77.5048 78.4337 77.5048 82.9291C77.5048 86.5719 77.014 90.9769 76.8331 92.5011C76.8073 92.7983 76.5231 92.992 76.226 92.9145Z"
                fill="#313234"
              />
              <path
                opacity="0.47"
                d="M56.3712 91.106C56.1516 89.0392 55.8545 85.771 55.8545 82.942C55.8545 80.4101 55.764 76.1731 55.6865 72.9824C56.3712 75.2818 57.0687 78.1107 57.0687 80.2034C57.0687 84.4792 56.6424 88.7808 56.3712 91.106Z"
                fill="url(#paint13_linear)"
                stroke="#313234"
                strokeWidth="0.129178"
                strokeMiterlimit="10"
              />
              <path
                opacity="0.47"
                d="M76.355 91.106C76.5746 89.0392 76.8717 85.771 76.8717 82.942C76.8717 80.4101 76.9622 76.1731 77.0397 72.9824C76.355 75.2818 75.6575 78.1107 75.6575 80.2034C75.6575 84.4792 76.0838 88.7808 76.355 91.106Z"
                fill="url(#paint14_linear)"
                stroke="#313234"
                strokeWidth="0.129178"
                strokeMiterlimit="10"
              />
              <path
                d="M49.4344 73.9383C49.3957 74.1321 49.5507 74.3 49.7445 74.2871C50.4291 74.2483 51.8759 73.7058 52.483 73.4733C52.651 73.4087 52.7543 73.2407 52.7414 73.0728L52.7285 72.8015L49.4603 73.7574C49.4603 73.835 49.4474 73.8866 49.4344 73.9383Z"
                fill="#313234"
              />
              <path
                d="M53.2851 71.6648L53.5177 70.7605C53.5177 70.7605 50.0299 71.8456 49.4873 73.7704L52.7555 72.8145L52.7167 72.0136L53.2851 71.6648Z"
                fill="#FAFAF8"
              />
              <path
                d="M83.279 73.9383C83.3177 74.1321 83.1627 74.3 82.969 74.2871C82.2843 74.2483 80.8375 73.7058 80.2304 73.4733C80.0625 73.4087 79.9591 73.2407 79.972 73.0728L79.985 72.8015L83.2531 73.7574C83.2531 73.835 83.279 73.8866 83.279 73.9383Z"
                fill="#313234"
              />
              <path
                d="M79.4425 71.6648L79.21 70.7605C79.21 70.7605 82.6978 71.8456 83.2403 73.7704L79.9721 72.8145L80.0109 72.0136L79.4425 71.6648Z"
                fill="#FAFAF8"
              />
            </g>
            <defs>
              <filter
                id="filter0_d"
                x="4.53027"
                y="1.8681"
                width="126"
                height="134"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="1" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter1_d"
                x="53.5303"
                y="0.270752"
                width="28.2189"
                height="28.616"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <filter
                id="filter2_d"
                x="0.530273"
                y="3.58981"
                width="134"
                height="136.278"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.279167 0 0 0 0 0.279167 0 0 0 0 0.279167 0 0 0 0.74 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
              </filter>
              <linearGradient
                id="paint0_linear"
                x1="67.5303"
                y1="12.5898"
                x2="67.5303"
                y2="130.868"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#CBD5E0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="67.5303"
                y1="6.27075"
                x2="67.5303"
                y2="31.7708"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#D5DDE5" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="67.5303"
                y1="5.77074"
                x2="67.5303"
                y2="24.2707"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.144725" stopColor="#3A4755" />
                <stop offset="0.455831" stopColor="#8291A1" />
                <stop offset="0.754641" stopColor="#BDC6D0" />
                <stop offset="1" stopColor="#E8EDF1" />
              </linearGradient>
              <linearGradient
                id="paint3_linear"
                x1="66.3323"
                y1="16.2707"
                x2="66.3323"
                y2="78.9942"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFE874" stopOpacity="0" />
                <stop offset="0.644624" stopColor="#FFD500" />
              </linearGradient>
              <linearGradient
                id="paint4_linear"
                x1="52.8798"
                y1="79.7712"
                x2="79.8435"
                y2="79.7712"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.1452" stopColor="white" />
                <stop offset="0.6215" stopColor="#C5C3C4" />
                <stop offset="1" stopColor="white" />
              </linearGradient>
              <linearGradient
                id="paint5_linear"
                x1="59.4207"
                y1="108.386"
                x2="73.3023"
                y2="108.386"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9C9A9B" />
                <stop offset="1" stopColor="#ADABAC" />
              </linearGradient>
              <linearGradient
                id="paint6_linear"
                x1="55.258"
                y1="58.6992"
                x2="59.7526"
                y2="59.2072"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.1452" stopColor="white" />
                <stop offset="1" stopColor="#908E8F" />
              </linearGradient>
              <linearGradient
                id="paint7_linear"
                x1="77.4499"
                y1="58.5215"
                x2="72.8289"
                y2="59.0438"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.1452" stopColor="white" />
                <stop offset="1" stopColor="#908E8F" />
              </linearGradient>
              <linearGradient
                id="paint8_linear"
                x1="60.0231"
                y1="97.4131"
                x2="51.5213"
                y2="100.387"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9C9A9B" />
                <stop offset="0.8548" stopColor="white" />
              </linearGradient>
              <linearGradient
                id="paint9_linear"
                x1="72.7"
                y1="97.4131"
                x2="81.2018"
                y2="100.387"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#9C9A9B" />
                <stop offset="0.8548" stopColor="white" />
              </linearGradient>
              <linearGradient
                id="paint10_linear"
                x1="66.3615"
                y1="100.926"
                x2="66.3615"
                y2="64.601"
                gradientUnits="userSpaceOnUse"
              >
                <stop />
                <stop offset="0.6273" stopColor="#ADABAC" />
                <stop offset="1" stopColor="#403F40" />
              </linearGradient>
              <linearGradient
                id="paint11_linear"
                x1="56.0351"
                y1="80.9833"
                x2="51.8172"
                y2="80.9833"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.1494" stopColor="#A9A7A8" />
                <stop offset="0.8548" stopColor="white" />
              </linearGradient>
              <linearGradient
                id="paint12_linear"
                x1="77.4085"
                y1="83.2184"
                x2="80.682"
                y2="83.2184"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.1494" stopColor="#A9A7A8" />
                <stop offset="0.8548" stopColor="white" />
              </linearGradient>
              <linearGradient
                id="paint13_linear"
                x1="56.3782"
                y1="91.1011"
                x2="56.3782"
                y2="72.9773"
                gradientUnits="userSpaceOnUse"
              >
                <stop />
                <stop offset="0.6273" stopColor="#ADABAC" />
                <stop offset="1" stopColor="#403F40" />
              </linearGradient>
              <linearGradient
                id="paint14_linear"
                x1="76.3448"
                y1="91.1011"
                x2="76.3448"
                y2="72.9773"
                gradientUnits="userSpaceOnUse"
              >
                <stop />
                <stop offset="0.6273" stopColor="#ADABAC" />
                <stop offset="1" stopColor="#403F40" />
              </linearGradient>
              <clipPath id="clip0">
                <rect
                  width="35.666"
                  height="63"
                  fill="white"
                  transform="translate(48.5303 48.2707)"
                />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            viewBox="0 0 138 138"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...svgProps}
          >
            <path
              d="M69.2653 77.7125L93.5307 15H45L69.2653 77.7125Z"
              fill="url(#paint0_linear)"
            />
            <g>
              <circle
                cx="69"
                cy="69"
                r="58"
                stroke="url(#paint1_linear)"
                strokeWidth="6"
              />
              <g filter="url(#filter1_df)">
                <circle
                  cx="69"
                  cy="69"
                  r="58"
                  stroke="url(#paint2_linear)"
                  strokeWidth="3"
                />
              </g>
              <ellipse
                cx="69.1095"
                cy="12.9054"
                rx="10.1095"
                ry="10.308"
                fill="white"
              />
              <circle cx="69" cy="13" r="8" fill="url(#paint3_linear)" />
              <path
                d="M68.0013 9.17904C68.3578 8.40129 69.4629 8.40129 69.8194 9.17904L72.5134 15.0564C72.817 15.7188 72.333 16.4731 71.6043 16.4731H66.2163C65.4876 16.4731 65.0036 15.7188 65.3072 15.0564L68.0013 9.17904Z"
                fill="url(#paint4_linear)"
              />
            </g>
            <path
              d="M69.3634 47.7634C66.4691 47.7971 63.4804 48.1685 60.3973 48.8774C59.831 49.0124 59.3277 49.4175 59.0131 49.9914L57.2199 53.3672C56.2446 55.2238 55.7098 57.3506 55.7098 59.5111V101.236C55.7098 103.43 57.0626 105.287 58.9501 105.726C62.5051 106.569 65.9657 107.042 69.3634 107.076C72.761 107.042 76.2216 106.569 79.7766 105.726C81.6642 105.287 83.0169 103.396 83.0169 101.236V59.5111C83.0169 57.3506 82.5136 55.2238 81.5069 53.3672L79.7137 49.9914C79.3991 49.4175 78.8957 49.0124 78.3294 48.8774C75.2464 48.1685 72.2577 47.7971 69.3634 47.7634Z"
              fill={props.color || '#fff'}
            />
            <mask
              id="mask0"
              mask-type="alpha"
              maskUnits="userSpaceOnUse"
              x="54"
              y="46"
              width="30"
              height="62"
            >
              <rect
                x="54.8259"
                y="46.8983"
                width="28.9431"
                height="61.1017"
                fill="url(#pattern0)"
              />
            </mask>
            <g mask="url(#mask0)">
              <g opacity="0.3">
                <path
                  opacity="0.3"
                  d="M69.3634 47.7634C66.4691 47.7971 63.4804 48.1685 60.3973 48.8774C59.831 49.0124 59.3277 49.4175 59.0131 49.9914L57.2199 53.3672C56.2446 55.2238 55.7098 57.3506 55.7098 59.5111V101.236C55.7098 103.43 57.0626 105.287 58.9501 105.726C62.5051 106.569 65.9657 107.042 69.3634 107.076C72.761 107.042 76.2216 106.569 79.7766 105.726C81.6642 105.287 83.0169 103.396 83.0169 101.236V59.5111C83.0169 57.3506 82.5136 55.2238 81.5069 53.3672L79.7137 49.9914C79.3991 49.4175 78.8957 49.0124 78.3294 48.8774C75.2464 48.1685 72.2577 47.7971 69.3634 47.7634Z"
                  fill="#494C66"
                />
              </g>
            </g>
            <path
              d="M58.1322 64.4726C59.2962 57.9919 61.6872 52.2347 64.8961 48.2708L59.485 48.9629L55.8985 56.4503L55.7727 72.1174L58.1322 64.4726Z"
              fill={props.color || '#fff'}
            />
            <path
              d="M80.5946 64.4726C79.4305 57.9919 77.0396 52.2347 73.8307 48.2708L79.2418 48.9629L82.8282 56.4503L82.9541 72.1174L80.5946 64.4726Z"
              fill={props.color || '#fff'}
            />
            <path
              d="M56.0559 70.8275L51.3054 72.9038C50.7391 73.1555 50.2987 72.9038 50.2987 72.3376C50.2987 70.7017 51.62 68.7826 53.2559 68.059L56.0559 66.8321V70.8275Z"
              fill="black"
            />
            <path
              opacity="0.64"
              d="M51.3054 71.8971C51.0852 71.9915 50.9279 71.8971 50.9279 71.6769C50.9279 70.3871 51.9661 68.877 53.2559 68.3107L55.4267 67.3669V70.1039L51.3054 71.8971Z"
              fill="url(#paint7_linear)"
            />
            <path
              d="M82.6709 70.8275L87.4213 72.9038C87.9876 73.1555 88.4281 72.9038 88.4281 72.3376C88.4281 70.7017 87.1067 68.7826 85.4708 68.059L82.6709 66.8321V70.8275V70.8275Z"
              fill="black"
            />
            <path
              opacity="0.64"
              d="M87.4214 71.8971C87.6416 71.9915 87.7989 71.8971 87.7989 71.6769C87.7989 70.3871 86.7607 68.877 85.4708 68.3107L83.3001 67.3669V70.1039L87.4214 71.8971Z"
              fill="url(#paint8_linear)"
            />
            <path
              d="M69.3634 70.8791C66.5006 70.8476 63.6062 71.3195 60.8692 72.2633C59.7367 72.6409 58.5412 71.8544 58.4154 70.6903C58.1637 68.4567 57.9435 66.223 57.6918 64.0208C57.5974 63.2029 58.0378 62.4479 58.7929 62.1018C62.1591 60.6232 65.777 59.8681 69.3634 59.8996C72.9813 59.8681 76.5677 60.5917 79.9339 62.1018C80.689 62.4164 81.1294 63.2029 81.035 64.0208C80.7833 66.2545 80.5631 68.4567 80.3114 70.6903C80.1856 71.8858 78.9901 72.6723 77.8576 72.2633C75.1206 71.3195 72.2263 70.8476 69.3634 70.8791Z"
              fill="url(#paint9_radial)"
            />
            <path
              d="M55.4061 58.2937L59.0828 76.3679L59.6759 85.6441L55.2875 97.7891L55.4061 58.2937Z"
              fill={props.color || '#fff'}
            />
            <path
              d="M83.2545 58.2937L79.5778 76.3679L78.9847 85.6441L83.3731 97.7891L83.2545 58.2937Z"
              fill={props.color || '#fff'}
            />
            <path
              d="M64.5528 102L63.3915 104H75.3915L74.6173 102H64.5528Z"
              fill="url(#paint12_linear)"
            />
            <path
              d="M56.5906 56.293C59.1389 53.4616 62.159 50.819 65.5252 48.3966C65.5252 48.3966 61.9703 48.0505 59.9254 49.5291C58.0378 50.8819 56.5906 56.293 56.5906 56.293Z"
              fill="white"
            />
            <path
              d="M82.1361 56.293C79.5878 53.4616 76.5677 50.819 73.2015 48.3966C73.2015 48.3966 76.7564 48.0505 78.8013 49.5291C80.6889 50.8819 82.1361 56.293 82.1361 56.293Z"
              fill="white"
            />
            <path
              opacity="0.54"
              d="M64.4871 47.9247C63.8579 49.6865 62.3479 50.0325 60.649 51.6055C58.5097 53.619 56.1503 56.702 55.8042 57.1425"
              stroke="#55565E"
              strokeWidth="0.943796"
              strokeMiterlimit="10"
            />
            <path
              opacity="0.54"
              d="M74.2397 47.9247C74.8689 49.6865 76.379 50.0325 78.0778 51.6055C80.2171 53.619 82.5766 56.702 82.9226 57.1425"
              stroke="#55565E"
              strokeWidth="0.943796"
              strokeMiterlimit="10"
            />
            <path
              d="M69.3915 48C66.4237 48.0336 63.3592 48.403 60.1979 49.1081C59.6173 49.2425 59.1012 49.6454 58.7786 50.2163L56.9399 53.5743C55.9399 55.4212 55.3915 57.5367 55.3915 59.6858V101.191C55.3915 103.373 56.7786 105.22 58.7141 105.657C62.3592 106.496 65.9076 106.966 69.3915 107C72.8754 106.966 76.4237 106.496 80.0689 105.657C82.0044 105.22 83.3915 103.34 83.3915 101.191V59.6858C83.3915 57.5367 82.8754 55.4212 81.8431 53.5743L80.0044 50.2163C79.6818 49.6454 79.1657 49.2425 78.585 49.1081C75.4237 48.403 72.3592 48.0336 69.3915 48Z"
              stroke="black"
              strokeWidth="0.943796"
              strokeMiterlimit="10"
            />
            <path
              d="M83.1717 95.0089C83.1717 95.0089 83.73 98.0819 83.067 99.4648C82.4041 100.848 78.3915 102 78.3915 102C78.3915 102 78.5659 100.771 78.8102 99.8105C78.88 99.5416 79.194 99.3111 79.5778 99.1191C80.799 98.5429 81.776 97.5057 82.1947 96.1229C82.4041 95.3546 82.7181 94.9321 83.1717 95.0089Z"
              fill="black"
            />
            <path
              d="M55.6113 95.0089C55.6113 95.0089 55.053 98.0819 55.7159 99.4648C56.3789 100.848 60.3915 102 60.3915 102C60.3915 102 60.217 100.771 59.9728 99.8105C59.903 99.5416 59.589 99.3111 59.2051 99.1191C57.9839 98.5429 57.007 97.5057 56.5882 96.1229C56.3789 95.3546 56.0649 94.9321 55.6113 95.0089Z"
              fill="black"
            />
            <path
              opacity="0.4"
              d="M69.3634 98.4071C65.9028 98.4071 62.4107 97.8991 58.9816 96.8368C58.2266 96.6059 57.7547 95.4974 57.9434 94.4351C58.1322 93.1419 58.3524 91.8487 58.5412 90.5555C58.6985 89.4932 59.3906 88.8466 60.1141 89.0314C63.1658 89.8627 66.2803 90.2784 69.3634 90.2784C72.4464 90.2784 75.5609 89.8627 78.6126 89.0314C79.3361 88.8466 80.0283 89.4932 80.1856 90.5555C80.3743 91.8487 80.5945 93.1419 80.7833 94.4351C80.9406 95.5436 80.4687 96.6059 79.7451 96.8368C76.316 97.9453 72.8554 98.4533 69.3634 98.4071Z"
              fill="url(#paint13_radial)"
            />
            <defs>
              <filter
                id="filter1_df"
                x="3.5"
                y="3.5"
                width="131"
                height="131"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="3" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.279167 0 0 0 0 0.279167 0 0 0 0 0.279167 0 0 0 0.39 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow"
                  result="shape"
                />
                <feGaussianBlur
                  stdDeviation="1"
                  result="effect2_foregroundBlur"
                />
              </filter>
              <linearGradient
                id="paint0_linear"
                x1="69.2674"
                y1="15"
                x2="69.2674"
                y2="77.7236"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FFE874" stopOpacity="0" />
                <stop offset="0.644624" stopColor="#FFD500" />
              </linearGradient>
              <linearGradient
                id="paint1_linear"
                x1="69"
                y1="11"
                x2="69"
                y2="127"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#CBD5E0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear"
                x1="69"
                y1="11"
                x2="69"
                y2="127"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#ECF2F9" />
              </linearGradient>
              <linearGradient
                id="paint3_linear"
                x1="69"
                y1="5"
                x2="69"
                y2="30.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#D5DDE5" />
              </linearGradient>
              <linearGradient
                id="paint4_linear"
                x1="69"
                y1="4.49998"
                x2="69"
                y2="23"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0.269617" stopColor="#010101" />
                <stop offset="0.717542" stopColor="#8291A1" />
                <stop offset="1" stopColor="#BDC6D0" />
              </linearGradient>
              <linearGradient
                id="paint5_linear"
                x1="55.0723"
                y1="58.4439"
                x2="63.2726"
                y2="61.154"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#CBD5DF" />
              </linearGradient>
              <linearGradient
                id="paint6_linear"
                x1="83.6583"
                y1="58.4439"
                x2="75.458"
                y2="61.154"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#CBD5DF" />
              </linearGradient>
              <linearGradient
                id="paint7_linear"
                x1="55.3233"
                y1="67.3184"
                x2="52.3326"
                y2="72.6351"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#CBD5DF" />
                <stop offset="1" />
              </linearGradient>
              <linearGradient
                id="paint8_linear"
                x1="83.4074"
                y1="67.3184"
                x2="86.398"
                y2="72.6351"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#CBD5DF" />
                <stop offset="1" />
              </linearGradient>
              <radialGradient
                id="paint9_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(69.4699 62.1969) rotate(0.23657) scale(16.2487 10.872)"
              >
                <stop stopColor="#666666" />
                <stop offset="0.8078" />
              </radialGradient>
              <linearGradient
                id="paint10_linear"
                x1="55.2722"
                y1="78.046"
                x2="59.6804"
                y2="78.046"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#CBD5DF" />
              </linearGradient>
              <linearGradient
                id="paint11_linear"
                x1="83.3957"
                y1="78.046"
                x2="78.9875"
                y2="78.046"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="white" />
                <stop offset="1" stopColor="#CBD5DF" />
              </linearGradient>
              <linearGradient
                id="paint12_linear"
                x1="69.3915"
                y1="102"
                x2="69.3915"
                y2="104.5"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#CDD7E0" />
                <stop offset="1" stopColor="#CDD7E0" stopOpacity="0" />
              </linearGradient>
              <radialGradient
                id="paint13_radial"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(69.3653 89.9052) scale(10.7927 8.35172)"
              >
                <stop stopColor="#666666" />
                <stop offset="0.8078" />
              </radialGradient>
            </defs>
          </svg>
        )}
      </Knob>
    </SkinWrap>
  );
}
