import type {
  StepComponentControlsErrorProps,
  StepComponentFC,
  StepComponentSharedProps,
  StepComponentShowsPrefillProps,
  WitnessEntryStepComponentSpec,
} from '@assured/step-renderer';
import { WitnessEntryButtonValue } from '@assured/step-renderer/types/step-components/WitnessEntry';
import { WitnessEntryList } from './WitnessEntryList';

type WitnessEntryProps = StepComponentSharedProps<
  WitnessEntryStepComponentSpec,
  WitnessEntryButtonValue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> &
  StepComponentControlsErrorProps &
  StepComponentShowsPrefillProps;

export const WitnessEntry: StepComponentFC<WitnessEntryProps> = ({
  step_component,
  updateValue,
}) => {
  return (
    <WitnessEntryList
      updateValue={updateValue}
      step_component={step_component}
    />
  );
};
