import { DisplayMode, HandOptionKey, RegionTemplate } from './types';

export const hand: { hand: RegionTemplate<HandOptionKey> } = {
  hand: {
    tabs: [
      {
        key: 'popular',
        label: 'Most common',
      },
      {
        key: 'skin',
        label: 'Skin',
      },
      {
        key: 'wrist',
        label: 'Wrist',
      },
      {
        key: 'palm',
        label: 'Palm',
      },
      {
        key: 'finger',
        label: 'Fingers',
      },
      {
        key: 'bleeding',
        label: 'Bleeding',
      },
    ],
    options: [
      {
        key: 'cut_scrape',
        label: 'Cut / scrape',
        tabs: ['skin'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'bruise',
        label: 'Bruise',
        tabs: ['skin'],
        followups: [
          {
            key: 'major',
            label: 'Was this a major bruise?',
          },
        ],
      },
      {
        key: 'puncture',
        label: 'Puncture',
        tabs: ['skin'],
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'stitches',
            label: 'Were stitches required?',
          },
        ],
      },
      {
        key: 'airbag_burns',
        label: 'Airbag burns',
        hidden_in_modes: [DisplayMode.WORKERS_COMP, DisplayMode.PROPERTY],
        tabs: ['skin'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'light_bleeding',
        label: 'Light bleeding',
        tabs: ['bleeding'],
      },
      {
        key: 'uncontrolled_bleeding',
        label: 'Uncontrolled bleeding',
        tabs: ['bleeding'],
        hide_if_low_severity: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.PROPERTY,
        ],
        followups: [
          {
            key: 'transfusion',
            label: 'Was a blood transfusion performed?',
          },
        ],
      },
      {
        key: 'broken_finger',
        label: 'Broken finger',
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
        ],
        tabs: ['finger'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'hyperextension_finger',
        label: 'Hyperextended finger',
        popular_in_modes: [
          DisplayMode.AUTO,
          DisplayMode.LIABILITY,
          DisplayMode.WORKERS_COMP,
        ],
        tabs: ['finger'],
      },
      {
        key: 'sprain_finger',
        label: 'Sprained / strained finger',
        tabs: ['finger'],
      },

      {
        key: 'severed_finger',
        label: 'Severed',
        tabs: ['finger'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'amputation_finger',
        label: 'Amputated',
        tabs: ['finger'],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'crushed_finger',
        label: 'Crushed',
        tabs: ['finger'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'broken_wrist',
        label: 'Broken wrist',
        popular_in_modes: [DisplayMode.PROPERTY],
        tabs: ['wrist'],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'carpal_tunnel',
        label: 'Carpal tunnel',
        tabs: ['wrist'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'bruised_bone',
        label: 'Bruised bone',
        tabs: ['palm'],
      },
      {
        key: 'broken_bone',
        label: 'Broken bone',
        tabs: ['palm'],
      },
      {
        key: 'severed_hand',
        label: 'Severed hand',
        tabs: ['palm'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'amputation_hand',
        label: 'Amputated hand',
        tabs: ['palm'],
      },
      {
        key: 'crushed_hand',
        label: 'Crushed hand',
        tabs: ['palm'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        popular_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'hyperextension_wrist',
        label: 'Hyperextended wrist',
        tabs: ['wrist'],
      },
      {
        key: 'sprain_wrist',
        label: 'Sprained / strained wrist',
        tabs: ['wrist'],
        popular_in_modes: [DisplayMode.AUTO, DisplayMode.LIABILITY],
      },
      {
        key: 'soreness',
        label: 'Muscle soreness / generalized pain',
        tabs: ['wrist'],
      },
      {
        key: 'burn_scald',
        label: 'Burn / scald',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
        followups: [
          {
            key: 'majorBurn',
            label: 'Major burn?',
          },
          {
            key: 'surgery',
            label: 'Is surgery required?',
          },
        ],
      },
      {
        key: 'dermatitis',
        label: 'Dermatitis',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
      {
        key: 'infection',
        label: 'Infection',
        tabs: ['skin'],
        display_in_modes: [DisplayMode.WORKERS_COMP],
      },
    ],
  },
};
