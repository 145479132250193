import { DateTime } from 'luxon';

type MobileDateTimeProps = {
  dateOnly?: boolean;
  value?: string;
  hashedField?: string;
  onChange: (date?: Date) => void;
  error?: string;
};

export const MobileDateTime = ({
  dateOnly,
  value,
  hashedField,
  onChange,
  error,
}: MobileDateTimeProps) => {
  const onChangeInputDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(new Date(e.target.value));
  };

  let mobileValue = value || DateTime.fromJSDate(new Date()).toISO();

  if (dateOnly) {
    mobileValue = mobileValue.slice(0, -1).slice(0, 10);
  }

  const innerClassName = `DateTime bg-white textbox p-2 px-4 border-solid border-2 rounded-lg focus:outline-none focus:shadow-outline w-full text-center${
    error ? ' Shake border-red-500' : ''
  }`;

  return (
    <input
      type={dateOnly ? 'date' : 'datetime-local'}
      value={mobileValue}
      onChange={onChangeInputDate}
      className={innerClassName}
      id={hashedField}
      aria-controls="data-picker"
    />
  );
};
