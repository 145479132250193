import 'cross-fetch/polyfill';

import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GUIDED_PHOTO_CAPTURE_APOLLO_TYPE_POLICIES } from '@assured/guided-photo-capture/src/constants';
import { captureException } from '@sentry/react';

import { config } from '../config';
import { getTokenSilently } from './auth';
import { noticeError } from '@assured/telemetry/src/common';

const httpLink = new HttpLink({ uri: config.endpoint, credentials: 'include' });

const authLink = setContext(async (request, { headers }) => {
  let updatedHeaders = { ...headers };

  if (config.multiTenantSelectedTenant) {
    updatedHeaders['x-assured-tenant'] = config.multiTenantSelectedTenant;
  }

  // Don't use token for specific unauthenticated operations. Prevents issues from
  // expired tokens, since even if your old token is expired, it shouldn't matter because
  // we are looking up/creating a whole new one.
  const NO_AUTH_OPERATIONS = [
    'GetTenantConfig',
    'LookupDemo',
    'ActivateDemo',
    'AuthenticateUser',
    'LookupAuthenticatedDeepLink',
    'StartNewLinkInvestigationResponse',
    'UpdateOAuthSession',

    // FIXME: This logic excludes tokens for Guided Photo Capture, since auth uses a separate
    // process. We should modify the backend app logic to check permissions in a more scoped
    // way. Ref: @assured/guided-photo-capture
    'SMSGuidedPhotoCaptureLink',
    'GuidedPhotosSession',
    'SubmitDamageMediaItems',
    'AddGuidedPhotoCaptureMediaItem',
    'UpdateGuidedPhotoCaptureMediaItem',
  ];
  if (
    // Also don't use the token from local storage if one has already explicitly been provided
    !!updatedHeaders.authorization ||
    (request.operationName &&
      NO_AUTH_OPERATIONS.indexOf(request.operationName) !== -1)
  ) {
    return { headers: updatedHeaders };
  }

  let token;
  try {
    token = await getTokenSilently();
  } catch (e: any) {
    // fall-through if not logged in
  }

  if (token) {
    updatedHeaders = {
      ...updatedHeaders,
      authorization: `Bearer ${token}`,
    };
  }

  return { headers: updatedHeaders };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      // eslint-disable-next-line no-console
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations,
        )}, Path: ${path}`,
      );
      const error = new Error(message);
      error.name = 'ApolloGraphQLError';
      noticeError(error);
      captureException(error);
    });
  if (networkError) {
    noticeError(networkError);
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

export const apolloClient = new ApolloClient({
  link: errorLink.concat(authLink).concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      ...GUIDED_PHOTO_CAPTURE_APOLLO_TYPE_POLICIES,
    },
  }),
});
