import classNames from 'classnames';
import React from 'react';

import { CoverageHudData } from '@assured/shared-types/Notifications/CoverageHud';
import { WorkflowStepNotification } from '@assured/step-renderer/types';
import { Coordinate } from '@assured/step-renderer/types/step-components/additional';

import CoverageIcon from '../elements/CoverageIcon';
import Modal from '../Modal';
import LocationMap from './Location/LocationMap';

const DUMMY_TOW_LOCATION: Coordinate = {
  latitude: 37.44220803300987,
  longitude: -122.1612180732146,
};

const NOTIFICATIONS: Record<string, any> = {
  'tow-requested': {
    icon: 'check',
    title: 'Tow truck requested',
    body: (data: {
      destination: Coordinate;
      requires_claim_submission?: boolean;
    }) => (
      <div>
        <div
          className="rounded overflow-hidden m-4"
          style={{
            height: 150,
            position: 'relative',
          }}
        >
          <LocationMap
            center={data.destination || DUMMY_TOW_LOCATION}
            zoom={18}
            loaded={true}
            interactive={false}
            // mapTypeId={'hybrid'}
          />
        </div>
        {data.requires_claim_submission ? (
          <>
            Your tow truck has been requested. You will receive a phone call or
            text message confirmation when it's on the way.
          </>
        ) : (
          <>
            Your tow truck has been requested. We'll let you know when it's on
            the way. In the meantime, let's continue filing your claim.
          </>
        )}
      </div>
    ),
  },
  'repair-coordination-requested': {
    icon: 'check',
    title: 'Thanks for making a selection',
    body: (data: { name: string }) => (
      <div>
        A representative from {data.name} will be in touch soon to confirm your
        appointment.
      </div>
    ),
  },
  'coverage-hud': {
    icon: 'check',
    title: (data: CoverageHudData) => `You may be covered`,
    inlineIconTitle: true,
    body: (data: CoverageHudData) => (
      <div>
        <div>
          Here's how you,{' '}
          <span className="text-blue-500 font-medium">{data.covered_name}</span>
          , may be covered for your {data.incident_type}.
        </div>
        <div className="mb-6">
          {data.coverages.map(section => (
            <div>
              <h3 className="mt-6 mb-4 font-medium text-lg text-cool-gray-700">
                {section.header}
              </h3>
              <div
                className={classNames(
                  `grid gap-4`,
                  section.items.length === 1
                    ? 'grid-cols-1'
                    : section.items.length === 2
                    ? 'grid-cols-2 max-w-[250px] mx-auto'
                    : 'grid-cols-3',
                )}
              >
                {section.items.map(item => (
                  <div className="flex items-center flex-col">
                    <div className="bg-blue-300 w-12 h-12 rounded-full flex items-center justify-center mb-1">
                      <CoverageIcon
                        className="text-blue-500 w-11 h-11"
                        icon={item.type}
                      />
                    </div>
                    <div className="text-cool-gray-700 font-medium text-xs leading-tight">
                      {item.label}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    ),
    actions: (data: CoverageHudData) => [
      {
        title: 'Coverage details',
        primary: false,
        className: '',
        onClick: (history: any) =>
          history.push({
            pathname: '/policy/coverages',
            search: '?inline=true',
            state: { coverage_data: data },
          }),
      },
      {
        title: 'Got it',
        primary: true,
        className: '',
        onClick: () => {},
      },
    ],
  },
  'temporary-accomodations': {
    icon: 'check',
    title: "We'll reach out",
    body: (data: { isCommercialClaim: boolean }) =>
      data.isCommercialClaim ? (
        <div>
          We are sorry to hear about your business. Our team has been alerted
          and will reach out soon to provide immediate assistance.
        </div>
      ) : (
        <div>
          We are sorry to hear about your home. Our team has been alerted and
          will reach out soon to assist in arranging temporary accommodations.
        </div>
      ),
  },
  'water-mitigation': {
    icon: 'check',
    title: "We'll reach out",
    body: (data: { isCommercialClaim: boolean }) => (
      <div>
        We're on it. Our team has been alerted and will reach out soon to assist
        in arranging water mitigation services.
      </div>
    ),
  },
  'policy-verification': {
    icon: 'check',
    title: (data: {
      filer_name: string;
      is_first_party?: boolean;
      insurer_name: string;
    }) =>
      data.is_first_party
        ? `Welcome back, ${data.filer_name}`
        : 'We’ve found a match',
    body: (data: {
      filer_name: string;
      is_first_party?: boolean;
      insurer_name: string;
      claim_type?: string;
    }) =>
      data.is_first_party ? (
        <div>
          We're sorry to hear about your claim, and we'll work to{' '}
          {data.claim_type === 'property'
            ? 'process your claim as quickly as possible'
            : 'get you back on the road as soon as possible'}
          .
        </div>
      ) : (
        <div>
          We've been able to locate a {data.insurer_name} policy. Now, let's
          gather the details of your claim.
        </div>
      ),
    actions: (data: CoverageHudData) => [
      {
        title: 'Continue',
        primary: true,
        className: '',
        onClick: () => {},
      },
    ],
  },
};

interface ClaimWorkflowNotificationProps {
  notification: WorkflowStepNotification;
}
export const ClaimWorkflowNotification: React.FC<
  ClaimWorkflowNotificationProps
> = ({ notification }) => {
  const matched = NOTIFICATIONS[notification.type];
  if (!matched) {
    return null;
  }

  const { title, body, actions, ...rest } = matched;

  return (
    <Modal
      id={notification.id}
      title={
        typeof title === 'function' ? title(notification.data ?? {}) : title
      }
      body={body(notification.data ?? {})}
      actions={
        typeof actions === 'function'
          ? actions(notification.data ?? {})
          : actions
      }
      {...rest}
    />
  );
};

export default ClaimWorkflowNotification;
