import classNames from 'classnames';
import Fuse from 'fuse.js';
import { useEffect, useMemo } from 'react';

import { HighlightedText } from '@assured/design-system/src/components/HighlightedText';
import {
  LocationIndicator,
  LocationType,
} from '@assured/design-system/src/components/LocationIndicator';
import { LocationType as LocationObjectType } from '@assured/shared-types/Claim';
import { ExitNotListed } from '@assured/shared-types/Location/highwayExit';

const MAX_RESULTS_DISPLAYED = 6;

export type ExitLocationItemData = {
  body: string;
  locationIndicatorKey: LocationType;
  title: string;
  id: string;
  value: LocationObjectType;
};

export type ExitLocationItemProps = {
  index: number;
  isSelected?: boolean;
  locationData: ExitLocationItemData;
  onItemClick?: (item: ExitLocationItemData) => void;
  onItemDeselect?: (item: ExitLocationItemData) => void;
  searchTerm: string;
};

export const ExitLocationItem = ({
  index,
  isSelected = true,
  locationData,
  onItemClick,
  onItemDeselect,
  searchTerm,
}: ExitLocationItemProps) => {
  const { locationIndicatorKey, title, body } = locationData;

  return (
    <button
      data-exit-location-item={index}
      className={classNames(
        'select-btn w-full !ml-1 !mb-0 !mr-2 !mt-1 relative pr-12 focus:outline-none focus:ring focus:ring-cool-gray-100',
        {
          'bg-cool-gray-100': isSelected,
          'bg-white': !isSelected,
        },
      )}
      onClick={() => {
        onItemClick?.(locationData);
      }}
    >
      <div className="flex flex-row items-center text-left">
        <LocationIndicator size="small" type={locationIndicatorKey}>
          {title}
        </LocationIndicator>
        <div className="pl-5 leading-tight">
          <div className="font-bold pb-0.5">
            {isSelected ? (
              title
            ) : (
              <HighlightedText
                classNameHighlighted="font-bold text-indigo-bright-600"
                input={title}
                term={searchTerm}
              />
            )}
          </div>
          <div className="text-cool-gray-400">
            {isSelected ? (
              body
            ) : (
              <HighlightedText
                classNameHighlighted="font-bold text-indigo-bright-600"
                input={body}
                term={searchTerm}
              />
            )}
          </div>
        </div>
      </div>
      {isSelected && (
        <div className="absolute top-1/2 right-4 -translate-y-1/2">
          <button
            className="p-1" /* larger touch area for button */
            onClick={e => {
              /* stop event propagating to parent button */
              e.stopPropagation();
              e.preventDefault();
              onItemDeselect?.(locationData);
            }}
          >
            <svg
              width="13"
              height="14"
              viewBox="0 0 9 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M0.505732 1.00521C0.637001 0.873976 0.815017 0.800255 1.00063 0.800255C1.18625 0.800255 1.36426 0.873976 1.49553 1.00521L4.50063 4.01031L7.50573 1.00521C7.5703 0.938349 7.64755 0.885021 7.73295 0.848335C7.81835 0.811648 7.91021 0.792338 8.00315 0.79153C8.0961 0.790723 8.18827 0.808434 8.2743 0.84363C8.36033 0.878827 8.43848 0.930804 8.50421 0.996529C8.56993 1.06225 8.62191 1.14041 8.65711 1.22644C8.6923 1.31247 8.71002 1.40464 8.70921 1.49759C8.7084 1.59053 8.68909 1.68239 8.6524 1.76779C8.61572 1.85319 8.56239 1.93043 8.49553 1.99501L5.49043 5.00011L8.49553 8.00521C8.62304 8.13723 8.6936 8.31405 8.692 8.49759C8.69041 8.68112 8.61679 8.85669 8.487 8.98648C8.35722 9.11627 8.18165 9.18988 7.99811 9.19148C7.81457 9.19307 7.63775 9.12252 7.50573 8.99501L4.50063 5.98991L1.49553 8.99501C1.36351 9.12252 1.18669 9.19307 1.00315 9.19148C0.819613 9.18988 0.644044 9.11627 0.514259 8.98648C0.384473 8.85669 0.310855 8.68112 0.30926 8.49759C0.307665 8.31405 0.378221 8.13723 0.505732 8.00521L3.51083 5.00011L0.505732 1.99501C0.374502 1.86374 0.300781 1.68572 0.300781 1.50011C0.300781 1.31449 0.374502 1.13648 0.505732 1.00521Z"
                fill="#94A3B8"
              />
            </svg>
          </button>
        </div>
      )}
    </button>
  );
};

export type LocationListProps = {
  locations: ExitLocationItemData[];
  limitResults?: number | null;
  searchTerm?: string | null;
  selectedItem?: ExitLocationItemData | null;
  onItemClick?: (item: ExitLocationItemData) => void;
  onItemDeselect?: (item: ExitLocationItemData) => void;
  onNoResults?: () => void;
};

export const ExitLocationList = ({
  locations,
  searchTerm,
  selectedItem,
  onItemClick,
  onItemDeselect,
  onNoResults,
  limitResults,
}: LocationListProps) => {
  const hasSearchTerm = (searchTerm?.length || 0) > 1;
  const fuse = useMemo(() => {
    return new Fuse<ExitLocationItemData>(locations, {
      keys: ['title', 'body'],
      includeScore: true,
      minMatchCharLength: 1,
      threshold: 0.3,
      useExtendedSearch: true,
    });
  }, [locations]);

  const results = useMemo(() => {
    if (!searchTerm || searchTerm?.length < 2) return locations;
    const extendedSearchTerm = `="${ExitNotListed.name}" | ${searchTerm}`;
    const matches = fuse.search(extendedSearchTerm);
    const matchedOptions = matches.map(match => match.item);
    return matchedOptions;
  }, [fuse, locations, searchTerm]);

  useEffect(() => {
    results?.length === 0 && onNoResults?.();
  }, [results]);

  const resultsToShow = !!selectedItem
    ? [selectedItem]
    : hasSearchTerm
    ? results?.slice(0, limitResults ?? MAX_RESULTS_DISPLAYED)
    : [];
  if (resultsToShow) {
    // pop the "Exit not listed" option from first to last entry in list
    const exitNotListedIndex = resultsToShow.findIndex(
      result => result.id === ExitNotListed.id,
    );
    if (exitNotListedIndex > -1) {
      resultsToShow.push(resultsToShow.splice(exitNotListedIndex, 1)[0]);
    }
  }

  return (
    <div
      className={'transition-all duration-500 overflow-hidden'}
      style={{
        maxHeight: `${
          hasSearchTerm && !resultsToShow.length ? 8 : resultsToShow.length * 8
        }rem`,
      }}
    >
      {resultsToShow.length ? (
        resultsToShow.map((locationData, index) => {
          return (
            <div className="pb-4 pr-2" key={locationData.id}>
              <ExitLocationItem
                index={index}
                key={index}
                isSelected={locationData?.id === selectedItem?.id}
                locationData={locationData}
                onItemClick={onItemClick}
                onItemDeselect={onItemDeselect}
                searchTerm={searchTerm || ''}
              />
            </div>
          );
        })
      ) : hasSearchTerm ? (
        <>
          <div
            style={{
              fontSize: '3.5rem',
            }}
          >
            🙁
          </div>
          <div className="text-cool-gray-400">No results found</div>
        </>
      ) : null}
    </div>
  );
};
