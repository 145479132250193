import { currency } from './Currency';
import { distance_number } from './DistanceNumber';
import { feet_inches } from './FeetInches';
import { hours_minutes } from './HoursMinutes';
import { mph_number } from './MPHNumber';
import { number } from './Number';
import { paragraph } from './Paragraph';
import { percentage } from './Percentage';
import { phone_number } from './PhoneNumber';
import { postal_code } from './PostalCode';
import { small_number } from './SmallNumber';
import { ssn } from './SSNumber';
import { vin } from './VIN';

export const varieties = {
  currency,
  distance_number,
  feet_inches,
  hours_minutes,
  mph_number,
  number,
  paragraph,
  percentage,
  phone_number,
  postal_code,
  small_number,
  ssn,
  vin,
};
