import { DateTime } from 'luxon';

import { UTCDateTime } from '@assured/step-renderer';

export const isUTCDateTime = (
  value: UTCDateTime | string | undefined | null,
): value is UTCDateTime => {
  return !!value && typeof value === 'object' && 'rawValue' in value;
};

export const getTimezoneIfProvided = ({
  existing_value,
  timezone,
}: {
  existing_value?: UTCDateTime | string | null;
  timezone?: string;
}) => {
  if (timezone) {
    return { zone: timezone };
  }
  if (isUTCDateTime(existing_value) && existing_value?.timezone) {
    return { zone: existing_value?.timezone };
  }
  return {};
};

const getDateTimeFromUnion = (
  value?: UTCDateTime | string | null,
): DateTime | undefined => {
  if (!value) {
    return undefined;
  }
  if (!isUTCDateTime(value)) {
    return DateTime.fromISO(value);
  }
  return DateTime.fromISO(value.rawValue);
};

export const getValue = ({
  primaryValue,
  existing_value,
}: {
  primaryValue?: UTCDateTime;
  existing_value?: UTCDateTime | string | null;
}): DateTime | undefined => {
  return getDateTimeFromUnion(primaryValue || existing_value);
};

export const getDateBoundaries = ({
  date_mode,
  date_must_be_before,
  date_must_be_after,
}: {
  date_mode?: string;
  date_must_be_before?: UTCDateTime | string | null;
  date_must_be_after?: UTCDateTime | string | null;
}) => {
  let dateMustBeBefore: DateTime | undefined;
  if (date_must_be_before) {
    dateMustBeBefore = getDateTimeFromUnion(date_must_be_before);
  } else if (date_mode === 'past' || date_mode === 'past_including_time') {
    dateMustBeBefore = DateTime.fromJSDate(new Date());
  }
  let dateMustBeAfter: DateTime | undefined;
  if (date_must_be_after) {
    dateMustBeAfter = getDateTimeFromUnion(date_must_be_after);
  } else if (date_mode === 'future') {
    dateMustBeAfter = DateTime.fromJSDate(new Date());
  }
  return { dateMustBeBefore, dateMustBeAfter };
};

export const calculateNewValue = ({
  newDate,
  zone,
  existing_value,
}: {
  newDate?: Date;
  zone: { zone?: string };
  existing_value?: UTCDateTime | string | null;
}): UTCDateTime | undefined => {
  if (!newDate) {
    return undefined;
  }
  let adjustedProps;
  if (zone.zone) {
    adjustedProps = {
      timezone: zone.zone,
      UTCAdjustedDateTime: DateTime.fromJSDate(newDate)
        .toUTC()
        .toISO({ includeOffset: false }),
    };
  }
  return {
    id: isUTCDateTime(existing_value) ? existing_value?.id : undefined,
    rawValue: DateTime.fromJSDate(newDate).toISO({ includeOffset: false }),
    ...adjustedProps,
  };
};
