import React from 'react';

interface HiddenProps {
  step_component: {
    value: string;
    field: string;
    id?: string;
  };
}

const Hidden: React.FC<HiddenProps> = ({ step_component }) => {
  if (!step_component?.value || !step_component?.field) return null;

  return (
    <input
      id={step_component?.id}
      data-testid={`${step_component?.id}-hidden`}
      type="hidden"
      name={step_component?.field}
      value={step_component?.value}
    />
  );
};

export default Hidden;
