import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useTenantConfig } from '~/src/hooks';

import { ClaimWorkflowStepFragmentData } from '@assured/step-renderer';
import {
  StepProvider,
  StepRenderer,
  StepRendererProps,
  useStep,
} from '@assured/step-renderer/components';
import TermsWidget from '@assured/ui/components/TermsWidget';

import ClaimWorkflowNotification from './ClaimWorkflowNotification';
import { selfServiceRegistry } from './registries/selfServiceRegistry';
import Title from './Title';

const ClaimWorkflowStepInner: React.FC<
  Omit<StepRendererProps, 'components' | 'registry'>
> = ({ ...rest }) => {
  const {
    step,
    attemptSubmit,
    submit,
    values,
    resetWithValues,
    skip,
    stepSkippable,
    manualSubmitRequired,
    stepReadyToContinue,
  } = useStep();

  const tenantConfig = useTenantConfig();

  // Reset when the step changes.
  useEffect(() => {
    let newValues: Record<string, any> = {};

    (step?.content?.step_components || []).forEach(c => {
      if ('existing_value' in c) {
        if (c.field && typeof c.existing_value !== 'undefined') {
          newValues[c.field] = c.existing_value;
        }
      }
    });

    resetWithValues(newValues);
  }, [step, resetWithValues]);

  // Autosubmit if possible whenever values change
  useEffect(() => {
    if (!manualSubmitRequired) {
      attemptSubmit();
    }
  }, [values]);

  if (!step) {
    return null;
  }

  return (
    <div className="-mt-8 step-renderer">
      <StepRenderer
        components={{
          Title,
          Notification: ClaimWorkflowNotification,
        }}
        {...rest}
      />

      {step.content.include_terms_footer &&
      // Progressive's Terms widget is above the submit/continue buttons
      tenantConfig?.tenant === 'progressive' ? (
        <div className="mt-8">
          <TermsWidget
            tenantName={tenantConfig?.displayName}
            hideExtendedTerms={step.content.terms_footer_hide_extended_terms}
            continueButtonText={step.content.manual_submit_label}
          />
        </div>
      ) : null}

      {manualSubmitRequired || stepSkippable ? (
        <div
          key={step.id}
          className={classNames(
            step.content.step_components.some(s => s.widget) &&
              !manualSubmitRequired
              ? 'ClaimWorkflowInnerDelay'
              : 'ClaimWorkflowInner',
            'mt-4 flex flex-wrap-reverse justify-center gap-4',
            step.content.step_components.length > 1 && 'mt-6',
          )}
        >
          {stepSkippable ? (
            <button
              type="button"
              data-testid="secondaryAction"
              className="btn btn-subtle sm:order-first m-0"
              onClick={skip}
            >
              {step.content.skip_label || 'Skip'}
            </button>
          ) : null}
          {manualSubmitRequired ? (
            <button
              type="button"
              data-testid="primaryAction"
              className={classNames(
                'btn',
                'm-0',
                step.content.manual_submit_button_class_name_override ||
                  'btn-blue',
                'sm:order-last',
                {
                  'btn-disabled': !stepReadyToContinue,
                },
              )}
              onClick={() => stepReadyToContinue && submit(values)}
              type="button"
              disabled={!stepReadyToContinue}
            >
              {step.content.manual_submit_label || 'Continue'}
            </button>
          ) : null}
        </div>
      ) : null}

      {step.content.include_terms_footer &&
      // Non-Progressive Terms widgets are below the submit/continue buttons
      tenantConfig?.tenant !== 'progressive' ? (
        <div>
          <TermsWidget
            tenantName={tenantConfig?.displayName}
            hideExtendedTerms={step.content.terms_footer_hide_extended_terms}
            continueButtonText={step.content.manual_submit_label}
          />
        </div>
      ) : null}
    </div>
  );
};

/**
 * This is the main component for rendering a step in the self-service FNOL
 * workflow. It is a wrapper around the StepRenderer component that provides
 * the step data and the registry of components to use.
 *
 * Originally, all of StepRenderer was contained within this component. The step
 * rendering state was moved into StepProvider, and the binding of that state to
 * the UI was moved into StepRenderer.
 */
export const ClaimWorkflowStep: React.FC<
  Omit<StepRendererProps, 'components' | 'registry'> & {
    step: ClaimWorkflowStepFragmentData | null;
    onSubmit: (values: any) => void;
  }
> = ({ step, onSubmit, ...rest }) => {
  if (!step) {
    return null;
  }

  return (
    <StepProvider
      step={step}
      onSubmit={onSubmit}
      registry={selfServiceRegistry}
    >
      <ClaimWorkflowStepInner {...rest} />
    </StepProvider>
  );
};

export default ClaimWorkflowStep;
