import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import CoverageIcon from '../elements/CoverageIcon';
import Title from './Title';

import type {
  StepComponentFC,
  StepComponentSharedProps,
  CoverageSummaryStepComponentSpec,
} from '@assured/step-renderer';
interface CoverageSummaryProps
  extends StepComponentSharedProps<
    CoverageSummaryStepComponentSpec,
    string | null
  > {
  forceSubmit: () => void;
}

const CoverageSummary: StepComponentFC<CoverageSummaryProps> = ({
  step_component,
  forceSubmit,
  className,
  workflowChannel,
}) => {
  const history = useHistory();
  const data = step_component.coverage_data;

  useEffect(() => {
    if (!data) {
      forceSubmit();
    }
  }, []);

  if (!data) {
    return null;
  }

  return (
    <div className={classNames('relative', className)}>
      <div>
        <Title title={{ text: 'Coverage Review', bold: true }} />
        {workflowChannel === 'sidekick' ? (
          <div className="mt-4 text-cool-gray-600 text-lg">
            Thank you! Based on the details you provided, here are coverages
            that may be relevant for this {data.incident_type} claim.
          </div>
        ) : (
          <>
            <div className="mt-4 text-cool-gray-600">
              Based on the details you provided, here's how you,{' '}
              <span className="text-blue-500 font-semibold">
                {data.covered_name}
              </span>
              , may be covered for this {data.incident_type}.
              {data.insurer_name === 'Progressive' ? (
                <>
                  {' '}
                  Additional coverages may apply. Your adjuster will review your
                  available coverages with you.
                </>
              ) : null}
            </div>
            <div className="mt-2 text-cool-gray-600">
              Please review and then submit your claim using the button below.
            </div>
          </>
        )}
        <div className="mb-6">
          {data.coverages.map(section => (
            <div>
              <h3 className="mt-6 mb-4 text-left font-semibold text-lg text-cool-gray-700">
                {section.header}
              </h3>
              <div className={classNames(`grid gap-4 grid-cols-1`)}>
                {section.items.map(item => (
                  <div className="flex items-start">
                    <div className="">
                      <CoverageIcon
                        className="text-blue-500 w-12 h-12 -mt-2 -ml-2"
                        icon={item.type}
                      />
                    </div>
                    <div className="text-left text-cool-gray-700 flex-1 ml-2 mr-3">
                      <div className="font-semibold text-sm leading-tight">
                        {item.label}
                      </div>
                      <div className="text-xs leading-tight mt-1">
                        {item.short_description || item.description}
                      </div>
                    </div>
                    <div className="text-cool-gray-700 font-medium text-xs leading-tight text-right mt-1">
                      {Object.keys(item.amounts).map(k => (
                        <div>
                          <div style={{ fontSize: 11 }}>
                            {k === 'per_person'
                              ? 'Per Person'
                              : k === 'per_occurrence'
                              ? `Per ${data.occurrence_label ?? 'Occurrence'}`
                              : k === 'deductible'
                              ? 'Deductible'
                              : k === 'radius'
                              ? 'Radius'
                              : k === 'per_day'
                              ? 'Per Day'
                              : null}
                          </div>
                          <div className="text-sm mb-2">
                            {item.amounts[k as keyof typeof item.amounts]}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className={classNames(
          'ClaimWorkflowInner mt-4 flex flex-wrap justify-center',
        )}
      >
        <button
          className="btn btn-blue sm:order-last"
          onClick={() => forceSubmit()}
        >
          Submit claim
        </button>
        {workflowChannel !== 'sidekick' ? (
          <button
            className="btn btn-subtle sm:order-first"
            onClick={() => {
              history.push({
                pathname: '/policy/coverages',
                search: '?inline=true',
                state: { coverage_data: data },
              });
            }}
          >
            Coverage details
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default CoverageSummary;
