import classNames from 'classnames';
import React from 'react';

import { useMobileDetect } from '../../helpers/useMobileDetect';
import { StepComponentRenderer } from '../StepComponentRenderer';
import { useStep } from './useStep';

import type {
  StepComponentControlsBackHookProps,
  StepComponentShowsPrefillProps,
  StepSkipConfirmProps,
  StepTitleProps,
  WorkflowStepNotification,
} from '../../types';
const VERBOSE_ERROR_THRESHOLD = 2;

type WorkflowStepExtendable = Partial<
  StepComponentShowsPrefillProps & StepComponentControlsBackHookProps
>;

export interface StepRendererProps extends WorkflowStepExtendable {
  loading?: boolean;
  workflowChannel?: 'self_service' | 'sidekick';
  onSidekickCommand?: (data: { type: 'DIGITAL_REQUEST_MEDIA' }) => void;
  components: {
    Notification: React.FC<{ notification: WorkflowStepNotification }>;
    Title: React.FC<StepTitleProps>;
    SkipConfirm?: React.FC<StepSkipConfirmProps>;
  };
}

export const StepRenderer: React.FC<StepRendererProps> = ({
  onSidekickCommand,
  components: { Notification, Title, SkipConfirm },
  workflowChannel,
  ...rest
}) => {
  const {
    step,
    attemptSubmit,
    submit,
    updateValue,
    values,
    valuesJSON,
    errors,
    errorCount,
    stepControlledTitle,
    stepRequiresFullPageHeight,
    registry,
    skipNeedsManualConfirm,
    skipConfirmed,
    setSkipConfirmed,
  } = useStep();
  const { isDesktop } = useMobileDetect();

  if (!step) {
    return null;
  }

  return (
    <>
      {step.notifications?.length ? (
        <Notification notification={step.notifications[0]} />
      ) : null}
      <div style={{ display: 'none' }} data-testid="wf-step-key">
        {step.key}
      </div>

      {step.content.step_components.map(
        (step_component, i, step_components) => {
          const filterEntry = step_component?.client_filter
            ? Object.entries(step_component?.client_filter)?.at?.(0)
            : [];

          if (
            filterEntry?.length &&
            (typeof valuesJSON === 'object' && !Array.isArray(valuesJSON) // nested field
              ? valuesJSON[filterEntry?.[0]] !== filterEntry?.[1]
              : values[filterEntry?.[0]] !== filterEntry?.[1])
          ) {
            return null;
          }

          const titleClass =
            i === 0 ? 'ClaimWorkflowTitle' : 'ClaimWorkflowInner';

          const isFirstInteractiveComponent =
            i === 0 ||
            step_components.slice(0, i).every(sc => sc.type === 'interstitial');

          const isSidekickTitlelessSelectMultiWithAnotherBeforeIt =
            workflowChannel === 'sidekick' &&
            i > 0 &&
            !step_component.title &&
            step_component.type === 'select_multi' &&
            step_components[i - 1]?.type === 'select_multi';
          let error = step_component.field
            ? Object.entries(errors).find(([key]) =>
                key.startsWith(step_component.field || ''),
              )?.[1]
            : undefined;
          error = Array.isArray(error) ? error[0] : error;
          return (
            <div
              key={step_component.id || step_component.field}
              className={classNames(
                step_component.class_name_override ||
                  (isSidekickTitlelessSelectMultiWithAnotherBeforeIt
                    ? 'mt-0'
                    : 'mt-8'),
                stepRequiresFullPageHeight &&
                  'flex flex-col h-[calc(100%-36px)]', // height account for mt and intersection-box
              )}
            >
              {!stepControlledTitle ? (
                <Title
                  title={step_component.title}
                  subtitle={step_component.subtitle}
                  titleClassName={titleClass}
                  injectors={step_component.injectors}
                  textReplacer={
                    step_component.type === 'upload' && isDesktop
                      ? t =>
                          t
                            .replace(/take or upload/g, 'upload')
                            .replace(/take/g, 'upload')
                            .replace(/Take/g, 'Upload')
                      : undefined
                  }
                  isFirst={i === 0}
                  callout={step_component.callout}
                />
              ) : null}
              {i === 0 && skipNeedsManualConfirm && SkipConfirm ? (
                <SkipConfirm
                  skipLabel={step.content.skip_label}
                  skipConfirmed={skipConfirmed}
                  onSetSkipConfirmed={setSkipConfirmed}
                />
              ) : null}
              <StepComponentRenderer
                primaryValue={
                  step_component.field ? values[step_component.field] : null
                }
                updateValue={updateValue}
                step_component={step_component}
                error={error}
                errors={errors}
                showErrorMessages={
                  workflowChannel === 'sidekick'
                    ? errorCount > 0
                    : errorCount >= VERBOSE_ERROR_THRESHOLD
                }
                forceSubmit={() => submit(values)}
                attemptSubmit={attemptSubmit}
                titleClassName={titleClass}
                otherValue={
                  'other_field' in step_component && step_component.other_field
                    ? values[step_component.other_field]
                    : undefined
                }
                sourceValue={
                  'source_value' in step_component
                    ? step_component.source_value
                    : 'source_field' in step_component &&
                      step_component.source_field
                    ? values[step_component.source_field]
                    : undefined
                }
                allValues={values}
                registry={registry}
                onSidekickCommand={onSidekickCommand}
                disabled={skipConfirmed}
                autoFocus={isFirstInteractiveComponent}
                workflowChannel={workflowChannel}
                {...rest}
              />
            </div>
          );
        },
      )}
      {errors.default ? (
        <div className="mt-6 error-message" role="alert" aria-live="assertive">
          {errors.default}
        </div>
      ) : null}
    </>
  );
};

export default StepRenderer;
