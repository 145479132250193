import UnitInput from '../UnitInput';
import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

export const hours_minutes: StringInputVariety = ({
  step_component,
  primaryValue,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  const Component = UnitInput;
  const v = primaryValue ? +primaryValue : 0;

  const additionalProps = {
    value: v || undefined,
    onRawChange: (val: number) => updateValue(step_component.field, val),
    subdivisions: [
      {
        label: 'hr',
        base_value: 60,
      },
      {
        label: 'min',
        base_value: 1,
      },
    ],
    style: { textAlign: 'center' },
  };
  return {
    Component,
    additionalProps,
    narrow: true,
  };
};
