import React, { useState, useRef, useEffect, ReactElement } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const propertiesToPrefix: Record<string, boolean> = {
  transform: true,
  transformOrigin: true,
  transition: true,
};

const webkitPrefix = 'Webkit';

const prefix = (styles: Record<string, any>) => {
  const prefixedStyles: Record<string, any> = {};
  const styleProperties = Object.keys(styles || {});
  styleProperties.forEach(property => {
    if (propertiesToPrefix[property]) {
      const prefixedProperty =
        webkitPrefix + property[0].toUpperCase() + property.slice(1);
      prefixedStyles[prefixedProperty] = styles[property];
    }
    prefixedStyles[property] = styles[property];
  });
  return prefixedStyles;
};

const renderSubtreeIntoContainer = ReactDOM.unstable_renderSubtreeIntoContainer;

const components: Record<
  string,
  {
    prevPosition: PositionStyle;
    prevElement: React.ReactElement;
  } | null
> = {};

interface OverdriveState {
  loading: boolean;
}

interface PositionStyle {
  top: number;
  left: number;
  width: number;
  height: number;
  margin: string;
  padding: string;
  borderRadius: string;
  position: string;
}
interface OverdriveProps {
  id: string;
  duration: number;
  easing?: string;
  element?: React.ElementType;
  animationDelay?: number;
  onAnimationEnd?: () => void;
  style?: Record<string, any>;
  forceRefresh?: boolean;
  didForceRefresh?: () => void;
  [key: string]: any;
}

interface OverdriveRefs {
  animationTimeout?: number;
  animationDelayTimeout?: number;
  bodyElement: Node;
  element: Node;
  onShowLock: boolean;
}

class Overdrive extends React.Component<OverdriveProps, OverdriveState> {
  static propTypes = {};
  static defaultProps = {};
  animationTimeout: number | null;
  animationDelayTimeout: number | null;
  bodyElement: Node | null;
  element: Node | null;
  onShowLock: boolean;

  constructor(props: OverdriveProps) {
    super(props);
    this.state = {
      loading: true,
    };
    this.animationTimeout = null;
    this.animationDelayTimeout = null;
    this.bodyElement = null;
    this.element = null;
    this.onShowLock = false;
    this.animateEnd = this.animateEnd.bind(this);
  }

  animate(prevPosition: PositionStyle, prevElement: React.ReactElement) {
    const { duration, easing } = this.props;

    prevPosition.top +=
      window.pageYOffset || document.documentElement.scrollTop;
    const nextPosition = this.getPosition(true);
    const noTransform = 'scaleX(1) scaleY(1) translateX(0px) translateY(0px)';
    const targetScaleX = prevPosition.width / nextPosition.width;
    const targetScaleY = prevPosition.height / nextPosition.height;
    const targetTranslateX = prevPosition.left - nextPosition.left;
    const targetTranslateY = prevPosition.top - nextPosition.top;

    if (
      targetScaleX === 1 &&
      targetScaleY === 1 &&
      targetTranslateX === 0 &&
      targetTranslateY === 0
    ) {
      return;
    }

    const transition = {
      transition: `transform ${duration / 1000}s ${easing}, opacity ${
        duration / 1000
      }s ${easing}`,
      transformOrigin: '0 0 0',
    };

    const sourceStart = React.cloneElement(prevElement, {
      key: '1',
      style: prefix({
        ...transition,
        ...prevPosition,
        opacity: 1,
        transform: noTransform,
      }),
    });

    const sourceEnd = React.cloneElement(prevElement, {
      key: '1',
      style: prefix({
        ...transition,
        ...prevPosition,
        margin: nextPosition.margin,
        opacity: 0,
        transform: `matrix(${1 / targetScaleX}, 0, 0, ${
          1 / targetScaleY
        }, ${-targetTranslateX}, ${-targetTranslateY})`,
      }),
    });

    const targetStart = React.cloneElement(
      this.props.children as ReactElement,
      {
        key: '2',
        style: prefix({
          ...transition,
          ...nextPosition,
          margin: prevPosition.margin,
          opacity: 0,
          transform: `matrix(${targetScaleX}, 0, 0, ${targetScaleY}, ${targetTranslateX}, ${targetTranslateY})`,
        }),
      },
    );

    const targetEnd = React.cloneElement(this.props.children as ReactElement, {
      key: '2',
      style: prefix({
        ...transition,
        ...nextPosition,
        opacity: 1,
        transform: noTransform,
      }),
    });

    const start = (
      <div>
        {sourceStart}
        {targetStart}
      </div>
    );
    const end = (
      <div>
        {sourceEnd}
        {targetEnd}
      </div>
    );

    this.setState({ loading: true });

    const bodyElement = document.createElement('div');
    window.document.body.appendChild(bodyElement);
    this.bodyElement = bodyElement;
    renderSubtreeIntoContainer(this, start, bodyElement);

    this.animationTimeout = setTimeout(() => {
      renderSubtreeIntoContainer(this, end, bodyElement);
      this.animationTimeout = setTimeout(this.animateEnd, duration) as any;
    }, 0) as any;
  }

  animateEnd() {
    this.animationTimeout = null;
    this.setState({ loading: false });
    this.props.onAnimationEnd && this.props.onAnimationEnd();
    this.bodyElement && window.document.body.removeChild(this.bodyElement);
  }

  onHide() {
    const { id } = this.props;

    // Have already hidden recently, don't try again.
    if (components[id]) {
      return;
    }

    const prevElement = React.cloneElement(this.props.children as ReactElement);
    const prevPosition = this.getPosition();
    components[id] = {
      prevPosition,
      prevElement,
    };

    this.clearAnimations();

    setTimeout(() => {
      components[id] = null;
    }, 100);
  }

  onShow() {
    if (this.onShowLock) {
      return;
    }
    this.onShowLock = true;
    const { id, animationDelay } = this.props;
    if (components[id]) {
      const { prevPosition, prevElement } = components[id]!;
      components[id] = null;
      if (animationDelay) {
        this.animationDelayTimeout = setTimeout(
          this.animate.bind(this, prevPosition, prevElement),
          animationDelay,
        ) as any;
      } else {
        this.animate(prevPosition, prevElement);
      }
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidMount() {
    this.onShow();
  }

  clearAnimations() {
    this.animationDelayTimeout && clearTimeout(this.animationDelayTimeout);
    this.animationTimeout && clearTimeout(this.animationTimeout);

    if (this.animationTimeout) {
      this.animateEnd();
    }
  }

  componentWillUnmount() {
    this.onHide();
  }

  componentWillReceiveProps() {
    this.onShowLock = false;
    this.onHide();
  }

  componentDidUpdate() {
    this.onShow();
  }

  getPosition(addOffset?: boolean): PositionStyle {
    const node = this.element;
    const rect = (node as HTMLElement).getBoundingClientRect();
    const computedStyle = window.getComputedStyle(node as HTMLElement);
    const marginTop = parseInt(computedStyle.marginTop, 10);
    const marginLeft = parseInt(computedStyle.marginLeft, 10);
    return {
      top:
        rect.top -
        marginTop +
        (addOffset ? 1 : 0) *
          (window.pageYOffset || document.documentElement.scrollTop),
      left: rect.left - marginLeft,
      width: rect.width,
      height: rect.height,
      margin: computedStyle.margin,
      padding: computedStyle.padding,
      borderRadius: computedStyle.borderRadius,
      position: 'absolute',
    };
  }

  render() {
    const {
      id,
      duration,
      animationDelay,
      style = {},
      children,
      element,
      ...rest
    } = this.props;
    const newStyle = {
      ...style,
      opacity: this.state.loading ? 0 : 1,
    };
    const onlyChild = React.Children.only(children);

    return React.createElement(
      element || 'div',
      {
        ref: (c: Node) => (this.element = c && (c.firstChild as Node)),
        style: newStyle,
        ...rest,
      },
      onlyChild,
    );
  }
}

Overdrive.propTypes = {
  id: PropTypes.string.isRequired,
  duration: PropTypes.number,
  easing: PropTypes.string,
  element: PropTypes.string,
  animationDelay: PropTypes.number,
  onAnimationEnd: PropTypes.func,
};

Overdrive.defaultProps = {
  element: 'div',
  duration: 200,
  easing: '',
};

export default Overdrive;
