import classNames from 'classnames';
import moment from 'moment';

import { UserCircleIcon } from '@heroicons/react/solid';

export const CommercialDriverEntry = ({
  entry,
  selected,
  onClick,
}: {
  entry: {
    name: string;
    contactId: string;
    employeeId: string;
    cdlNumber: string;
    cdlState: string;
    cdlExpiration: string;
    endorsements: string[];
    dateOfBirth?: string;
  };
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <div
      className={classNames(
        'flex text-left items-center border rounded-lg px-4 py-2 cursor-pointer',
        selected
          ? 'border-blue-300 bg-blue-100'
          : 'border-cool-gray-300  hover:bg-cool-gray-50',
      )}
      onClick={onClick}
    >
      <UserCircleIcon
        className={classNames(
          'h-8 w-8 mr-2',
          selected ? 'text-blue-500' : 'text-cool-gray-400',
        )}
      />
      <div className="flex-1">
        <div className="text-sm text-cool-gray-800">{entry.name}</div>
        {entry.cdlNumber ? (
          <div className="text-xs text-cool-gray-500">
            CDL: {entry.cdlState} {entry.cdlNumber}
          </div>
        ) : null}
        {entry.dateOfBirth ? (
          <div className="text-xs text-cool-gray-500">
            Date of birth: {moment(entry.dateOfBirth).format('M/D/YYYY')}
          </div>
        ) : null}
      </div>
      {entry.employeeId ? (
        <div className="text-sm text-cool-gray-500 leading-none">
          <div className="text-xxs text-cool-gray-400 mb-1">Employee ID</div>
          <div>
            #<span className="font-mono">{entry.employeeId}</span>
          </div>
        </div>
      ) : null}
    </div>
  );
};
