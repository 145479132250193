import {
  BodilyInjuryDisplayMode,
  Region,
  RegionTemplate,
} from '@assured/shared-types/ClaimWorkflow/BodilyInjury';

export const buildTabs = (
  regionTemplate: RegionTemplate,
  displayMode: BodilyInjuryDisplayMode,
  region?: Region,
) => {
  return regionTemplate.tabs
    .filter(t => {
      if (!t.display_in_modes) {
        return true;
      }
      if (t.display_in_modes.includes(displayMode)) {
        return true;
      }
      return false;
    })
    .map(t => {
      const count = (region?.options || []).filter(o =>
        regionTemplate.options
          .find(specOption => specOption.key === o.key)
          ?.tabs.includes(t.key),
      ).length;
      const altCount = (region?.options || []).filter(
        o => o.key === `${t.key}_other`,
      ).length;

      return {
        ...t,
        count: t.key === 'popular' ? 0 : count + altCount,
      };
    });
};

export const tabLabel = (regionTemplate: RegionTemplate, currentTab: string) =>
  regionTemplate.tabs?.find(t => t.key === currentTab)?.label?.toLowerCase();

export const buildOptions = (
  regionTemplate: RegionTemplate,
  currentTab: string,
  displayMode: BodilyInjuryDisplayMode,
  allowOtherEntry?: boolean,
  isLowSeverity?: boolean,
) => {
  return regionTemplate.options
    .filter(o => {
      let shouldDisplay = o.tabs.includes(currentTab);
      if (currentTab === 'popular' && o.popular_in_modes) {
        shouldDisplay = (o.popular_in_modes || []).includes(displayMode);
      }

      const shouldNOTExcludeForLowSeverity =
        !isLowSeverity ||
        !o.hide_if_low_severity ||
        !(o.hide_if_low_severity || []).includes(displayMode);

      const shouldNOTExcludeBecauseNOTHidden =
        !o.hidden_in_modes || !(o.hidden_in_modes || []).includes(displayMode);

      const shouldNOTExcludeIfInDisplay =
        !o.display_in_modes || o.display_in_modes.includes(displayMode);

      return (
        shouldDisplay &&
        shouldNOTExcludeForLowSeverity &&
        shouldNOTExcludeBecauseNOTHidden &&
        shouldNOTExcludeIfInDisplay
      );
    })
    .concat(
      currentTab !== 'popular' && allowOtherEntry
        ? [
            {
              key: `${currentTab}_other`,
              tabs: [],
              label: `Other ${tabLabel(regionTemplate, currentTab)} injury`,
            },
          ]
        : [],
    );
};
