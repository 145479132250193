import classNames from 'classnames';
import { FC } from 'react';

export type IndicatorSize = 'small' | 'large';

export enum HighwayExitType {
  EXIT = 'exit',
  JUNCTION_INTERSTATE = 'junction-interstate',
  JUNCTION_HIGHWAY = 'junction-highway',
  REST_AREA = 'rest-area',
  TOLL = 'toll',
  BORDER = 'border',
  TURNOUT = 'turnout',
  WELCOME_CENTER = 'welcome-center',
}

const staticText: Partial<Record<HighwayExitType, string | undefined>> = {
  [HighwayExitType.REST_AREA]: 'rest area',
  [HighwayExitType.TOLL]: 'toll',
  [HighwayExitType.BORDER]: 'border',
  [HighwayExitType.TURNOUT]: 'turnout',
  [HighwayExitType.WELCOME_CENTER]: 'welcome center',
} as const;

interface LocationIndicatorProps {
  size?: IndicatorSize;
  type: HighwayExitType;
}

export const LocationIndicator: FC<LocationIndicatorProps> = ({
  size = 'large',
  type,
  children,
}) => {
  const hasSmallRings = (
    [
      HighwayExitType.EXIT,
      HighwayExitType.JUNCTION_INTERSTATE,
      HighwayExitType.JUNCTION_HIGHWAY,
      HighwayExitType.TOLL,
      HighwayExitType.REST_AREA,
    ] as HighwayExitType[]
  ).includes(type);

  const classes = classNames({
    'flex items-center w-min rounded-lg uppercase font-semibold px-6 py-2 whitespace-nowrap relative border text-base':
      size === 'large',
    'max-w-[200px] overflow-hidden flex justify-center text-center text-sm items-center w-min rounded-lg uppercase font-semibold py-2 whitespace-nowrap relative border leading-tight':
      size === 'small',
    'px-4': size === 'small' && type === 'exit',
    'px-6': size === 'small' && type !== 'exit',

    'text-lg': size === 'small' && type === HighwayExitType.TOLL,

    'p-0': size === 'small',
    'ring-[1.5px] ring-offset-[0.75px] border-white border border-solid':
      hasSmallRings,
    'bg-[#13803E] ring-[#13803E]': type === HighwayExitType.EXIT,
    'bg-[#003981] ring-[#B01B2E]': type === HighwayExitType.JUNCTION_INTERSTATE,
    '!bg-black !ring-black !text-black !ring-[2px] !border-0 after:content-[""] after:absolute after:bg-white after:inset-0.5 after:rounded-md':
      type === HighwayExitType.TURNOUT,
    'translate-y-[0.5px] my-[0.4px] !text-white !bg-white !ring-[#015DD0]  !text-black !ring-[3.5px] !border-0 after:content-[""] after:absolute after:bg-[#015DD0] after:inset-0.5 after:rounded-md':
      type === HighwayExitType.WELCOME_CENTER,
    'translate-y-[0.5px] my-[0.4px] !text-white !bg-white !ring-[#016839]  !text-black !ring-[3.5px] !border-0 after:content-[""] after:absolute after:bg-[#016839] after:inset-0.5 after:rounded-md':
      type === HighwayExitType.BORDER,
    'after:content-[""] after:absolute after:border-[1.50px] after:border-black after:rounded-lg after:inset-0':
      type === HighwayExitType.JUNCTION_HIGHWAY,
    'after:content-[""] after:absolute z-5 after:border-[3px] after:border-black after:rounded-lg after:inset-0':
      type === HighwayExitType.TOLL,
    'bg-[#0659A0] ring-[#0659A0]': type === HighwayExitType.REST_AREA,
    'bg-white ring-black': (
      [
        HighwayExitType.JUNCTION_HIGHWAY,
        HighwayExitType.TOLL,
      ] as HighwayExitType[]
    ).includes(type),
    'text-white': (
      [
        HighwayExitType.EXIT,
        HighwayExitType.JUNCTION_INTERSTATE,
        HighwayExitType.REST_AREA,
        HighwayExitType.BORDER,
        HighwayExitType.WELCOME_CENTER,
      ] as HighwayExitType[]
    ).includes(type),
  });

  return (
    <div className={classes}>
      <div className="relative z-10 text-ellipsis ">
        {staticText[type]
          ? staticText[type]
          : size === 'small' &&
            ['exit', 'junction-interstate'].includes(type) &&
            typeof children === 'string' &&
            !!children.replace(/^Exit\s/, '').trim()
          ? children.replace(/^Exit\s/, '')
          : children}
      </div>
    </div>
  );
};
