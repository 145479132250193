import PhoneNumberInput from 'react-phone-number-input/input';

import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';
import { correctCapitalization } from './utilities';

const DEFAULT_COUNTRY = 'US';

export const phone_number: StringInputVariety = ({
  step_component,
  primaryValue,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  const Component = PhoneNumberInput;
  const additionalProps = {
    country: DEFAULT_COUNTRY,
    // defaultCountry: DEFAULT_COUNTRY,
    value: `${primaryValue}`,
    type: 'tel',
    displayInitialValueAsLocalNumber: true,
    onChange: (v: string) => {
      // If empty, set to null to explicitly unset the value on backend
      updateValue(
        step_component.field,
        correctCapitalization(step_component, v) ?? null,
      );
    },
    maxLength: 16,
  };
  return {
    Component,
    additionalProps,
    narrow: !!step_component.narrow,
  };
};
