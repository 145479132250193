import classNames from 'classnames';
import React from 'react';

interface IUnitInputProps {
  value?: number;
  subdivisions: {
    label: string;
    base_value: number;
  }[];
  onRawChange: (value: number | null) => void;
}

const UnitInput: React.FC<IUnitInputProps> = props => {
  let total = props.value || 0,
    values = props.subdivisions.map(subdivision => {
      let v = Math.floor(total / subdivision.base_value);
      total = Math.floor(total % subdivision.base_value);
      return v;
    });

  return (
    <div className="mt-6">
      {props.subdivisions.map((s, i) => (
        <UnitSubInput
          label={s.label}
          value={values[i]}
          className={i < props.subdivisions.length - 1 ? 'mr-4' : ''}
          onChange={v => {
            values[i] = v;
            props.onRawChange(
              values.reduce(
                (acc, v, i) => acc + v * props.subdivisions[i].base_value,
                0,
              ),
            );
          }}
        />
      ))}
    </div>
  );
};

export default UnitInput;

const UnitSubInput: React.FC<{
  label: string;
  value: number;
  className?: string;
  onChange: (v: number) => void;
}> = props => {
  return (
    <div className={classNames('inline-flex items-stretch', props.className)}>
      <input
        type="text"
        pattern="\d*"
        value={props.value ? props.value : ''}
        placeholder={'0'}
        onChange={e => {
          let v = e.target.value.replace(/[^\d]/g, '');
          if (v.length > 0) {
            props.onChange(+v);
          } else {
            props.onChange(0);
          }
        }}
        className="p-2 px-4 w-14 text-center focus:outline-none focus:z-10 focus:shadow-outline-blue border-cool-gray-300 border-solid border-2 rounded-l-lg"
      />
      <div className="border-cool-gray-300 bg-cool-gray-300 px-2 text-cool-gray-700 font-medium text-sm border-solid border-2 rounded-r-lg flex items-center">
        <span>{props.label}</span>
      </div>
    </div>
  );
};
