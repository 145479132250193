import './ProgressBar.css';

import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

interface ProgressBarProps {
  progress?: number;
  countdownMs?: number;
  className: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  progress,
  countdownMs,
  className,
}) => {
  const [mountedAt] = useState(Date.now());
  const [countdownProgress, setCountdownProgress] = useState(0);

  useEffect(() => {
    if (!countdownMs) return;

    const id = setInterval(() => {
      setCountdownProgress(Math.min((Date.now() - mountedAt) / countdownMs, 1));
    }, 250);
    return () => {
      clearInterval(id);
    };
  }, []);

  return (
    <div className={classNames('ProgressBar bg-cool-gray-300', className)}>
      <div
        role="progressbar"
        aria-valuemin={0}
        aria-valuemax={100}
        title="claim progress"
        aria-valuenow={Number(
          ((progress || countdownProgress || 0) * 100).toFixed(2),
        )}
        style={{
          width: `${((progress || countdownProgress) * 100).toFixed(2)}%`,
          minWidth: 10,
        }}
        className="h-full bg-cool-gray-600"
      />
    </div>
  );
};
export default ProgressBar;
