import classNames from 'classnames';

import { LOADING_MEDIA_CLASS_NAME } from '@assured/shared-types/Claim/Media';

import loader from '../images/loader.svg';

export const LoadingOverlay = () => {
  return (
    <div
      className="absolute rounded top-0 left-0 right-0 bottom-0 flex items-center justify-center"
      style={{
        backgroundColor: 'rgba(255,255,255,0.5)',
        zIndex: 2,
      }}
    >
      <img
        src={loader}
        className={classNames('inline-block', LOADING_MEDIA_CLASS_NAME)}
      />
    </div>
  );
};
