import classNames from 'classnames';
import { lazy, useEffect, useRef, useState } from 'react';

import DefaultAnimationIn from '../images/lottie-animations/Assured-Loader-In.json';
import DefaultAnimationLoop from '../images/lottie-animations/Assured-Loader-Loop.json';
import DefaultAnimationOut from '../images/lottie-animations/Assured-Loader-Out.json';
import PGRAnimationIn from '../images/lottie-animations/Loader-In.json';
import PGRAnimationLoop from '../images/lottie-animations/Loader-Loop.json';
import PGRAnimationOut from '../images/lottie-animations/Loader-Out.json';

import type { LottieRefCurrentProps } from 'lottie-react';

const Lottie = lazy(() => import('lottie-react'));

export function SubmitLoader({
  loading,
  onAnimationComplete,
  workflowType,
  hasAPIError,
  tenant,
}: {
  loading: boolean;
  onAnimationComplete: () => void;
  workflowType?: string;
  hasAPIError?: boolean;
  tenant?: string;
}) {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>();

  const isProgressive = tenant === 'progressive';
  const animationIn = isProgressive ? PGRAnimationIn : DefaultAnimationIn;
  const animationLoop = isProgressive ? PGRAnimationLoop : DefaultAnimationLoop;
  const animationOut = isProgressive ? PGRAnimationOut : DefaultAnimationOut;

  const copyMap = new Map<string, string | string[]>([
    [
      'in',
      workflowType && ['standard', 'home'].includes(workflowType)
        ? 'Submitting your claim'
        : 'Submitting your response',
    ],
    ['loop', ['Almost there', 'Hang tight', 'Working on it']],
    ['end', 'Done'],
  ]);

  const ANIMATION_IN_INDEX = 0;
  const ANIMATION_LOOP_INDEX = 1;
  const ANIMATION_OUT_INDEX = 2;
  const PAUSE_ON_LAST_ANIMATION_SCREEN_MS = 1000;

  const [loaderCopy, setLoaderCopy] = useState(copyMap?.get('in'));
  const [loopedTimes, setLoopedTimes] = useState<number>(0); // number of times AnimationLoop looped
  const [cyclingCopyIndex, setCyclingCopyIndex] = useState<number>(0); // current index of cycling copy
  const [shouldAnimate, setShouldAnimateText] = useState(true);
  const [animationCompleted, setAnimationCompleted] = useState(false);

  const [lastCompletedAnimationIndex, setLastCompletedAnimationIndex] =
    useState<number>(0);
  const [currentAnimationData, setCurrentAnimationData] =
    useState<any>(animationIn);
  const [shouldAnimationLoop, setShouldAnimationLoop] = useState(false);

  const lottieRef = useRef<LottieRefCurrentProps | null>(null);
  lottieRef.current?.setSpeed(0.7);

  useEffect(() => {
    if (hasAPIError) {
      // reset
      setLoaderCopy(copyMap?.get('in'));
      setLoopedTimes(0);
      setCyclingCopyIndex(0);
      setShouldAnimateText(false);
      setAnimationCompleted(false);
      setLastCompletedAnimationIndex(0);
      setCurrentAnimationData(animationIn);
      setShouldAnimationLoop(false);
    }
  }, [hasAPIError]);

  useEffect(() => {
    /* Handle animation completed */

    if (
      lastCompletedAnimationIndex === ANIMATION_OUT_INDEX &&
      animationCompleted
    ) {
      // last animation is complete, pause animation & call callback
      onAnimationComplete();
      lottieRef?.current?.pause();
    }
  }, [lastCompletedAnimationIndex, animationCompleted]);

  useEffect(() => {
    /* Handle loading success, show last animatino */
    if (
      loading ||
      hasAPIError ||
      lastCompletedAnimationIndex !== ANIMATION_LOOP_INDEX
    ) {
      return; // keep looping the `1` sequence until `loaded` is true
    }

    // advance looping animation sequence to end (`2`) sequence
    setLastCompletedAnimationIndex(ANIMATION_OUT_INDEX);
    setLoaderCopy(copyMap.get('end'));
    setCurrentAnimationData(animationOut);
    setShouldAnimationLoop(false);
  }, [loading, hasAPIError]);

  useEffect(() => {
    () => {
      if (timeoutRef?.current) {
        clearTimeout(timeoutRef?.current);
      }
    };
  });

  return (
    <>
      <Lottie
        lottieRef={lottieRef}
        animationData={currentAnimationData}
        loop={shouldAnimationLoop}
        autoplay
        onLoopComplete={() => {
          if (
            lastCompletedAnimationIndex > ANIMATION_OUT_INDEX ||
            hasAPIError
          ) {
            return;
          }

          if (
            lastCompletedAnimationIndex === ANIMATION_LOOP_INDEX &&
            loading === false
          ) {
            setLastCompletedAnimationIndex(ANIMATION_OUT_INDEX);
            setCurrentAnimationData(animationOut);
            setLoaderCopy(copyMap.get('end'));
            setShouldAnimationLoop(false);
            return;
          }

          const copyArr = copyMap.get('loop');
          if (!loaderCopy || !Array.isArray(copyArr)) return;

          setLoopedTimes(state => state + 1);

          if (loopedTimes % 2 === 0) {
            // cycle the copy every other time
            setShouldAnimateText(true);
            setCyclingCopyIndex(state => {
              const nextIndex = state + 1;
              // set back to 0 if out of bounds
              return nextIndex > copyArr?.length - 1 ? 0 : nextIndex;
            });

            setLoaderCopy(copyArr[cyclingCopyIndex]);
          } else {
            // tee up next text animation
            setShouldAnimateText(false);
          }
        }}
        onComplete={() => {
          if (hasAPIError) return;

          setShouldAnimateText(true);

          // set the state for the next animation sequence
          if (lastCompletedAnimationIndex === ANIMATION_IN_INDEX) {
            // completed the start animation

            setCurrentAnimationData(animationLoop);
            setShouldAnimationLoop(true);
          }

          if (
            lastCompletedAnimationIndex === ANIMATION_LOOP_INDEX &&
            loading === true
          ) {
            setCurrentAnimationData(animationLoop);
            setShouldAnimationLoop(true);
          }

          if (
            lastCompletedAnimationIndex === ANIMATION_LOOP_INDEX &&
            loading === false
          ) {
            setCurrentAnimationData(animationOut);
            setLoaderCopy(copyMap.get('end'));
            setLastCompletedAnimationIndex(ANIMATION_OUT_INDEX);
            return;
          }

          if (lastCompletedAnimationIndex === ANIMATION_OUT_INDEX) {
            if (!timeoutRef.current) {
              timeoutRef.current = setTimeout(() => {
                // keep √ done screen loaded for a moment before moving to next screen
                setAnimationCompleted(true);
              }, PAUSE_ON_LAST_ANIMATION_SCREEN_MS);
            }

            setShouldAnimationLoop(false);
            return;
          }

          // mark current index as completed
          const nextAnimationIndex = lastCompletedAnimationIndex + 1;
          setLastCompletedAnimationIndex(nextAnimationIndex);

          setShouldAnimateText(false); // set and reset to trigger animations
        }}
        alt="Loading"
        style={{
          height: 300,
        }}
      />
      <div className="-translate-y-[50px]">
        <div
          data-id={loaderCopy}
          className={classNames('bg-white text-cool-gray-500', {
            'animate-slideup':
              shouldAnimate &&
              lastCompletedAnimationIndex === ANIMATION_LOOP_INDEX,
          })}
        >
          {loaderCopy}
        </div>
      </div>
    </>
  );
}
