import acl_meniscus from '../../../images/bodily_injury/acl_meniscus.svg';
import amputation from '../../../images/bodily_injury/amputation.svg';
import amputation_toe from '../../../images/bodily_injury/amputation_toe.svg';
import back_pinched_nerve from '../../../images/bodily_injury/back_pinched_nerve.svg';
import back_sprain from '../../../images/bodily_injury/back_sprain.svg';
import broken_bone from '../../../images/bodily_injury/broken_bone.svg';
import broken_collarbone from '../../../images/bodily_injury/broken_collarbone.svg';
import broken_wrist from '../../../images/bodily_injury/broken_wrist.svg';
import bruised_bone from '../../../images/bodily_injury/bruised_bone.svg';
import concussion from '../../../images/bodily_injury/concussion.svg';
import crushed_hand from '../../../images/bodily_injury/crushed_hand.svg';
import crushed_lower_leg from '../../../images/bodily_injury/crushed_lower_leg.svg';
import crushed_upper_leg from '../../../images/bodily_injury/crushed_upper_leg.svg';
import cut_scrape from '../../../images/bodily_injury/cut_scrape.svg';
import dislocated from '../../../images/bodily_injury/dislocated.svg';
import genital_damage from '../../../images/bodily_injury/genital_damage.svg';
import headache from '../../../images/bodily_injury/headache.svg';
import hearing_loss from '../../../images/bodily_injury/hearing_loss.svg';
import hernia from '../../../images/bodily_injury/hernia.svg';
import herniated_disk from '../../../images/bodily_injury/herniated_disk.svg';
import hyperextension from '../../../images/bodily_injury/hyperextension.svg';
import muscle_soreness_arm from '../../../images/bodily_injury/muscle-soreness-arm.svg';
import muscle_soreness_back from '../../../images/bodily_injury/muscle-soreness-back.svg';
import muscle_soreness_chest from '../../../images/bodily_injury/muscle-soreness-chest.svg';
import muscle_soreness_lower_leg from '../../../images/bodily_injury/muscle-soreness-lower-leg.svg';
import muscle_soreness_torso from '../../../images/bodily_injury/muscle-soreness-torso.svg';
import muscle_soreness_upper_leg from '../../../images/bodily_injury/muscle-soreness-upper-leg.svg';
import neck_pain from '../../../images/bodily_injury/neck_pain.svg';
import puncture from '../../../images/bodily_injury/puncture.svg';
import soreness from '../../../images/bodily_injury/soreness.svg';
import sprain_strain from '../../../images/bodily_injury/sprain_strain.svg';
import sprain_strain_lower from '../../../images/bodily_injury/sprain_strain_lower.svg';
import sprain_strain_upper from '../../../images/bodily_injury/sprain_strain_upper.svg';
import vertebrae_fracture from '../../../images/bodily_injury/vertebrae_fracture.svg';
import vision_loss from '../../../images/bodily_injury/vision_loss.svg';
import whiplash from '../../../images/bodily_injury/whiplash.svg';

const icons: { [k: string]: string } = {
  acl_meniscus,
  amputation_finger: amputation,
  amputation_foot: amputation,
  amputation_toe,
  amputation,
  back_nerve_pinch: back_pinched_nerve,
  back_sprain,
  broken_ankle: broken_bone,
  broken_bone,
  broken_collarbone,
  broken_finger: broken_bone,
  broken_rib: broken_bone,
  broken_toe: broken_bone,
  broken_wrist,
  bruised_bone,
  bruised_rib: bruised_bone,
  concussion,
  crushed_hand,
  crushed_lower_leg,
  crushed_upper_leg,
  cut_scrape,
  dislocated_hip: dislocated,
  dislocated_knee: dislocated,
  dislocated,
  genital_damage,
  headache,
  hearing_loss,
  hernia,
  herniated_disk,
  hyperextension_finger: hyperextension,
  hyperextension,
  muscle_soreness_arm,
  muscle_soreness_back,
  muscle_soreness_chest,
  muscle_soreness_lower_leg,
  muscle_soreness_torso,
  muscle_soreness_upper_leg,
  neck_pain,
  puncture,
  soreness,
  sprain_strain_lower,
  sprain_strain_upper,
  sprain_strain,
  sprain_wrist: sprain_strain,
  vertebrae_fracture,
  vision_loss,
  whiplash,
  default: soreness,
};

export default icons;
