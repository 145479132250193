import isChromatic from 'chromatic/isChromatic';
import classNames from 'classnames';
import React, { useState } from 'react';

import LocationKnob from './Location/LocationKnob';

import type {
  StepComponentFC,
  StepComponentSharedProps,
  LocationDirectionStepComponentSpec,
} from '@assured/step-renderer';

type LocationDirectionProps = StepComponentSharedProps<
  LocationDirectionStepComponentSpec,
  number | null
>;

const LocationDirection: StepComponentFC<LocationDirectionProps> = ({
  step_component,
  updateValue,
  className,
}) => {
  const [value, setValue] = useState(step_component.existing_value || 0);
  return (
    <div className={className}>
      {/**
       * For some reason, rendering this component in Chromatic causes their screenshot
       * renderer to crash. Hopefully this is a temporary issue, but for now we'll just
       * skip rendering this component in Chromatic.
       */}
      {isChromatic() ? null : (
        <LocationKnob
          center={step_component.location_center}
          highFidelity={step_component.high_fidelity}
          vehicleColor={step_component.vehicle_color}
          value={value}
          onValueChange={setValue}
          interactive={true}
        />
      )}
      <div className="mt-6 md:flex justify-center">
        <button
          onClick={() => updateValue(step_component.field, value)}
          className={classNames('btn btn-blue')}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default LocationDirection;
