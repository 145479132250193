import { useMemo } from 'react';
import { StepComponentSharedProps } from '~/types';
import { AnyStepComponentSpec } from '~/types/step-components';

export const useDisabledWhen = ({
  step_component,
  allValues,
}: Pick<
  StepComponentSharedProps<AnyStepComponentSpec, unknown>,
  'step_component' | 'allValues'
>) => {
  return useMemo(
    () =>
      step_component.disabled_when
        ? Object.entries(step_component.disabled_when).some(
            ([peerField, peerValue]) => allValues?.[peerField] === peerValue,
          )
        : false,
    [allValues, step_component.disabled_when],
  );
};
