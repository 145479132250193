import classNames from 'classnames';
import React from 'react';
import reactStringReplace from 'react-string-replace';

import { notEmpty } from '@assured/shared-types/helpers';
import { InlineComponentType } from '@assured/step-renderer';
import { CustomString } from '@assured/step-renderer/types/step-components/additional';

import Heading from '../Heading';
import InjectInline from './InjectInline';

export const formatHighlightedTerms = (text: string) => {
  text = /OTHER/.test(text) ? text.replace(/OTHER/, '').trim() : text;

  return reactStringReplace(text, /\[\[(.*?)\]\]/g, (match, i) => {
    // If the text is long, we'll allow it to wrap on small viewports. If it's super long, we'll
    // wrap it everywhere. Otherwise/by default, we'll block wrapping everywhere. Note: This
    // could be made more dynamic, but this is a hacky solution for now.
    const isSuperLong = match.length > 40;
    const isLong = match.length > 25;

    return (
      <span
        key={i}
        className={classNames(
          'rounded border border-cool-gray-600 px-1 mx-1 shadow font-semibold',
          isSuperLong
            ? ''
            : isLong
            ? 'sm:whitespace-nowrap'
            : 'whitespace-nowrap',
        )}
      >
        {match}
      </span>
    );
  });
};

type Injector = {
  type: InlineComponentType;
  value: string;
};

interface TitleProps {
  title?: CustomString | CustomString[];
  subtitle?: CustomString | CustomString[];
  titleClassName?: string;
  subtitleClassName?: string;
  inline?: boolean;
  dark?: boolean;
  injectors?: Record<string, Injector>;
  textReplacer?: (text: string) => string;
}

const Title: React.FC<TitleProps> = ({
  title,
  subtitle,
  titleClassName,
  subtitleClassName,
  inline,
  dark,
  injectors,
  textReplacer,
}) => {
  const renderComponent = ({
    def,
    Component,
    className,
    customClassNames,
    injectors = {},
    id,
  }: {
    def: CustomString | CustomString[];
    Component: React.ElementType;
    className: string;
    customClassNames: Record<string, string> & { default: string };
    injectors?: Record<string, Injector>;
    id?: string;
  }) =>
    def
      ? (Array.isArray(def) ? def : [def])
          .filter(t => !!t)
          .map(t => {
            let text: string;
            let rest: { [key: string]: any };

            if (!t) {
              return null;
            }
            if (typeof t === 'string') {
              text = t;
              rest = {};
            } else {
              text = t.text;
              rest = { ...t, text: undefined };
            }

            if (textReplacer && text) {
              text = textReplacer(text);
            }

            let outputContent = formatHighlightedTerms(text);

            outputContent = reactStringReplace(
              outputContent,
              /(\[.*?\]\(.*?\))/g,
              (match, i) => {
                const res = /\[(.*?)\]\((.*?)\)/.exec(match);
                return (
                  res && (
                    <a
                      key={`link-${i}`}
                      className="underline hover:text-gray-800"
                      href={res[2]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {res[1]}
                    </a>
                  )
                );
              },
            );

            const selectedCustomClassNames = Object.keys(rest)
              .map(r => (!!rest[r] ? customClassNames[r] : undefined))
              .filter(notEmpty);

            return (
              <Component
                key={text}
                id={id}
                className={classNames(
                  className,
                  titleClassName,
                  'mb-2',
                  selectedCustomClassNames?.length
                    ? selectedCustomClassNames
                    : customClassNames.default,
                )}
              >
                <InjectInline nodes={outputContent} injectors={injectors} />
              </Component>
            );
          })
      : null;

  return (
    <>
      {title &&
        renderComponent({
          def: title,
          Component: Heading,
          className: dark ? 'text-white' : 'text-cool-gray-600',
          customClassNames: {
            bold: 'font-medium text-lg',
            big: 'text-5xl',
            small: 'text-base',
            heading: 'text-3xl font-bold leading-8 mb-3',
            default: 'text-lg',
          },
          injectors,
          id: 'page-title',
        })}
      {subtitle &&
        renderComponent({
          def: subtitle,
          Component: Heading,
          className: classNames(
            `mt-2`,
            dark ? 'text-white' : 'text-cool-gray-600',
            subtitleClassName,
          ),
          customClassNames: {
            bold: 'font-medium text-md',
            big: 'text-3xl -mt-1',
            small: 'text-sm',
            monospace: 'font-mono',
            default: 'text-md',
          },
          injectors,
          id: 'page-subtitle',
        })}
    </>
  );
};

export default Title;
