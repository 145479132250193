import { VehicleSeatmapFields } from './VehicleSeatmap';

export enum YourInvolvementVisualizationType {
  SEATMAP = 'seatmap', // vehicle seatmap with known seat
  UNKNOWN_SEAT = 'unknownSeat', // vehicle, but seat is unknown. Car with "?" graphic
  UNINVOLVED_OWNER = 'uninvolvedOwner', // vehicle, but reporting owner was not an occupant
  UNINVOLVED_NON_OWNER = 'uninvolvedNonOwner', // vehicle, but reporting non-owner was not an occupant
  PEDESTRIAN = 'pedestrian',
  BICYCLIST = 'bicyclist',
  WITNESS = 'witness',
  NOT_INVOLVED = 'notInvolved',
}

export interface YourInvolvementVisualizationData
  extends Partial<VehicleSeatmapFields> {
  label_color?: string | null;
  label_text?: string | null;
  seat_label?: string | null;
}
