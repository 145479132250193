import classNames from 'classnames';
import { DetailedHTMLProps, ReactNode } from 'react';

interface ButtonPropsBase {
  /** Custom className */
  className?: string;

  /** Inner content */
  children?: ReactNode | ReactNode[];

  /** Custom className for the text content of the button */
  classNameText?: string;

  /** Custom className for the inner <span> that wraps the content */
  innerClassName?: string;

  /** Background color */
  colorBackground?:
    | 'blue-bright-600'
    | 'blue-gradient'
    | 'cool-gray-100'
    | 'cool-gray-400'
    | 'cool-gray-700'
    | 'indigo-bright-50'
    | 'indigo-bright-600'
    | 'white';

  /** Border color */
  colorBorder?: 'cool-gray-300' | 'cool-gray-500' | 'transparent';

  /** Text color */
  colorText?:
    | 'cool-gray-50'
    | 'cool-gray-100'
    | 'cool-gray-600'
    | 'cool-gray-900'
    | 'cool-gray-faded'
    | 'green-warm-600'
    | 'red-warm-600';

  /** An id to be rendered as a `data-testid` attribute */
  dataTestId?: string;

  /** If `true` will center text */
  isCentered?: boolean;

  /** If `true` border-radius will be more than the default */
  hasLargeBorderRadius?: boolean;

  /** If `true` font weight will be lighter the default */
  hasLighterFont?: boolean;

  /** A leading icon (or any React Node really) to render as a text prefix */
  leadingIcon?: ReactNode;

  /** A leading icon (or any React Node really) to render as a text prefix. "Floats" above z-layer */
  leadingIconFloating?: ReactNode;

  /** A click handler */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  /** Handler called when button is clicked while disabled */
  onDisabledClick?: React.MouseEventHandler<HTMLButtonElement>;

  /** If `true` will have equal padding x and y */
  hasEqualPadding?: boolean;

  /** If `true` will larger than default padding */
  hasLargePadding?: boolean;

  /** Size variation of the pill to style font-size and padding */
  size?: 'xs' | 'sm' | 'md' | 'lg';

  /** Trailing content to render on the first row */
  trailingContent?: ReactNode;

  /**
   * A group of style choices represented by one value. `default` means
   * that no major style choices are made to be used when more significant
   * customization is needed
   * */
  variant?: 'default' | 'primary' | 'secondary';

  /** An optional second row of content inside the button */
  secondRow?: ReactNode;
}

type HtmlButtonProps = DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export type ButtonProps = HtmlButtonProps & ButtonPropsBase;

export const Button = ({
  children,
  className,
  innerClassName,
  classNameText,
  colorBackground,
  colorBorder,
  colorText,
  dataTestId = 'button',
  disabled,
  hasEqualPadding,
  hasLargeBorderRadius,
  hasLargePadding,
  hasLighterFont,
  isCentered,
  leadingIcon,
  leadingIconFloating,
  onClick,
  onDisabledClick,
  size = 'md',
  trailingContent,
  type = 'button',
  variant = 'primary',
  secondRow,
  ...rest
}: ButtonProps) => {
  // the `default` variant is intended to be used as a blank slate
  const isDefault = variant === 'default';

  const isPrimary = variant === 'primary' && !disabled;
  const isPrimaryDisabled = variant === 'primary' && !!disabled;
  const isSecondary = variant === 'secondary' && !disabled;
  const isSecondaryDisabled = variant === 'secondary' && !!disabled;

  const handleDisabledEvent: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    event.preventDefault();
    onDisabledClick?.(event);
  };
  return (
    <button
      className={classNames(
        // the idea here is to keep the base classes minimal so that
        // we can always override with a custom `className`
        {
          // background
          'bg-blue-bright-600': colorBackground === 'blue-bright-600',
          'bg-cool-gray-100':
            colorBackground === 'cool-gray-100' || isSecondaryDisabled,
          'bg-cool-gray-400':
            colorBackground === 'cool-gray-400' || isPrimaryDisabled,
          'bg-cool-gray-700': colorBackground === 'cool-gray-700',
          'bg-gradient-98-degrees from-[#2480fe_5.51%] to-[#1E40AF_121.39%]':
            colorBackground === 'blue-gradient',
          'bg-indigo-bright-600 hover:bg-indigo-bright-700':
            colorBackground === 'indigo-bright-600' || isPrimary,
          'bg-indigo-bright-50': colorBackground === 'indigo-bright-50',
          'bg-white hover:bg-indigo-bright-50':
            colorBackground === 'white' || isSecondary,

          // borders
          border: !!colorBorder || isSecondary || isSecondaryDisabled,
          'border-cool-gray-300':
            colorBorder === 'cool-gray-300' || isSecondary,
          'border-transparent': colorBorder === 'transparent',
          'border-cool-gray-500': colorBorder === 'cool-gray-500',
          'border-cool-gray-400': isPrimaryDisabled,

          // border-radius
          'rounded-md': !hasLargeBorderRadius,
          'rounded-2xl': hasLargeBorderRadius,

          // color
          'text-cool-gray-100': colorText === 'cool-gray-100',
          'text-green-warm-600': colorText === 'green-warm-600',
          'text-red-warm-600': colorText === 'red-warm-600',
          'text-cool-gray-50':
            colorText === 'cool-gray-50' || !isSecondaryDisabled,
          'text-[#A8B9D1]':
            colorText === 'cool-gray-faded' || isSecondaryDisabled,
          'text-cool-gray-600': colorText === 'cool-gray-600',
          'text-cool-gray-800':
            isSecondary && !isSecondaryDisabled && !colorText,
          'text-cool-gray-900': colorText === 'cool-gray-900',

          // font-size
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-lg': size === 'md',
          'text-xl': size === 'lg',

          // font-weight
          'font-normal': hasLighterFont,
          'font-medium': size === 'md' || size === 'sm',
          'font-semibold': size === 'lg',

          // line-height
          'leading-[21.6px]': size === 'md',
          'cursor-not-allowed': disabled,
        },
        className,
      )}
      tabIndex={0}
      data-testid={dataTestId}
      aria-disabled={disabled}
      disabled={disabled && !onDisabledClick ? true : undefined}
      onClick={event =>
        disabled ? handleDisabledEvent(event) : onClick?.(event)
      }
      type={type}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <span
        className={classNames(
          'flex items-center',
          {
            // height
            'h-10': size === 'sm',

            // padding
            'px-2.5 py-1.5 leading-none': size === 'xs',
            'px-6 py-4': !hasEqualPadding && size !== 'xs',
            'p-4': hasEqualPadding && !hasLargePadding,
            'px-5 py-4': hasEqualPadding && hasLargePadding,

            // text layout
            'justify-center': !isDefault || isCentered,
          },
          innerClassName,
        )}
      >
        {leadingIcon && (
          <span
            className={classNames('inline-flex items-center', {
              'mr-2.5': !hasLargePadding,
              'mr-5': hasLargePadding,
            })}
          >
            {leadingIcon}
          </span>
        )}
        {leadingIconFloating && (
          <span className="left-2 absolute">{leadingIconFloating}</span>
        )}
        <span className={classNameText}>{children}</span>
        {trailingContent && <span className="ml-2.5">{trailingContent}</span>}
      </span>
      {secondRow ?? null}
    </button>
  );
};
