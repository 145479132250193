import classNames from 'classnames';
import { useEffect, useState } from 'react';

import { conicGradient } from '@assured/design-system/src/lib/conicGradient';
import {
  StepComponentControlsBackHookProps,
  StepComponentControlsTitleProps,
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer/types';

import { useIntermediateValues } from '../../hooks';
import { dataTestId } from '../../utilities/dataTestId';
import Icon from '../Icon';
import Overdrive from '../Overdrive';
import Select from './Select';
import Title from './Title';

import type { BuilderStepComponentSpec } from '@assured/step-renderer';
const CHILD_COMPONENTS = {
  select: Select,
};

const makeApplicableSubcomponents = (
  step_component: BuilderStepComponentSpec,
  obj: Record<string, any>,
) => {
  return step_component.subcomponents.filter(c => {
    if (!c.filter) {
      return true;
    }

    let filterObj: Record<string, any> = c.filter;
    return Object.keys(filterObj).every(k => {
      if (Array.isArray(filterObj[k])) {
        return filterObj[k].includes(obj[k]);
      }
      return obj[k] === filterObj[k];
    });
  });
};

const makeCompletedSubcomponents = (
  applicableSubcomponents: BuilderStepComponentSpec['subcomponents'],
  obj: Record<string, any>,
) => {
  const firstUnansweredIndex = applicableSubcomponents.findIndex((c, i) => {
    applicableSubcomponents.findIndex(c => c.allow_other === false);

    const matchedOtherComponent =
      c.allow_other &&
      applicableSubcomponents.find(
        cc => cc.allow_other === false && cc.field === c.field,
      );

    return (
      !!matchedOtherComponent || !c.field || typeof obj[c.field] === 'undefined'
    );
  });

  if (firstUnansweredIndex === -1) {
    return applicableSubcomponents;
  }
  return applicableSubcomponents.slice(0, firstUnansweredIndex);
};

type BuilderProps = StepComponentSharedProps<
  BuilderStepComponentSpec,
  object[] | null
> &
  StepComponentControlsBackHookProps &
  StepComponentControlsTitleProps &
  Record<string, any>;

const Builder: StepComponentFC<BuilderProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  titleClassName,
  registerBackHook,
  removeBackHook,
  ...rest
}) => {
  const { obj, setObj, submit, hasSubmitted } = useIntermediateValues({
    step_component,
    updateValue,
    initialObj: step_component.initial_value,
    postProcess: o => {
      if (!tempValues && currentComponentDef?.field === 'color') {
        return {
          ...o,
          color: null,
          otherColor: null,
        };
      }

      /** set otherColor to null when color is a hex*/
      if (o?.color?.startsWith?.('#')) {
        return {
          otherColor: null,
          ...o,
        };
      }

      return o;
    },
  });

  const [tempValues, setTempValues] = useState<Record<string, string> | null>();

  // Apply filters
  const applicableSubcomponents = makeApplicableSubcomponents(
    step_component,
    obj,
  );

  /**
   * Instantly submit if single-entry field and no optionality to collect a related 'other' value,
   * or if the value selected is not the 'other' option.
   */
  const shouldAutoSubmit =
    applicableSubcomponents.length === 1 &&
    (!applicableSubcomponents[0]?.other_field ||
      (applicableSubcomponents[0].field &&
        tempValues &&
        tempValues[applicableSubcomponents[0].field] &&
        tempValues[applicableSubcomponents[0].field] !==
          applicableSubcomponents[0].other_option)) &&
    applicableSubcomponents[0].field &&
    typeof obj[applicableSubcomponents[0].field] !== 'undefined';

  const currentComponentDef = shouldAutoSubmit
    ? applicableSubcomponents[0]
    : applicableSubcomponents.find(c => {
        return c.field && typeof obj[c.field] === 'undefined';
      });

  const SelectComponent = currentComponentDef
    ? CHILD_COMPONENTS[
        currentComponentDef.type as keyof typeof CHILD_COMPONENTS
      ]
    : null;

  let RenderedComponent =
    currentComponentDef && SelectComponent ? (
      <div
        key={currentComponentDef.field}
        className="ClaimWorkflowInnerBuilder"
      >
        {currentComponentDef?.other_label && (
          <div className="text-md my-2 text-cool-gray-600">
            {currentComponentDef?.other_label}
          </div>
        )}
        <SelectComponent
          step_component={currentComponentDef}
          primaryValue={
            currentComponentDef.field ? obj[currentComponentDef.field] : null
          }
          updateValue={(k, v) => k && setObj({ ...obj, [k]: v })}
          setTempValues={setTempValues}
          overdriveField={`${step_component.id}-${currentComponentDef.field}`}
          error={''}
          showErrorMessages={false}
          {...rest}
          sourceValue={
            'source_value' in currentComponentDef
              ? currentComponentDef.source_value
              : currentComponentDef.source_field
              ? obj[currentComponentDef.source_field]
              : undefined
          }
        />
      </div>
    ) : null;

  const completedSubcomponents = shouldAutoSubmit
    ? []
    : makeCompletedSubcomponents(applicableSubcomponents, obj);

  // Buttons at the top
  const builderButtons = completedSubcomponents
    .filter(c => {
      // filter out color='other', let otherColor='other' pass thru
      return !(c?.field === 'color' && obj[c?.field] === 'OTHER');
    })
    .map((c, i) => {
      let inner;
      if (c.type === 'select') {
        if (c.mode === 'color' || c.field === 'otherColor') {
          const srColorLabel = c?.options?.find?.(
            o => o?.value === obj?.color,
          )?.label;

          inner = (
            <div
              className="rounded-full border-2 border-cool-gray-300 bg-white"
              style={{
                width: 30,
                height: 30,
                backgroundColor: c.field && obj[c.field],
                backgroundImage:
                  c.field && obj[c.field] === 'OTHER' ? conicGradient : '',
              }}
            >
              <span className="sr-only">{srColorLabel}</span>
            </div>
          );
        } else if (c.options) {
          const opt = c.options.find(o => c.field && o.value === obj[c.field]);
          if (opt && (opt.icon_text || opt.icon)) {
            inner = opt.icon_text || <Icon icon={opt.icon} small />;
          }
        }
      }

      if (!inner) {
        inner = c.field && obj[c.field];
      }

      const valueId = c.mode === 'dropdown' ? c.mode : c.field && obj[c.field];

      return (
        <Overdrive
          id={`${step_component.id}-${c.field}-${valueId}`}
          key={c.field}
          duration={400}
        >
          <button
            className={`select-btn p-2 px-4 text-cool-gray-600 text-${
              inner?.length > 8 ? 'xl' : '2xl'
            }`}
            style={{ minHeight: 60, minWidth: 60 }}
            onClick={() => {
              let newObj = { ...obj };
              applicableSubcomponents
                .slice(i)
                .map(c => c.field)
                .forEach(f => {
                  f && delete newObj[f];
                });
              setObj(newObj);
            }}
          >
            <span data-testid={dataTestId(`${c.field && obj[c.field]}`)}>
              {inner}
            </span>
          </button>
        </Overdrive>
      );
    });

  useEffect(() => {
    if (!hasSubmitted && shouldAutoSubmit) {
      submit();
    }
  }, [shouldAutoSubmit, obj]);

  useEffect(() => {
    const backHook = () => {
      const applicableSubcomponents = makeApplicableSubcomponents(
        step_component,
        obj,
      );
      const completed = makeCompletedSubcomponents(
        applicableSubcomponents,
        obj,
      );

      if (completed.length) {
        const k = completed[completed.length - 1].field;
        k &&
          setObj({
            ...obj,
            [k]: undefined,
          });
        return true;
      }
      return false;
    };
    registerBackHook(backHook);
    return () => removeBackHook(backHook);
  }, [obj]);

  return (
    <div className={className}>
      <Title
        title={
          currentComponentDef?.title ||
          (builderButtons.length && step_component.intermediate_title) ||
          step_component.title
        }
        titleClassName={titleClassName}
      />
      <div className="mt-4">
        {builderButtons.length ? (
          <div className="flex justify-center items-center flex-wrap -mx-6">
            {builderButtons}
          </div>
        ) : null}

        {RenderedComponent ? (
          RenderedComponent
        ) : (
          <button
            data-testid="primaryAction"
            className="mt-4 btn btn-blue inline-block"
            onClick={submit}
          >
            {step_component.builder_submit_label || 'Continue'}
          </button>
        )}

        <div className="pt-6 empty:hidden flex flex-wrap justify-center">
          {currentComponentDef?.use_continue_button ? (
            <>
              <button
                className={classNames(
                  'btn btn-blue inline-block mb-4 mt-0 sm:order-last',
                  {
                    'opacity-50 cursor-not-allowed': !tempValues,
                  },
                )}
                onClick={() => {
                  if (!tempValues) return;

                  setObj({
                    ...obj,
                    ...tempValues,
                  });
                }}
                data-testid="secondaryAction"
              >
                {step_component.builder_submit_label}
              </button>
            </>
          ) : null}

          {RenderedComponent && step_component.builder_skip_label ? (
            <button
              className={classNames(
                'btn btn-subtle inline-block mt-0 sm:order-first',
                {
                  'mb-3.5':
                    RenderedComponent && step_component.builder_submit_label,
                },
              )}
              onClick={submit}
              data-testid="secondaryAction"
            >
              {step_component.builder_skip_label}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

Builder.stepConfig = {
  controlsTitle: true,
};

export default Builder;
