import React from 'react';
import MaskedInput from 'react-text-mask';

import Toggle from '../../Toggle';
import {
  booleanToFeetOrYards,
  isYards,
  nearestValueToNewStepSize,
  units,
} from './helpers';
import IncrementButton from './IncrementButton';

interface IncrementableSmallNumberProps {
  value: number;
  minimum: number;
  maximum: number;
  onRawChange: (v: number | string) => void;
  mask: any;
  [k: string]: any;
  mode?: 'mph_number' | 'distance_number';
  initialStepSize?: number;
  initialUnits?: units;
}

const IncrementableSmallNumber: React.FC<IncrementableSmallNumberProps> = ({
  value,
  minimum,
  maximum,
  onRawChange,
  initialStepSize,
  mode,
  initialUnits,
  ...rest
}) => {
  const [preferredUnits, setPreferredUnits] = React.useState<units>(
    initialUnits || 'feet',
  );
  const [stepSize, setStepSize] = React.useState<number>(initialStepSize || 1);

  React.useEffect(() => {
    if (initialUnits) {
      setPreferredUnits(initialUnits);
    }

    if (initialStepSize) {
      setStepSize(initialStepSize);
    } else if (mode === 'distance_number' || mode === 'mph_number') {
      setStepSize(initialUnits === 'yards' ? 10 : 5);
    }
  }, [mode, initialUnits, initialStepSize]);

  // Toggle is only shown when mode is 'distance_number'
  // so this function will only be evaluated for that mode.
  const defaultOnToggleChange = (v: boolean) => {
    const previousUnits = preferredUnits;
    const newUnits = booleanToFeetOrYards(v);
    setPreferredUnits(booleanToFeetOrYards(v));
    setStepSize(newUnits === 'yards' ? 10 : 5);
    const adjustedValue = nearestValueToNewStepSize(
      value,
      previousUnits,
      booleanToFeetOrYards(v),
    );
    const externalValue = `${adjustedValue} ${newUnits}`;
    onRawChange(externalValue);
  };

  const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };
  return (
    <>
      <div className="flex items-center justify-center">
        <IncrementButton
          direction="negative"
          stepSize={stepSize}
          value={value}
          updateValue={v =>
            onRawChange(getValueForMode(v, preferredUnits, mode))
          }
          minimum={minimum}
          maximum={maximum}
          disabled={value === undefined || value <= minimum}
        />
        <MaskedInput value={value} {...rest} onFocus={focusHandler} />
        {mode === 'mph_number' ? (
          <div className="text-xl text-cool-gray-500 ml-2">MPH</div>
        ) : null}
        <IncrementButton
          direction="positive"
          stepSize={stepSize}
          value={value}
          updateValue={v =>
            onRawChange(getValueForMode(v, preferredUnits, mode))
          }
          minimum={minimum}
          maximum={maximum}
          disabled={value >= maximum}
        />
      </div>
      {mode === 'distance_number' ? (
        <div className="m-8">
          <Toggle
            options={[
              { label: 'Feet', value: false },
              { label: 'Yards', value: true },
            ]}
            value={isYards(preferredUnits)}
            onChange={defaultOnToggleChange}
            extraXPadding
          />
        </div>
      ) : null}
    </>
  );
};

export default IncrementableSmallNumber;

/**
 * When this component is in 'distance_number' mode, the value it maintains is a
 * string: "123 feet" or "123 yards" as a way of saving the user-selected units.
 * In other modes, the value is the simple number. This helper function gets the
 * proper value to pass to the onRawChange callback based on the mode.
 * @param value Numeric value
 * @param units Currently-selected units - only used in 'distance_number' mode
 * @param mode The current mode of the component (can be undefined)
 * @returns
 */
function getValueForMode(
  value: number,
  selectedUnits: units,
  mode?: 'mph_number' | 'distance_number',
): string | number {
  if (mode === 'distance_number') {
    return `${value} ${selectedUnits}`;
  }
  return value;
}
