// import './Datepicker.css';
import './react-datepicker.css';

import classNames from 'classnames';
import React, { useRef, useState } from 'react';
import DatePicker from 'react-datepicker';

import { useAccessiblePopover } from '../../hooks/useAccessiblePopover';

interface DatepickerProps {
  value?: Date | null;
  onChange: (d?: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  showTimeSelect?: boolean;
  placeholderText?: string;
  ariaLabelledBy?: string;
  inline?: boolean;
  wide?: boolean;
  id?: string;
}

const Datepicker: React.FC<
  DatepickerProps & ({ ariaLabelledBy: string } | { ariaLabel: string })
> = ({
  value,
  onChange,
  minDate,
  maxDate,
  showTimeSelect,
  placeholderText,
  inline,
  wide,
  ariaLabelledBy = 'date-picker-label',
  id,
}) => {
  const datePickerRef = useRef<any>(null);

  const [calendarPopoverIsOpen, setCalendarPopoverIsOpen] = useState(false);

  useAccessiblePopover({
    isOpen: calendarPopoverIsOpen,
    ref: datePickerRef,
    ariaControls: `${id}-date-popover`,
  });

  return (
    <DatePicker
      id={id && `${id}-trigger`}
      portalId={id && `${id}-date-popover`}
      onCalendarOpen={() => {
        setCalendarPopoverIsOpen(true);
      }}
      onCalendarClose={() => {
        setCalendarPopoverIsOpen(false);
      }}
      ref={datePickerRef}
      popperClassName="react-datepicker-popper--ui"
      wrapperClassName={classNames('react-datepicker-wrapper--ui', {
        'react-datepicker-wrapper--wide': wide,
        'react-datepicker-wrapper--narrow': !wide,
      })}
      selected={value}
      ariaLabelledBy={ariaLabelledBy}
      placeholderText={placeholderText || 'Enter a date...'}
      onChange={date => onChange(date as Date | null)}
      peekNextMonth
      showMonthDropdown
      showYearDropdown
      popperPlacement="bottom"
      dropdownMode="select"
      dateFormat={`MMMM d, yyyy${showTimeSelect ? ' h:mm a' : ''}`}
      showTimeSelect={showTimeSelect}
      minDate={minDate}
      maxDate={maxDate}
      inline={inline}
      popperModifiers={[
        {
          name: 'preventOverflow',
          options: {
            rootBoundary: 'viewport',
            tether: true,
            altAxis: true,
          },
        },
      ]}
    />
  );
};

export default Datepicker;
