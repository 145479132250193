import type {
  ReconfirmBannerStepComponentSpec,
  StepComponentSharedProps,
} from '@assured/step-renderer';
import classNames from 'classnames';

type ReconfirmBannerProps = StepComponentSharedProps<
  ReconfirmBannerStepComponentSpec,
  string | null
>;
const ReconfirmBanner = ({
  step_component,
  className,
}: ReconfirmBannerProps) => {
  return (
    <div className={classNames('-mb-8 h-12 sm:h-8', className)}>
      <div className="absolute top-0 left-0 right-0 rounded-t border-b border-blue-200 h-16 sm:h-12 px-4 flex items-center justify-center bg-blue-50">
        <span className="text-blue-600 text-xl sm:text-lg font-medium">
          Reconfirming
        </span>
      </div>
    </div>
  );
};
export default ReconfirmBanner;
