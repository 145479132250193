import { useHealthSearchNlm } from './useHealthSearchNlm';
import { SetIsLoading, useHealthSearchRibbon } from './useHealthSearchRibbon';

/**
 * A custom hook to determine which search functionality to use
 */
export function useHealthSearch({
  isLoading,
  searchTerm,
  setIsLoading,
  mode,
  is_ribbon,
  filing_location,
  showMoreResults,
}: {
  isLoading: boolean;
  searchTerm: string;
  setIsLoading: SetIsLoading;
  mode: 'npi_individual' | 'npi_organization' | 'rxterm' | 'npi_hospital';
  is_ribbon?: boolean;
  filing_location?: {
    latitude: number | null;
    longitude: number | null;
  };
  showMoreResults?: boolean;
}) {
  const isRibbon = mode !== 'rxterm' && is_ribbon;
  const healthSearchNlm = useHealthSearchNlm({
    mode,
    searchTerm,
    skip: isRibbon,
  });
  const healthSearchRibbon = useHealthSearchRibbon({
    isLoading,
    location:
      !filing_location?.latitude || !filing_location?.longitude
        ? undefined
        : `${filing_location.latitude},${filing_location.longitude}`,
    mode,
    searchTerm,
    setIsLoading,
    skip: !isRibbon,
    showMoreResults,
  });

  return !isRibbon ? healthSearchNlm : healthSearchRibbon;
}
