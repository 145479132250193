import classNames from 'classnames';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';

import {
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer/types';

import Modal from '../Modal';
import String from './String';

import type { MultipartyFollowupSummaryStepComponentSpec } from '@assured/step-renderer';
interface MultipartyFollowupSummaryProps
  extends StepComponentSharedProps<
    MultipartyFollowupSummaryStepComponentSpec,
    string | null
  > {
  forceSubmit: () => void;
}

const MultipartyFollowupSummary: StepComponentFC<
  MultipartyFollowupSummaryProps
> = ({ step_component, forceSubmit, className, allValues, updateValue }) => {
  if (!allValues) {
    allValues = {};
  }

  const [finished, setFinished] = useState(false);
  const [activeEntryIndex, setActiveEntryIndex] = useState(-1);
  const [modalError, setModalError] = useState<string | null>(null);
  const entries = step_component.followup_data.entries.map(entry => {
    const isValid = entry.data.some(d => {
      const value =
        d.field && d.field in allValues ? allValues[d.field] : d.existing_value;
      if (!value) {
        return false;
      } else if (d.type === 'phone_number') {
        return isPossiblePhoneNumber(value);
      } else {
        return yup.string().email().isValidSync(value);
      }
    });
    return { ...entry, isValid };
  });

  const isReady = entries.every(e => e.isValid);

  useEffect(() => {
    setModalError(null);
  }, [activeEntryIndex]);

  return (
    <div className={classNames('relative mt-4', className)}>
      <motion.div
        className="text-left mt-8"
        variants={{
          initial: {},
          submitting: { transition: { staggerChildren: 0.8 } },
        }}
        animate={finished ? 'submitting' : 'initial'}
      >
        {entries.map(({ label, role, data, isValid }, i) => {
          return (
            <div className="mt-6">
              <Modal
                title={label}
                actions={[
                  {
                    title: 'Save',
                    primary: true,
                    disabled: !isValid,
                    onClick: () => {
                      setActiveEntryIndex(-1);
                    },
                  },
                ]}
                open={activeEntryIndex === i}
                customDismiss={() => setActiveEntryIndex(-1)}
                showDismissButton
                body={
                  <div className="max-w-xs mx-auto mb-8">
                    <div>
                      {data.every(d => !d.field)
                        ? `The following information is not editable.`
                        : `Please provide 
                        ${
                          data.length > 1
                            ? 'at least one of the following contact methods'
                            : 'the following contact method'
                        }.`}
                    </div>
                    {data.map(({ type, field, existing_value }) => {
                      return (
                        <div
                          key={field || type}
                          className={classNames(
                            !field &&
                              'opacity-50 cursor-not-allowed pointer-events-none select-none',
                          )}
                        >
                          <String
                            step_component={{
                              label:
                                type === 'phone_number'
                                  ? 'Phone number'
                                  : 'Email address',
                              type: 'string',
                              mode: type,
                              field: field || undefined,
                            }}
                            aria-label={`text input for ${
                              type === 'phone_number'
                                ? 'Phone number'
                                : 'Email address'
                            }`}
                            primaryValue={
                              field && field in allValues
                                ? allValues[field]
                                : existing_value
                            }
                            updateValue={(_, v) =>
                              field && updateValue(field, v as string)
                            }
                            error={''}
                            showErrorMessages={false}
                            showsPrefill={false}
                          />
                        </div>
                      );
                    })}
                  </div>
                }
              />
              <div className="sm:flex items-center leading-tight text-center sm:text-left">
                <span className="block font-medium text-cool-gray-700">
                  {label}
                </span>
                <span className="mt-1 sm:mt-0 sm:ml-1 sm:inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-600">
                  {role}
                </span>
              </div>
              <div className="text-cool-gray-500 text-sm leading-tight"></div>
              <Stepper
                finished={finished}
                missingContactDetails={!isValid}
                isSelf={i === 0}
                onContactDetailsClick={() => setActiveEntryIndex(i)}
              />
            </div>
          );
        })}
      </motion.div>
      {!isReady ? (
        <div className="mt-8 -mb-4 text-sm font-medium text-cool-gray-600">
          Please ensure that valid contact details are provided for each
          involved party above before submitting.
        </div>
      ) : null}
      <div
        className={classNames(
          'ClaimWorkflowInner mt-8 flex flex-wrap justify-center',
        )}
      >
        <button
          className={classNames(
            'btn btn-blue sm:order-last',
            (!isReady || finished) && 'btn-disabled',
          )}
          disabled={!isReady}
          onClick={() => {
            const delay = (entries.length + 1) * 1000 - 500;
            setFinished(true);
            // setTimeout(() => setFinished(false), delay);
            setTimeout(() => forceSubmit(), delay);
          }}
        >
          {finished ? 'Submitting...' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

const Stepper = ({
  missingContactDetails,
  onContactDetailsClick,
  isSelf,
  finished,
}: {
  missingContactDetails: boolean;
  onContactDetailsClick: () => void;
  isSelf: boolean;
  finished: boolean;
}) => {
  const makeSteps = (finished: boolean) => [
    {
      id: '1',
      name: (
        <span>
          Contact details{' '}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-3 w-3 inline-block"
            style={{ verticalAlign: -2 }}
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
            <path
              fillRule="evenodd"
              d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
              clipRule="evenodd"
            />
          </svg>
        </span>
      ),
      status: missingContactDetails ? 'error' : 'complete',
    },
    {
      id: '2',
      name: 'Collect report',
      status: isSelf
        ? finished
          ? 'complete'
          : 'current'
        : finished
        ? 'current'
        : 'upcoming',
    },
    {
      id: '3',
      name: 'Finished!',
      status: isSelf && finished ? 'complete' : 'upcoming',
    },
  ];
  const previousSteps = makeSteps(false);
  const newSteps = makeSteps(finished);

  return (
    <div>
      <ol className="mt-4 sm:mt-2 border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {newSteps.map((step, stepIdx) => {
          const onClick = stepIdx === 0 ? onContactDetailsClick : undefined;

          const iconInner =
            step.status === 'complete' ? (
              <svg
                className="h-4 w-4 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            ) : step.status === 'error' ? (
              <svg
                className="h-4 w-4 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                />
              </svg>
            ) : step.status === 'current' ? (
              <motion.svg
                className="h-4 w-4 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style={{ originX: '50%', originY: '50%' }}
                animate={{ rotate: -360 }}
                transition={{
                  repeat: Infinity,
                  repeatType: 'loop',
                  duration: 2,
                  ease: 'linear',
                }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </motion.svg>
            ) : (
              <svg
                className="h-4 w-4 text-white"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            );

          const makeVariants = (status: string) => ({
            backgroundColor:
              status === 'complete'
                ? '#057a55'
                : status === 'error'
                ? '#e02424'
                : status === 'current'
                ? '#5850ec'
                : '#9fa6b2',
          });
          const initial = makeVariants(previousSteps[stepIdx].status);
          const submitting = makeVariants(step.status);

          const variants = {
            initial,
            submitting: {
              ...submitting,
              opacity:
                initial.backgroundColor === submitting.backgroundColor
                  ? 1
                  : [0, 1, 1],
              scale:
                initial.backgroundColor === submitting.backgroundColor
                  ? 1
                  : [1, 1.5, 1],
              transition: { duration: 0.5 },
            },
          };

          const icon = (isSelf ? stepIdx > 0 : stepIdx === 1) ? (
            <motion.span
              variants={variants}
              className={classNames(
                'flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full',
              )}
            >
              {iconInner}
            </motion.span>
          ) : (
            <span
              style={{ backgroundColor: variants.submitting.backgroundColor }}
              className={classNames(
                'flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full',
              )}
            >
              {iconInner}
            </span>
          );

          return (
            <li
              key={step.id}
              className={classNames(
                'relative md:flex-1 md:flex',
                onClick && 'cursor-pointer',
              )}
              onClick={onClick}
            >
              {step.status === 'complete' ? (
                <div className="group flex items-center w-full">
                  <span className="px-3 py-4 flex items-center text-sm font-medium">
                    {icon}
                    <span className="ml-2 text-xs font-medium text-green-900 leading-tight">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.status === 'error' ? (
                <div className="group flex items-center w-full">
                  <span className="px-3 py-4 flex items-center text-sm font-medium">
                    {icon}
                    <span className="ml-2 text-xs font-medium text-red-900 leading-tight">
                      {step.name}
                    </span>
                  </span>
                </div>
              ) : step.status === 'current' ? (
                <div className="px-3 py-4 flex items-center text-sm font-medium">
                  {icon}
                  <span className="ml-2 text-xs font-medium text-indigo-600 leading-tight">
                    {step.name}
                  </span>
                </div>
              ) : (
                <div className="group flex items-center">
                  <span className="px-3 py-4 flex items-center text-sm font-medium">
                    {icon}
                    <span className="ml-2 text-xs font-medium text-gray-500 leading-tight">
                      {step.name}
                    </span>
                  </span>
                </div>
              )}

              {stepIdx !== newSteps.length - 1 ? (
                <>
                  {/* Arrow separator for lg screens and up */}
                  <div
                    className="hidden md:block absolute top-0 right-0 h-full w-5"
                    aria-hidden="true"
                  >
                    <svg
                      className="h-full w-full text-gray-300"
                      viewBox="0 0 22 80"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0 -2L20 40L0 82"
                        vectorEffect="non-scaling-stroke"
                        stroke="currentcolor"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </>
              ) : null}
            </li>
          );
        })}
      </ol>
    </div>
  );
};

export default MultipartyFollowupSummary;
