import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

export type CurrencyComponentState = {
  formattedValue: string;
};

export const currency: StringInputVariety = ({
  step_component,
  primaryValue,
  updateValue,
  componentState,
}: StringInputVarietyProps): StringInputVarietyResult => {
  const Component = MaskedInput;
  const v = primaryValue ? +primaryValue : 0;

  const allowDecimal = step_component.allow_decimal || false;

  let defaultFormattedValue: string | undefined;
  if (allowDecimal) {
    defaultFormattedValue = v ? `$${(v / 100).toFixed(2)}` : undefined;
  } else {
    defaultFormattedValue = v ? `$${Math.round(v / 100)}` : undefined;
  }

  const formattedValue =
    componentState.current?.currency?.formattedValue || defaultFormattedValue;

  const additionalProps = {
    mask: createNumberMask({ allowDecimal }),
    type: 'tel',
    value: formattedValue,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (componentState.current) {
        // eslint-disable-next-line no-param-reassign
        componentState.current.currency.formattedValue = e.target.value;
      }
      const val = e ? +e.target.value.replace(/[^0-9.]/g, '') : null;
      updateValue(step_component.field, val ? Math.round(val * 100) : null);
    },
    placeholder: '$0',
    style: { textAlign: 'center' },
  };

  return {
    Component,
    additionalProps,
    narrow: true,
  };
};
