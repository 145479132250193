import { gql } from '@apollo/client';

export const AUTHENTICATE_USER = gql`
  mutation AuthenticateUser(
    $name: String
    $phoneNumber: String
    $carrierUserId: String
    $carrierSessionId: String
    $dispatchId: String
  ) {
    authenticateUserByPhone(
      name: $name
      phoneNumber: $phoneNumber
      carrierUserId: $carrierUserId
      carrierSessionId: $carrierSessionId
      dispatchId: $dispatchId
    ) {
      id
      firstName
      magicToken
    }
  }
`;

export const GET_USER = gql`
  query Me {
    me {
      id
      firstName
      magicToken
    }
  }
`;
