import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import {
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer/types';

import { useMobileDetect } from '../../hooks';

import type { SignatureStepComponentSpec } from '@assured/step-renderer';
type SignatureProps = StepComponentSharedProps<
  SignatureStepComponentSpec,
  string | null
>;

const Signature: StepComponentFC<SignatureProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
}) => {
  const { isMobile } = useMobileDetect();
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [dirty, setDirty] = useState(false);
  const [typed, setTyped] = useState('');
  const useWhiteColor = document.body.classList.contains('tenant-tesla');
  const inputRef = useRef();

  // forces autoFocus over buttons (mono-button "continue" has priority by default)
  // a hack, but should make it work everywhere else and here with proper priority
  useEffect(() => inputRef?.current?.focus?.(), []);

  return (
    <div className={classNames('mt-4', className)}>
      <div
        className="relative border rounded-lg border-dashed border-cool-gray-400"
        style={{ height: isMobile ? 180 : 125 }}
      >
        <div className="absolute left-0 right-0 bottom-0 px-4 pb-4 pointer-events-none select-none">
          <div
            data-testid="signature"
            className={classNames(
              'text-gray-400 font-medium uppercase text-sm transition-opacity ease-in-out duration-200',
              dirty ? 'opacity-0' : 'opacity-100',
            )}
          >
            {isMobile
              ? `Sign here (use your finger)`
              : `Type your full name here`}
          </div>
          <div className="mt-2 pt-2 border-t border-cool-gray-400 text-cool-gray-600">
            {step_component.signature_label}
          </div>
        </div>

        {isMobile ? (
          <SignatureCanvas
            penColor={useWhiteColor ? '#fff' : '#000'}
            canvasProps={{
              className: 'w-full absolute inset-0 h-full',
            }}
            onBegin={() => setDirty(true)}
            onEnd={() =>
              updateValue(
                step_component.field,
                sigCanvas.current?.toDataURL() || null,
              )
            }
            clearOnResize={false}
            ref={r => {
              sigCanvas.current = r;
            }}
          />
        ) : (
          <input
            autoFocus
            ref={inputRef}
            aria-label="signature"
            type="text"
            className={classNames(
              'absolute block left-0 right-0 bottom-12 pb-1 w-full bg-transparent text-center text-2xl focus:outline-none',
              useWhiteColor && 'text-white',
            )}
            style={{ fontFamily: 'cursive' }}
            value={typed}
            onChange={e => {
              setDirty(!!e.target.value);
              setTyped(e.target.value);
              updateValue(step_component.field, e.target.value);
            }}
          />
        )}
        <svg
          className={classNames(
            'absolute top-2 right-2 w-6 h-6 text-gray-400 cursor-pointer hover:text-gray-500 transition duration-200 ease-in-out',
            dirty ? 'opacity-100' : 'opacity-0',
          )}
          fill="currentColor"
          viewBox="0 0 20 20"
          onClick={() => {
            sigCanvas.current?.clear();
            updateValue(step_component.field, null);
            setDirty(false);
            setTyped('');
          }}
        >
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </div>
      {step_component.disclaimer_text ? (
        <div className="mt-6 mb-4 text-xs font-medium text-cool-gray-500 uppercase">
          {step_component.disclaimer_text}
        </div>
      ) : null}
    </div>
  );
};

Signature.stepConfig = {
  manualSubmit: true,
};

export default Signature;
