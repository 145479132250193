import type {
  PartyEntryStepComponentSpec,
  StepComponentControlsErrorProps,
  StepComponentFC,
  StepComponentSharedProps,
  StepComponentShowsPrefillProps,
} from '@assured/step-renderer';
import classNames from 'classnames';

import { PartyEntryButtonValue } from '@assured/step-renderer/types/step-components/PartyEntry';

import { PartyEntryEditableList } from './PartyEntryEditableList';
import { PartyEntryList } from './PartyEntryList';

type PartyEntryProps = StepComponentSharedProps<
  PartyEntryStepComponentSpec,
  PartyEntryButtonValue
> &
  StepComponentControlsErrorProps &
  StepComponentShowsPrefillProps;

export const PartyEntry: StepComponentFC<PartyEntryProps> = ({
  step_component,
  updateValue,
  className,
}) => {
  const parties = step_component.partyEntries || [];

  if (step_component.mode === 'editableList') {
    return (
      <div className={classNames('mt-4', className)}>
        <PartyEntryEditableList
          parties={parties}
          buttons={step_component.buttons}
          updateValue={updateValue}
          field={step_component.field}
        />
      </div>
    );
  }
  // step_component.mode === 'list'
  return (
    <div className={className}>
      <PartyEntryList
        parties={parties}
        footer={step_component.footer}
        buttons={step_component.buttons}
        updateValue={updateValue}
        field={step_component.field}
      />
    </div>
  );
};
