import { arm } from './arm';
import { back } from './back';
import { backWithNeck } from './backWithNeck';
import { chest } from './chest';
import { chestWithNeck } from './chestWithNeck';
import { foot } from './foot';
import { hand } from './hand';
import { head } from './head';
import { headWithoutNeck } from './headWithoutNeck';
import { lowerLeg } from './lowerLeg';
import { shoulder } from './shoulder';
import { torso } from './torso';
import {
  BodilyInjuryRegion,
  BodilyInjuryRegionTabs,
  BodilyInjuryRegionTemplates,
  RegionTemplates as TRegionTemplates,
} from './types';
import { upperLeg } from './upperLeg';

export const BodilyInjuryRegionToBodilyInjuryRegionTemplates: {
  [k in BodilyInjuryRegion]: BodilyInjuryRegionTemplates;
} = {
  head: 'head',
  shoulder_left: 'shoulder',
  shoulder_right: 'shoulder',
  arm_left: 'arm',
  arm_right: 'arm',
  hand_left: 'hand',
  hand_right: 'hand',
  chest: 'chest',
  torso: 'torso',
  upper_leg_left: 'upper_leg',
  upper_leg_right: 'upper_leg',
  lower_leg_left: 'lower_leg',
  lower_leg_right: 'lower_leg',
  foot_left: 'foot',
  foot_right: 'foot',
  back: 'back',
};

export const REGION_TEMPLATES: TRegionTemplates = {
  ...head,
  ...shoulder,
  ...arm,
  ...hand,
  ...chest,
  ...torso,
  ...back,
  ...upperLeg,
  ...lowerLeg,
  ...foot,
};

export const REGION_TEMPLATES_V2: TRegionTemplates = {
  ...headWithoutNeck,
  ...shoulder,
  ...arm,
  ...hand,
  ...chestWithNeck,
  ...torso,
  ...backWithNeck,
  ...upperLeg,
  ...lowerLeg,
  ...foot,
};

export const BODILY_INJURY_REGIONS: Readonly<
  Readonly<BodilyInjuryRegionTabs>[]
> = [
  {
    key: 'head',
    label: 'Head',
    data: REGION_TEMPLATES.head,
  },
  {
    key: 'shoulder_left',
    label: 'Left shoulder',
    data: REGION_TEMPLATES.shoulder,
  },
  {
    key: 'shoulder_right',
    label: 'Right shoulder',
    data: REGION_TEMPLATES.shoulder,
  },
  {
    key: 'arm_left',
    label: 'Left arm',
    data: REGION_TEMPLATES.arm,
  },
  {
    key: 'arm_right',
    label: 'Right arm',
    data: REGION_TEMPLATES.arm,
  },
  {
    key: 'hand_left',
    label: 'Left hand',
    data: REGION_TEMPLATES.hand,
  },
  {
    key: 'hand_right',
    label: 'Right hand',
    data: REGION_TEMPLATES.hand,
  },
  {
    key: 'chest',
    label: 'Chest',
    data: REGION_TEMPLATES.chest,
  },
  {
    key: 'torso',
    label: 'Torso',
    data: REGION_TEMPLATES.torso,
  },
  {
    key: 'upper_leg_left',
    label: 'Upper left leg',
    data: REGION_TEMPLATES.upper_leg,
  },
  {
    key: 'upper_leg_right',
    label: 'Upper right leg',
    data: REGION_TEMPLATES.upper_leg,
  },
  {
    key: 'lower_leg_left',
    label: 'Lower left leg',
    data: REGION_TEMPLATES.lower_leg,
  },
  {
    key: 'lower_leg_right',
    label: 'Lower right leg',
    data: REGION_TEMPLATES.lower_leg,
  },
  {
    key: 'foot_left',
    label: 'Left foot',
    data: REGION_TEMPLATES.foot,
  },
  {
    key: 'foot_right',
    label: 'Right foot',
    data: REGION_TEMPLATES.foot,
  },
  {
    key: 'back',
    label: 'Back',
    data: REGION_TEMPLATES.back,
  },
] as const;

export const BODILY_INJURY_REGIONS_V2: Readonly<
  Readonly<BodilyInjuryRegionTabs>[]
> = [
  {
    key: 'head',
    label: 'Head',
    data: REGION_TEMPLATES_V2.head,
  },
  {
    key: 'shoulder_left',
    label: 'Left shoulder',
    data: REGION_TEMPLATES_V2.shoulder,
  },
  {
    key: 'shoulder_right',
    label: 'Right shoulder',
    data: REGION_TEMPLATES_V2.shoulder,
  },
  {
    key: 'arm_left',
    label: 'Left arm',
    data: REGION_TEMPLATES_V2.arm,
  },
  {
    key: 'arm_right',
    label: 'Right arm',
    data: REGION_TEMPLATES_V2.arm,
  },
  {
    key: 'hand_left',
    label: 'Left hand',
    data: REGION_TEMPLATES_V2.hand,
  },
  {
    key: 'hand_right',
    label: 'Right hand',
    data: REGION_TEMPLATES_V2.hand,
  },
  {
    key: 'chest',
    label: 'Chest',
    data: REGION_TEMPLATES_V2.chest,
  },
  {
    key: 'torso',
    label: 'Torso',
    data: REGION_TEMPLATES_V2.torso,
  },
  {
    key: 'upper_leg_left',
    label: 'Upper left leg',
    data: REGION_TEMPLATES_V2.upper_leg,
  },
  {
    key: 'upper_leg_right',
    label: 'Upper right leg',
    data: REGION_TEMPLATES_V2.upper_leg,
  },
  {
    key: 'lower_leg_left',
    label: 'Lower left leg',
    data: REGION_TEMPLATES_V2.lower_leg,
  },
  {
    key: 'lower_leg_right',
    label: 'Lower right leg',
    data: REGION_TEMPLATES_V2.lower_leg,
  },
  {
    key: 'foot_left',
    label: 'Left foot',
    data: REGION_TEMPLATES_V2.foot,
  },
  {
    key: 'foot_right',
    label: 'Right foot',
    data: REGION_TEMPLATES_V2.foot,
  },
  {
    key: 'back',
    label: 'Back',
    data: REGION_TEMPLATES_V2.back,
  },
] as const;
