/* eslint-disable @typescript-eslint/no-explicit-any */
import classNames from 'classnames';

import { StepComponentRenderer } from '@assured/step-renderer/components';
import {
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer/types';

import type { ListStepComponentSpec } from '@assured/step-renderer';

type ListProps = StepComponentSharedProps<
  ListStepComponentSpec,
  object[] | null
> &
  Record<string, any>;

const List: StepComponentFC<ListProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  attemptSubmit,
  ...rest
}) => {
  // Note: We magically hide __hidden__ items from the list, while still passing them through
  // upon save. This keeps items in a list, while preventing the user from editing.
  let list: any[] =
    primaryValue || (step_component.existing_value as any[]) || [];
  if (step_component.fixed_length) {
    const nMissing = step_component.fixed_length - list.length;
    if (nMissing < 0) {
      list = list.slice(0, step_component.fixed_length);
    } else if (nMissing > 0) {
      list = list.concat(new Array(nMissing).fill(null).map(() => ({})));
    }
  }

  const grouped = step_component.list_components.length > 1;

  const shouldShowOne =
    step_component.add_button_always_show_one &&
    step_component.add_button &&
    list.filter(l => !l?.__hidden__).length === 0;
  const couldNotShowOne =
    step_component.add_button || step_component.fixed_length;

  const displayList = list.concat(
    shouldShowOne || !couldNotShowOne ? [null] : [],
  );

  const disableRemoval =
    step_component.add_button_always_show_one &&
    step_component.disable_remove_when_one_shown &&
    displayList.length === 1;

  return (
    <div className={classNames(className, grouped && '-my-2')}>
      {displayList.map((item: Record<string, any>, i) =>
        item?.__hidden__ ? null : (
          <div
            key={i}
            className={classNames(
              step_component.list_compact_items && `flex -mx-1`,
              step_component.add_button && 'items-center justify-center',
              grouped && (step_component.list_compact_items ? '' : 'my-8'),
            )}
          >
            {step_component.list_components.map(baseDef => {
              const currentComponentDef = { ...baseDef };
              for (const [key, value] of Object.entries(currentComponentDef)) {
                if (
                  key === 'label' &&
                  'label' in currentComponentDef &&
                  value
                ) {
                  currentComponentDef[key] = value.replace(/{n}/g, i + 1);
                }
              }
              return (
                <div
                  key={currentComponentDef.field}
                  className={
                    step_component.add_button
                      ? 'mr-2 ClaimWorkflowListItem'
                      : ''
                  }
                >
                  <StepComponentRenderer
                    listIndex={i}
                    step_component={{
                      ...currentComponentDef,
                      field: currentComponentDef.field || '$self',
                    }}
                    primaryValue={
                      ({ $self: item, ...item } as Record<string, any>)[
                        currentComponentDef.field || '$self'
                      ]
                    }
                    updateValue={(k, v) => {
                      let value = v;
                      const defaultValue =
                        step_component.list_components.reduce((acc, c) => {
                          const o = acc;
                          if (
                            c.field &&
                            'existing_value' in c &&
                            c.existing_value
                          ) {
                            o[c.field] = c.existing_value;
                          }
                          return o;
                        }, {} as any);

                      if (k !== '$self') {
                        if (k && i < list.length) {
                          value = { ...defaultValue, ...list[i], [k]: value };
                        } else if (k) {
                          value = { ...defaultValue, [k]: value };
                        }
                      }

                      const { field } = step_component;
                      const updatedList = list.slice();

                      if (!value) {
                        updatedList.splice(i, 1);
                      } else if (i > list.length) {
                        updatedList.push(value);
                      } else {
                        updatedList[i] = value;
                      }

                      updateValue(field, updatedList);
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                  />
                </div>
              );
            })}
            {step_component.add_button && !disableRemoval ? (
              <button
                type="button"
                className="ClaimWorkflowInner ml-1 mt-5 text-xl text-gray-500 hover:text-gray-600 focus:outline-none focus:font-bold"
                onClick={() => {
                  const updatedList = list.slice();
                  updatedList.splice(i, 1);
                  updateValue(step_component.field, updatedList);
                }}
              >
                ×
              </button>
            ) : null}
          </div>
        ),
      )}
      {step_component.add_button ? (
        <button
          type="button"
          className={classNames('btn mt-4 py-2')}
          onClick={() => {
            const updatedList = list.slice();
            updatedList.push({});
            updateValue(step_component.field, updatedList);
          }}
        >
          {list.length || step_component.add_button_always_show_one
            ? step_component.add_another_button || step_component.add_button
            : step_component.add_button}
        </button>
      ) : null}
    </div>
  );
};

List.stepConfig = {
  manualSubmit: true,
};

export default List;
