import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

const getDescriptionMessage = (nChars: number) => {
  switch (true) {
    case nChars < 10:
      return 'Start typing...';
    case nChars < 100:
      return 'Keep going...';
    case nChars < 200:
      return 'Add some more detail...';
    case nChars < 280:
      return 'Perfect!';
    default:
      return 'Oops! Too much.';
  }
};

const getCircleStroke = (
  goalChars: number,
  nChars: number,
): 'red' | 'orange' | '#3f83f8' => {
  const diff = goalChars - nChars;
  if (diff <= 0) {
    return 'red';
  }
  if (diff <= 20) {
    return 'orange';
  }
  return '#3f83f8';
};

export const paragraph: StringInputVariety = ({
  step_component,
  primaryValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  const Component = 'textarea';
  const additionalProps = { rows: 4, blockEmoji: true };
  if (step_component.paragraph_mode !== 'input_counter') {
    return {
      Component,
      additionalProps,
      narrow: !!step_component.narrow,
    };
  }
  const nChars = typeof primaryValue === 'string' ? primaryValue.length : 0;
  const GOAL_CHARS = 280;
  const message = getDescriptionMessage(nChars);

  const r = 10;
  const circleLength = 2 * Math.PI * r;
  const colored = (circleLength * nChars) / 280;
  const gray = circleLength - colored > 0 ? circleLength - colored : 0;

  const additionalContent = (
    <div className="flex items-center justify-end">
      <div className="font-medium text-sm text-cool-gray-600">{message}</div>
      <svg className="ml-2 w-7 h-10 inline-block">
        <circle
          id="gray"
          cx="50%"
          cy="50%"
          r={r}
          style={{ fill: 'none', strokeWidth: 6, stroke: 'lightgray' }}
        />
        <circle
          id="colored"
          cx="50%"
          cy="50%"
          r={r}
          style={{
            fill: 'none',
            strokeWidth: 6,
            stroke: getCircleStroke(GOAL_CHARS, nChars),
            strokeDasharray: `${colored} ${gray}`,
          }}
        />
      </svg>
    </div>
  );
  return {
    Component,
    additionalProps,
    additionalContent,
    narrow: !!step_component.narrow,
  };
};
