import '../ClaimWorkflow.css';

import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  CatType,
  useGetCatastropheAffectedPolicyLocationsQuery,
} from '~/src/generatedX/graphql';

import { BeginWorkflowContainer } from '../../components/BeginWorkflow/BeginWorkflowContainer';
import ClaimWorkflowStep from '../../components/ClaimWorkflow/ClaimWorkflowStep';
import Nav from '../../components/Nav';
import PageLoader from '../../components/PageLoader';
import { useTenantConfig } from '../../hooks';
import Base from '../../layouts/Base';
import { CatastropheNounMap } from '../catastrophe/constants';
import { BeginWorkflowParams } from '~/src/components/BeginWorkflow/types';

const BeginCat: React.FC<
  RouteComponentProps<
    { splat: string },
    {},
    {
      catastrophe: { id: string; type: CatType };
      me: { id: string; name: string; phoneNumber: string; email: string };
    }
  >
> = ({ location, history }) => {
  const tenantConfig = useTenantConfig();
  const [startParams, setStartParams] = useState<BeginWorkflowParams>();

  const { data: locationsData, loading } =
    useGetCatastropheAffectedPolicyLocationsQuery({
      variables: {
        catastropheId: location.state.catastrophe.id,
      },
    });

  useEffect(() => {
    window.analytics.track('Begin Page Viewed');
  }, []);

  if (!tenantConfig || loading) {
    return <PageLoader />;
  }

  return (
    <>
      <Nav onBack={() => history.goBack()} tenant={tenantConfig.tenant} />

      <Base
        narrow
        tenant={tenantConfig.tenant}
        containerClassName={'ClaimWorkflow mt-12 flex flex-col justify-center'}
      >
        <BeginWorkflowContainer startParams={startParams} />
        <ClaimWorkflowStep
          onSubmit={data => {
            if (data.skip) {
              history.push('/begin');
              return;
            }
            window.analytics.track('Begin Page Completed');
            const { me, catastrophe } = location.state;
            const { __typename, ...policyLocation } =
              data.affectedAddress.location;
            setStartParams({
              type: 'home',
              name: me.name,
              phoneNumber: me.phoneNumber,
              catastropheId: catastrophe.id,
              policyNumber: data.affectedAddress.policyNumber,
              policyLocation,
              isCommercialClaim: data.affectedAddress.isCommercial,
            });
          }}
          step={{
            id: 'cat-policy-search',
            key: 'cat-policy-search',
            activeStepKeys: [],
            notifications: [],
            stepIndex: 0,
            totalSteps: 1,
            content: {
              step_components: [
                {
                  title: `Which of your properties was impacted by the ${
                    CatastropheNounMap[location.state.catastrophe.type] ||
                    'storm'
                  }?`,
                  type: 'select',
                  mode: 'wide',
                  field: 'affectedAddress',
                  required: true,
                  label: 'Affected Address',
                  options:
                    locationsData?.catastropheAffectedPolicyLocations?.map(
                      address => ({
                        label: address!.policyNumber!,
                        sublabel: address!.location!.addressText,
                        value: address,
                      }),
                    ) ?? [],
                },
              ],
              skip_label: 'My property is not listed',
              skip_field: 'skip',
              skip_value: true,
            },
          }}
        />
      </Base>
    </>
  );
};

export default BeginCat;
