export const conicGradient = `
  conic-gradient(
    from 213deg at 50% 50%,
    #0025C2 0%,
    #007CCD 8.15%,
    #00B6D8 16.54%,
    #00BA00 25.03%,
    #68D000 33.38%,
    #FFFA00 41.42%,
    #FFCD00 50%,
    #FFA100 59.25%,
    #FF7300 66.58%,
    #FF0000 75.17%,
    #FF0096 83.54%,
    #980DC2 91.66%,
    #0025C2 100%
  )
`;
