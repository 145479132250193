import { DateTime } from 'luxon';

export function sameDay(a: DateTime | null, b: DateTime | null) {
  return (
    !!a &&
    !!b &&
    a.hasSame(b, 'year') &&
    a.hasSame(b, 'month') &&
    a.hasSame(b, 'day')
  );
}

export function getFirstApptOfDay(
  day: DateTime | null,
  appointments?: DateTime[],
) {
  return appointments?.find(slot => sameDay(slot, day));
}

function getFirstApptOfWeek(startOfWeek: DateTime, appointments: DateTime[]) {
  return appointments.find(
    slot => slot >= startOfWeek && slot < startOfWeek.plus({ days: 7 }),
  );
}

export interface State {
  currentWeek: DateTime;
  showTimeSlotsFor: DateTime | null;
  selectedTimeSlot: { slot: DateTime | null; selectedBy: 'user' | 'system' };
}

export type SimpleAction =
  | {
      type: 'NEXT_WEEK';
      payload: { available: DateTime[] };
    }
  | {
      type: 'PREV_WEEK';
      payload: { available: DateTime[] };
    }
  | {
      type: 'SELECT_DAY';
      payload: { available: DateTime[]; selected?: DateTime };
    }
  | {
      type: 'SELECT_TIMESLOT';
      payload: { selected: DateTime | null };
    };

type Action = SimpleAction & {
  callback: (selectedAppointment: DateTime | null) => void;
};

const reducer: React.Reducer<State, Action> = (
  state: State,
  action: Action,
) => {
  switch (action.type) {
    case 'NEXT_WEEK': {
      const firstApptOfCurrentWeek = getFirstApptOfWeek(
        state.currentWeek,
        action.payload.available,
      );
      const nextWeek = state.currentWeek.plus({ weeks: 1 });
      const firstApptOfNextWeek = getFirstApptOfWeek(
        nextWeek,
        action.payload.available,
      );

      const nextTimeSlot = (() => {
        if (
          state.selectedTimeSlot.selectedBy === 'user' &&
          !firstApptOfCurrentWeek
        )
          return state.selectedTimeSlot;

        if (firstApptOfNextWeek)
          return { slot: null, selectedBy: 'system' as const };

        return state.selectedTimeSlot;
      })();

      action.callback(nextTimeSlot.slot);
      return {
        ...state,
        currentWeek: nextWeek,
        showTimeSlotsFor: firstApptOfNextWeek ?? null,
        selectedTimeSlot: nextTimeSlot,
      };
    }
    case 'PREV_WEEK': {
      const firstApptOfCurrentWeek = getFirstApptOfWeek(
        state.currentWeek,
        action.payload.available,
      );
      const prevWeek = state.currentWeek.minus({ weeks: 1 });
      const firstApptOfPrevWeek = getFirstApptOfWeek(
        prevWeek,
        action.payload.available,
      );

      const nextTimeSlot = (() => {
        if (
          state.selectedTimeSlot.selectedBy === 'user' &&
          !firstApptOfCurrentWeek
        )
          return state.selectedTimeSlot;

        if (firstApptOfPrevWeek)
          return { slot: null, selectedBy: 'system' as const };

        return state.selectedTimeSlot;
      })();

      action.callback(nextTimeSlot.slot);
      return {
        ...state,
        currentWeek: prevWeek,
        showTimeSlotsFor: firstApptOfPrevWeek ?? null,
        selectedTimeSlot: nextTimeSlot,
      };
    }
    case 'SELECT_DAY': {
      const firstAppointment = getFirstApptOfDay(
        action.payload.selected ?? null,
        action.payload.available,
      );

      const nextTimeSlot = firstAppointment
        ? { slot: firstAppointment, selectedBy: 'system' as const }
        : state.selectedTimeSlot;

      action.callback(nextTimeSlot.slot);
      return {
        ...state,
        showTimeSlotsFor: action.payload.selected ?? null,
        selectedTimeSlot: nextTimeSlot,
      };
    }
    case 'SELECT_TIMESLOT': {
      const nextTimeSlot = {
        slot: action.payload.selected,
        selectedBy: 'user' as const,
      };

      action.callback(nextTimeSlot.slot ?? null);
      return {
        ...state,
        selectedTimeSlot: nextTimeSlot,
      };
    }
    default:
      return state;
  }
};

export default reducer;
