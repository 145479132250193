import { gql } from '@apollo/client';

export const RESUME_WORKFLOW_DRAFT = gql`
  mutation ResumeWorkflowDraft(
    $draftClaimId: ID!
    $authenticationCode: String
  ) {
    resumeWorkflowDraft(
      draftClaimId: $draftClaimId
      authenticationCode: $authenticationCode
    ) {
      success
      errors
      data {
        workflowId
      }
    }
  }
`;
