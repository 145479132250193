import {
  AnyStepComponentSpec,
  StepComponentConfigFlags,
  StepComponentRegistry,
} from '../';

export const stepComponentHasConfigFlag = <C extends AnyStepComponentSpec>(
  c: C,
  flag: keyof StepComponentConfigFlags<C>,
  registry: StepComponentRegistry,
) => {
  const Component = registry[c.type];
  let result;
  if (Component && '_result' in Component) {
    result = Component?._result?.stepConfig?.[flag];
  } else {
    result = Component?.stepConfig?.[flag];
  }
  if (flag === 'controlsError' && typeof result === 'function') {
    // controlsError can be a function that processes the step itself conditionally
    return result({ step_component: c });
  }
  return result;
};
