import Compressor from 'compressorjs';
import EXIF from 'exif-js';

//@ts-ignore
const S3Upload = require('react-s3-uploader/s3upload');

export interface PreviewableFile extends File {
  preview: string;
  exifData: any;
}
export type S3UploadResult = {
  fileKey: string;
  filename: string;
  publicUrl: string;
  signedUrl: string;
};
export type UplaodMediaToS3 = {
  files: File[];
  serverEndpoint: string;
  onBatchFinished: (data: S3UploadResult, blob: Blob) => void;
  onPreprocessComplete?: (file: PreviewableFile) => void;
  setProgress?: (p: number) => void;

  /**
   * @see {@link https://github.com/odysseyscience/react-s3-uploader/blob/4faf574977f4123a9040602bdc38476cfb216e97/s3upload.js#L163-L167 | `react-s3-uploader`}
   */
  onError?: (
    message: string,
    file?: string,
    xhr?: {
      response: string;
      status: number;
      statusText: string;
      readyState: number;
    },
  ) => void;

  mode?: string;
};
export const uploadMediaToS3 = ({
  files,
  serverEndpoint,
  onPreprocessComplete,
  setProgress,
  onError,
  onBatchFinished,
  mode,
}: UplaodMediaToS3) => {
  new S3Upload({
    files: files,
    signingUrl: '/uploads/sign',
    uploadRequestHeaders: { 'x-amz-acl': 'private' },
    onFinishS3Put: onBatchFinished,
    onProgress: setProgress
      ? (p: number) => setProgress(p / 100)
      : () => {
          /* no-op */
        },
    onError: onError,
    contentDisposition: 'auto',
    server: serverEndpoint,
    scrubFilename: (filename: string) => {
      const fn = filename.replace(/([^\w\d_\-.]+|--)/gi, '');
      const name = fn.substring(0, fn.lastIndexOf('.'));
      const ext = fn.substring(fn.lastIndexOf('.'));
      return `${name}${ext.toLowerCase()}`;
    },
    s3path: 'user-media/',
    getSignedUrl: function (
      file: File,
      cb: (result: { signedUrl: string }) => void,
    ) {
      // Use s3 accelerate
      this.executeOnSignedUrl(file, (result: { signedUrl: string }) => {
        result.signedUrl = result.signedUrl.replace(
          /s3\.amazonaws\./,
          's3-accelerate.amazonaws.',
        );
        cb(result);
      });
    },
    preprocess: (file: File, next: (file: File) => void) =>
      preprocess(
        file,
        next,
        onPreprocessComplete ? onPreprocessComplete : () => {},
        onError ? onError : () => {},
        mode,
      ),
  });
};

const preprocess = (
  file: File,
  next: (file: File) => void,
  onPreprocessComplete: (file: PreviewableFile) => void,
  onError: (e: string) => void,
  mode?: string,
) => {
  if (mode === 'video') {
    preprocessVideo(file, next, onPreprocessComplete, onError);
    return;
  }
  preprocessImage(file, next, onPreprocessComplete, onError);
};

const preprocessVideo = (
  file: File,
  next: (file: File) => void,
  onPreprocessComplete: (file: PreviewableFile) => void,
  onError: (e: string) => void,
) => {
  const preProcessedFile = {
    ...file,
    preview: URL.createObjectURL(file),
  } as PreviewableFile;
  onPreprocessComplete(preProcessedFile);
  next(file);
};

const preprocessImage = (
  file: File,
  next: (file: File) => void,
  onPreprocessComplete: (file: PreviewableFile) => void,
  onError: (e: string) => void,
) => {
  // Exif data
  EXIF.getData(file as any, () => {
    if (file && file.type) {
      if (!file.type.match(/image[/]/)) {
        return onError('Please upload an image file.');
      }

      if (file.type.match(/image[/]heic/)) {
        return onError(
          'Please convert this file to a JPEG file and try again.',
        );
      }
    }

    // window.EXIF = EXIF;
    let tags = EXIF.getAllTags(file);
    if (tags && tags.MakerNote) {
      delete tags.MakerNote;
    }

    // Compression
    new Compressor(file, {
      quality: 0.6,

      success(result) {
        const preProcessedFile = {
          ...result,
          preview: URL.createObjectURL(result),
          exifData: tags,
        } as PreviewableFile;
        onPreprocessComplete(preProcessedFile);
        next(result as File);
      },
      error(e) {
        console.error(e);
        if (file && file.type) {
          if (!file.type.match(/image[/]/)) {
            return onError('Please upload an image file.');
          } else if (file.type.match(/image[/]heic/)) {
            return onError(
              'Please convert this file to a JPEG file and try again.',
            );
          }
        }
        onError('There was an error processing your image.');
      },
    });
  });
};
