import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

export const postal_code: StringInputVariety = ({
  step_component,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  const additionalProps = {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value.toString().replace(/\D/g, '').slice(0, 5);
      updateValue(step_component.field, val);
    },
    blockEmoji: true,
    minlength: step_component.minimum,
  };
  return {
    Component: 'input',
    additionalProps,
    narrow: !!step_component.narrow,
  };
};
