export type units = 'feet' | 'yards';

// The <Toggle> component uses a boolean as its underlying value.
// We are toggling between feet and yards for units, but would
// prefer to call using "units" (feet|yards) for cognitive ease.
// Some of these functions facilitate the translation.

export function isYards(v: string): boolean {
  // feet = false, yards = true
  return v === 'yards';
}

export function booleanToFeetOrYards(v: boolean): units {
  // feet = false, yards = true
  return v ? 'yards' : 'feet';
}

// Originally this was more complicated: In addition to converting units,
// it also converted the value to the nearest value to the new step size.
// That proved to be a little unintuitive, so I took it out, leaving this
// function seeming unnecessary, but I still like the easy testability of
// logic contained in its own function, so I'll leave it here.
// TODO - When converting units, min/max not considered.
// For example, 9,999 yards converts to 29,997 feet. Then the down button
// steps down to the maximum. However, if it were to honor the maximum, it
// would appear as if "nothing happened" (it changes 9,999 yards to 9,999 feet)
// which may be less intuitive than actually performing the conversion.
// Leaving this slightly odd behavior (allowing exceeding the maximum during a
// units conversion) in place for now.
export function nearestValueToNewStepSize(
  previousValue: number,
  previousUnits: units,
  newUnits: units,
) {
  // Unlikely, but should this be called with same units as existing:
  if (previousUnits === newUnits) {
    return previousValue;
  }
  // Convert based on new units
  const valueInNewUnits =
    newUnits === 'feet' ? previousValue * 3 : previousValue / 3;
  return Math.floor(valueInNewUnits);
}

export function makeIncrementButtonClickHandler({
  direction,
  disabled,
  value,
  stepSize = 1,
  minimum = 0,
  maximum,
  updateValue,
}: {
  direction: 'positive' | 'negative';
  disabled?: boolean;
  value?: number;
  stepSize?: number;
  minimum?: number;
  maximum: number;
  updateValue: (n: number) => void;
}): React.MouseEventHandler<HTMLButtonElement> | undefined {
  if (disabled) {
    return undefined;
  }
  let newValue =
    (value || 0) + (direction === 'positive' ? stepSize : -stepSize);
  if (newValue < minimum) {
    newValue = minimum;
  }
  if (newValue > maximum) {
    newValue = maximum;
  }
  return () => updateValue(newValue);
}
