import classNames from 'classnames';
import React from 'react';
import { makeIncrementButtonClickHandler } from './helpers';

interface IncrementButtonProps {
  direction: 'positive' | 'negative';
  disabled?: boolean;
  value: number;
  updateValue: (n: number) => void;
  stepSize?: number;
  minimum?: number;
  maximum?: number;
}

const IncrementButton: React.FC<IncrementButtonProps> = ({
  direction,
  disabled,
  value,
  updateValue,
  stepSize = 1,
  minimum = 0,
  maximum = Infinity,
}) => {
  return (
    <button
      className={classNames(
        'w-12 h-12 flex items-center justify-center rounded-full border-2 border-cool-gray-300 bg-cool-gray-100 focus:outline-none focus:shadow-outline-blue mx-3 select-none',
        disabled && 'opacity-50 pointer-events-none',
      )}
      style={{ touchAction: 'manipulation' }}
      onClick={makeIncrementButtonClickHandler({
        direction,
        disabled,
        value,
        stepSize,
        minimum,
        maximum,
        updateValue,
      })}
    >
      <div className="leading-none text-center text-3xl font-bold text-cool-gray-500 -mt-1">
        {direction === 'positive' ? '+' : '−'}
      </div>
    </button>
  );
};

export default IncrementButton;
