import { RefObject, useEffect } from 'react';

export const useAccessiblePopover = ({
  ariaControls,
  isOpen,
  ref,
}: {
  ariaControls: string;
  isOpen: boolean;
  ref: RefObject<{ input: HTMLInputElement }> | null;
}) => {
  useEffect(() => {
    ref?.current?.input?.setAttribute('aria-controls', ariaControls);
    ref?.current?.input?.setAttribute('aria-expanded', isOpen?.toString());
  }, [ariaControls, isOpen]);
};
