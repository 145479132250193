import classNames from 'classnames';
import React, { useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import Modal from '~/src/components/Modal';

import { useHealthSearch } from '@assured/design-system/src/components/Input/useHealthSearch/useHealthSearch';
import { HealthSearchResult } from '@assured/shared-types/Services';
import Spinner from '@assured/ui/components/Spinner';
import { PencilIcon, XIcon } from '@heroicons/react/solid';

import type {
  ClinicalTableSearchStepComponentSpec,
  StepComponentFC,
  StepComponentSharedProps,
} from '@assured/step-renderer';
interface ClinicalTableSearchProps
  extends StepComponentSharedProps<
    ClinicalTableSearchStepComponentSpec,
    any[] | any
  > {
  forceSubmit: () => void;
}

const prettyProviderType = (providerType?: string) => {
  if (providerType) {
    return providerType
      .replace(/Non-/g, 'Non\u0000')
      .replace(/-(.*)($|-| )/g, ' ($1)$2')
      .replace(/\u0000/g, '-');
  }
};

const randomManualItemId = () => {
  return `MANUALLY_ENTERED_${Math.floor(Math.random() * 1000000)}`;
};

const ClinicalTableSearch: StepComponentFC<ClinicalTableSearchProps> = ({
  step_component,
  updateValue,
  className,
  forceSubmit,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = React.useState('');
  const [, cancel] = useDebounce(
    () => setDebouncedSearchTerm(searchTerm),
    100,
    [searchTerm],
  );

  type QueryResultItem = Partial<HealthSearchResult['results'][number]>;

  const [searchRes, setSearchRes] = useHealthSearch({
    isLoading,
    searchTerm: debouncedSearchTerm,
    setIsLoading,
    mode: step_component.mode,
    is_ribbon: step_component.is_ribbon,
    filing_location: step_component.filing_location,
  });

  const [selectedResults, setSelectedResults] = useState<QueryResultItem[]>(
    (step_component.list
      ? step_component.existing_results
      : 'existing_result' in step_component && step_component.existing_result
      ? [step_component.existing_result]
      : []
    )?.map(p =>
      step_component.mode === 'rxterm'
        ? p
        : {
            id: p.id,
            ...p.npiData,
          },
    ) || [],
  );

  const selectItem = (item: QueryResultItem) => {
    if (!selectedResults.find(rItem => rItem._id === item._id)) {
      setSelectedResults(results => [...results, item]);
    }
    setSearchRes(null);
    setSearchTerm('');
    setDebouncedSearchTerm('');
  };

  const deselectItem = (item: QueryResultItem) => {
    setSelectedResults(results => results.filter(i => i !== item));
  };

  const [isManuallyEnteringItem, setIsManuallyEnteringItem] = useState(false);
  const [manualEntryItem, setManualEntryItem] = useState<QueryResultItem>({
    _id: randomManualItemId(),
    NPI: 'MANUALLY_ENTERED',
  });
  const manualEntryReady =
    !!manualEntryItem['name.full'] &&
    (step_component.mode === 'rxterm' ||
      !!manualEntryItem['addr_practice.full']);
  const manualEntryLabel =
    step_component.mode === 'npi_hospital'
      ? 'hospital'
      : step_component.mode === 'npi_individual'
      ? 'doctor'
      : step_component.mode === 'npi_organization'
      ? 'organization'
      : step_component.mode === 'rxterm'
      ? 'prescription'
      : 'entry';

  const manualEntryButton = searchTerm ? (
    <>
      <div
        key={searchTerm}
        className="mt-4 p-4 cursor-pointer text-left rounded-md border border-cool-gray-300 hover:bg-cool-gray-50"
        onClick={() => {
          setManualEntryItem(i => ({
            ...i,
            'name.full': searchTerm,
          }));
          setIsManuallyEnteringItem(true);
        }}
      >
        <div className="font-medium text-cool-gray-800">{searchTerm}</div>
        <div className="text-sm font-medium text-cool-gray-500 flex items-center">
          <PencilIcon className="w-4 h-4 mr-1" />
          <span>Manually entered</span>
        </div>
      </div>
      {/* <button
        className="mt-4 rounded-md bg-blue-500 text-white hover:bg-blue-600 px-3 py-2 text-sm focus:outline-none focus:shadow-outline-blue inline-flex items-center leading-none"
        onClick={() => {
          setManualEntryItem(i => ({
            ...i,
            'name.full': searchTerm,
          }));
          setIsManuallyEnteringItem(true);
        }}
      >
        <PencilIcon className="w-4 h-4 mr-1" />
        <span>Enter details manually</span>
      </button> */}
    </>
  ) : null;

  return (
    <div className={classNames('mt-4 relative', className)}>
      <Modal
        open={isManuallyEnteringItem}
        title={`Add ${manualEntryLabel}`}
        body={
          <div className="mt-3 mb-6 text-left">
            <div>
              <label
                className="font-medium text-cool-gray-600 mb-1 block"
                htmlFor="name.full"
              >
                Full name
              </label>
              <input
                className="textbox text-base text-cool-gray-900"
                value={manualEntryItem['name.full'] || ''}
                onChange={e => {
                  const name = e.target.value;
                  setManualEntryItem(i => ({ ...i, ['name.full']: name }));
                }}
              />
            </div>
            {step_component.mode !== 'rxterm' ? (
              <div className="mt-4">
                <label
                  className="font-medium text-cool-gray-600 mb-1 block"
                  htmlFor="name.full"
                >
                  City and state
                </label>
                <input
                  className="textbox text-base text-cool-gray-900"
                  value={manualEntryItem['addr_practice.full'] || ''}
                  placeholder="Anytown, CA"
                  onChange={e => {
                    const name = e.target.value;
                    setManualEntryItem(i => ({
                      ...i,
                      ['addr_practice.full']: name,
                    }));
                  }}
                />
              </div>
            ) : null}
          </div>
        }
        actions={[
          {
            title: `Add ${manualEntryLabel}`,
            primary: true,
            onClick: () => {
              if (manualEntryReady) {
                selectItem(manualEntryItem);
                setManualEntryItem(i => ({ ...i, _id: randomManualItemId() }));
                setIsManuallyEnteringItem(false);
              }
            },
            className: manualEntryReady ? '' : 'btn-disabled',
          },
          {
            title: 'Never mind',
            className: 'sm:order-first',
            onClick: () => {
              setIsManuallyEnteringItem(false);
            },
          },
        ]}
      />
      {selectedResults ? (
        <div className="">
          {selectedResults.map(item => {
            return (
              <div
                key={item._id}
                className="bg-cool-gray-50 py-2 px-4 rounded-md border border-cool-gray-300 mb-4 text-left uppercase flex items-center text-cool-gray-800"
              >
                <span className="flex-1 font-medium">
                  {item['name.full'] || item['DISPLAY_NAME']}
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => deselectItem(item)}
                >
                  <XIcon className="w-4 h-4 text-cool-gray-400 hover:text-red-500" />
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
      {step_component.list || selectedResults.length === 0 ? (
        <>
          <div className="relative">
            <input
              type="string"
              className="textbox"
              placeholder={
                selectedResults.length
                  ? `Start typing ${
                      step_component.mode === 'npi_individual'
                        ? 'a last name '
                        : ''
                    }to add another...`
                  : `Start typing ${
                      step_component.mode === 'npi_individual'
                        ? 'a last name '
                        : ''
                    }to search...`
              }
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            {isLoading && <Spinner className="absolute right-1 top-3" />}
          </div>
          <div className="">
            {searchTerm &&
            searchTerm === searchRes?.term &&
            searchRes?.results?.length === 0 ? (
              <div className="my-4 text-cool-gray-400">{manualEntryButton}</div>
            ) : (
              <>
                {searchRes?.results.map(r => {
                  return (
                    <div
                      key={r._id}
                      data-testid={r._id}
                      className="mt-4 p-4 cursor-pointer text-left rounded-md border border-cool-gray-300 hover:bg-cool-gray-50"
                      onClick={() => selectItem(r)}
                    >
                      {step_component.mode === 'rxterm' ? (
                        <>
                          <div className="font-medium text-cool-gray-800">
                            {r['DISPLAY_NAME']}
                          </div>
                          <div
                            className="text-sm font-medium text-cool-gray-600"
                            style={{
                              overflow: 'hidden',
                              display: '-webkit-box',
                              WebkitBoxOrient: 'vertical',
                              WebkitLineClamp: '3',
                            }}
                          >
                            {r.STRENGTHS_AND_FORMS?.split(',')
                              .map((x: string) => x.trim())
                              .join(', ')}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="font-medium text-cool-gray-800 uppercase">
                            {r['name.full']}
                          </div>
                          <div className="text-sm font-medium text-cool-gray-500">
                            {prettyProviderType(r.provider_type)}{' '}
                            {step_component.mode === 'npi_organization' ||
                            step_component.mode === 'npi_hospital' ||
                            step_component.mode === 'npi_individual' ? (
                              <>
                                &middot;&nbsp;
                                {r['addr_practice.city'] &&
                                  `${r['addr_practice.city']},`}
                                &nbsp;
                                {r['addr_practice.state']}
                              </>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
                {searchRes?.results?.length ? (
                  <div className="mt-4">{manualEntryButton}</div>
                ) : null}
              </>
            )}
          </div>
        </>
      ) : null}
      <div className="mt-4 flex justify-center flex-wrap sm:flex-nowrap">
        <button
          data-testid="primaryAction"
          className={classNames(
            'btn btn-blue sm:order-last',
            !selectedResults.length && 'btn-disabled',
          )}
          onClick={() => {
            if (selectedResults.length) {
              if (step_component.mode === 'rxterm') {
                const value = selectedResults;
                updateValue(
                  step_component.field,
                  step_component.list
                    ? value.map(v => JSON.stringify(v))
                    : JSON.stringify(value[0]),
                );
              } else {
                const value = selectedResults.map(r => ({
                  id: (r as any).id,
                  name: r['name.full'],
                  npi: r.NPI,
                  npiData: r,
                }));
                updateValue(
                  step_component.field,
                  step_component.list ? value : value[0],
                );
              }
            }
          }}
        >
          {step_component.submit_label ||
            (step_component.list ? "That's everyone" : 'Continue')}
        </button>
        <button
          className="btn btn-subtle sm:order-first"
          onClick={() => forceSubmit()}
        >
          {step_component.skip_label ||
            (step_component.list ? "Didn't see anyone" : 'No one')}
        </button>
      </div>
    </div>
  );
};

ClinicalTableSearch.stepConfig = {};

export default ClinicalTableSearch;
