import type { WitnessEntryStepComponentSpec } from '@assured/step-renderer';
import { WitnessEntryButtonValue } from '@assured/step-renderer/types/step-components/WitnessEntry';
import { PartyEntryEditableList } from '../PartyEntry/PartyEntryEditableList';
import { PartyEntryListButtons } from '@assured/step-renderer/types/step-components/PartyEntry';

type WitnessEntryListProps = {
  updateValue: (
    field: string | undefined,
    value: WitnessEntryButtonValue,
  ) => void;
  step_component: WitnessEntryStepComponentSpec;
};

export const WitnessEntryList = ({
  step_component,
  updateValue,
}: WitnessEntryListProps) => {
  const witnesses = step_component.witnesses || [];

  return (
    <>
      <PartyEntryEditableList
        parties={witnesses}
        inlineEdit={false}
        updateValue={(field, value) =>
          updateValue(field, value as WitnessEntryButtonValue)
        }
        buttons={step_component.buttons}
        field={step_component.field}
      />
      {getSecondaryButton(
        step_component.buttons,
        updateValue,
        step_component.field,
      )}
    </>
  );
};

const getSecondaryButton = (
  buttons: PartyEntryListButtons,
  updateValue: (
    field: string | undefined,
    value: WitnessEntryButtonValue,
  ) => void,
  field?: string,
) => {
  if (buttons.secondaryButton) {
    const buttonValue = buttons.secondaryButton.value;
    const onClick = () => {
      if (buttons.secondaryButton)
        updateValue(field, buttonValue as WitnessEntryButtonValue);
    };

    return (
      <button
        type="button"
        key={JSON.stringify(buttons.secondaryButton.value)}
        className="btn btn-blue mt-6"
        onClick={onClick}
      >
        <span>{buttons.secondaryButton.label}</span>
      </button>
    );
  }
};
