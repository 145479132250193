import type {
  LegalDisclaimerStepComponentSpec,
  StepComponentSharedProps,
} from '@assured/step-renderer';

type LegalDisclaimerProps = StepComponentSharedProps<
  LegalDisclaimerStepComponentSpec,
  string | null
>;

const LegalDisclaimer = ({ step_component }: LegalDisclaimerProps) => {
  return (
    <div
      style={{ fontSize: '0.65rem' }}
      className={`mt-5 ClaimWorkflowInner ${
        step_component.centered ? 'text-center' : 'text-justify'
      } text-cool-gray-500`}
    >
      {step_component.disclaimer_text}
    </div>
  );
};
export default LegalDisclaimer;
