import type { StepComponentSpec } from '../stepComponentSpec';
import { DocumentSpecProperties } from './additional';
import { IllustrationType } from './helpers/Illustration';

export enum DocumentStepMode {
  UPLOAD = 'UPLOAD',
  PROCESSING = 'PROCESSING',
  MANUAL = 'MANUAL',
  CONFIRM = 'CONFIRM',
  REVIEW_ALREADY_UPLOADED = 'REVIEW_ALREADY_UPLOADED',
}

interface DocumentFields {
  manual_title: string;
  confirm_title: string;
  extraction_failed_title: string;
  review_already_uploaded_title?: string;
  skip_label?: string;
  secondary_skip_label?: string;
  manual_label?: string;
  submit_label?: string;
  document_type: string;
  document_field: string;
  content_field?: string;
  skip_ocr_confirm?: boolean;
  default_manual_document?: Record<string, string>;
  document_components: DocumentSpecProperties[];
  /** String that is used to determine what image to render on the ExampleIllustration component */
  example_illustration?: IllustrationType;
  initial_mode?: DocumentStepMode;
  enforce_required_document_fields?: boolean;
}

export type DocumentStepComponentSpec = StepComponentSpec<
  DocumentFields,
  'document'
>;
