import { Link } from 'react-router-dom';

export default function TermsWidget({
  tenantName,
  hideExtendedTerms,
  continueButtonText = 'Continue',
}: {
  tenantName?: string;
  hideExtendedTerms?: boolean;
  continueButtonText?: string;
}) {
  return (
    <div
      style={{ fontSize: '0.65rem' }}
      className="mt-5 ClaimWorkflowInner text-justify text-cool-gray-500"
    >
      {tenantName === 'Chubb' ? (
        <>
          If you are a California resident, please read our{' '}
          <a
            className="underline text-blue-600 hover:text-blue-700 whitespace-nowrap"
            href="https://www.chubb.com/us-en/online-privacy-policy.html#notice-at-collection"
            target="_blank"
            rel="noreferrer noopener"
          >
            Notice of Collection
          </a>
          .
        </>
      ) : null}{' '}
      By clicking &quot;{continueButtonText},&quot; you acknowledge the{' '}
      {tenantName === 'Chubb' ? (
        <>
          <a
            className="underline text-blue-600 hover:text-blue-700 whitespace-nowrap"
            href="https://www.chubb.com/us-en/online-privacy-policy.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            Chubb Privacy Policy
          </a>{' '}
          and agree to the{' '}
          <a
            className="underline text-blue-600 hover:text-blue-700 whitespace-nowrap"
            href="https://www.chubb.com/us-en/terms-of-use.html"
            target="_blank"
            rel="noreferrer noopener"
          >
            Chubb Terms of Use
          </a>
          . By clicking &quot;{continueButtonText},&quot; you also acknowledge
          the
        </>
      ) : null}{' '}
      Assured{' '}
      <Link
        to="/privacy"
        target="_blank"
        className="underline font-bold uppercase text-blue-600 hover:text-blue-700"
      >
        Privacy&nbsp;Policy
      </Link>
      , agree to the Assured{' '}
      <Link
        to="/terms"
        target="_blank"
        className="underline font-bold uppercase text-blue-600 hover:text-blue-700"
      >
        Terms of Service
      </Link>
      , including the arbitration provision, and waive the right to a trial by
      jury or to participate in any class action or representative proceeding
      against Assured.{' '}
      {tenantName === 'GEICO' || tenantName === 'Progressive' ? (
        <>
          By clicking &quot;{continueButtonText},&quot; you are not impacting
          your claim with {tenantName}.
        </>
      ) : (
        <>
          By clicking &quot;{continueButtonText},&quot; you are not waiving any
          such rights in reference to your claim with{' '}
          {tenantName || 'your insurer'}.
        </>
      )}{' '}
      {tenantName === 'Selective' ? (
        <>
          By clicking &quot;{continueButtonText},&quot; you agree to receive
          texts about the status of your online claim submission from or on
          behalf of Selective, including from Assured on behalf of Selective.
          Msg and data rates may apply; msg freq varies. Reply HELP for help or
          STOP to opt-out of communicating with Selective about your claim(s)
          via the Assured platform.
        </>
      ) : tenantName === 'Progressive' || tenantName === 'Chubb' ? (
        <>
          <span className="font-bold">
            You also agree to receive automated texts at the phone number you
            provided above about the status of your online claim submission from
            or on behalf of {tenantName || 'your insurer'}.
          </span>
          {!hideExtendedTerms && tenantName === 'Progressive' ? (
            <>
              {' '}
              <a
                className="underline text-blue-600 hover:text-blue-700"
                href="https://www.progressive.com/support/legal/state-fraud-warnings/?theme=neutral"
                target="_blank"
                rel="noreferrer noopener"
              >
                State Fraud Warnings
              </a>
              . For CA residents only:{' '}
              <a
                className="underline text-blue-600 hover:text-blue-700"
                href="https://www.progressive.com/privacy/do-not-sell-my-information/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Do Not Sell or Share My Personal Information
              </a>{' '}
              &middot;{' '}
              <a
                className="underline text-blue-600 hover:text-blue-700"
                href="https://www.progressive.com/privacy/privacy-data-request/"
                target="_blank"
                rel="noreferrer noopener"
              >
                Notice at Collection
              </a>
            </>
          ) : null}
        </>
      ) : (
        <>
          You also agree to receive texts about the status of your online claim
          submission from or on behalf of {tenantName || 'your insurer'}.
        </>
      )}
      {tenantName === 'Chubb' && !hideExtendedTerms ? (
        <>
          {' '}
          <a
            className="underline text-blue-600 hover:text-blue-700 whitespace-nowrap"
            href="https://www.chubb.com/content/dam/chubb-sites/chubb-com/us-en/claims/fraud-warning-notice_smallbusiness_04032023.pdf"
            target="_blank"
            rel="noreferrer noopener"
          >
            State Fraud Warnings
          </a>{' '}
          <a
            className="underline text-blue-600 hover:text-blue-700 whitespace-nowrap"
            href="https://www.chubb.com/us-en/online-privacy-policy.html#CCPA-section"
            target="_blank"
            rel="noreferrer noopener"
          >
            CA Privacy Policy
          </a>{' '}
        </>
      ) : null}
    </div>
  );
}
