import React from 'react';

import { stepComponentDataTestIdBuilder } from '../helpers/stepComponentDataTestIdBuilder';
import {
  makeAriaLabeledBy,
  makeStepComponentFieldID,
} from '../helpers/stringsUtils';
import {
  StepComponentControlsBackHookProps,
  StepComponentControlsErrorProps,
  StepComponentControlsTitleProps,
  StepComponentOtherValue,
  StepComponentSharedProps,
  StepComponentShowsPrefillProps,
  StepComponentSourceValue,
} from '../types';
import { AnyStepComponentSpec } from '../types/step-components';
import { useStep } from './StepRenderer';
import { useDisabledWhen } from './hooks/useDisabledWhen';

type StepComponentRendererProps = StepComponentSharedProps<
  AnyStepComponentSpec,
  any
> &
  Partial<
    StepComponentOtherValue<any> &
      StepComponentSourceValue<any> &
      StepComponentShowsPrefillProps &
      StepComponentControlsBackHookProps &
      StepComponentControlsErrorProps &
      StepComponentControlsTitleProps
  > &
  Record<string, any>;

export const StepComponentRenderer: React.FC<StepComponentRendererProps> = ({
  step_component,
  primaryValue,
  updateValue,
  className,
  allValues,
  disabled,
  ...rest
}) => {
  const { registry } = useStep();
  let Component = registry[step_component.type];
  let Widget =
    step_component.widget && registry.widgets[step_component.widget.type];

  const disabledByPeers = useDisabledWhen({ step_component, allValues });

  return (
    <React.Fragment>
      {step_component.widget && Widget ? (
        <Widget
          className="ClaimWorkflowInner"
          widget={step_component.widget}
          dataTestId={stepComponentDataTestIdBuilder(step_component)}
        />
      ) : null}
      {Component ? (
        <Component
          aria-labelledby={makeAriaLabeledBy(step_component)}
          key={step_component.id}
          id={makeStepComponentFieldID(step_component)}
          className={
            typeof className !== 'undefined'
              ? className
              : Widget != null
              ? 'ClaimWorkflowInnerDelay'
              : 'ClaimWorkflowInner'
          }
          step_component={step_component}
          primaryValue={primaryValue}
          updateValue={updateValue}
          Component={Component}
          allValues={allValues}
          disabled={disabled || disabledByPeers}
          dataTestId={stepComponentDataTestIdBuilder(step_component)}
          {...rest}
        />
      ) : (
        <div>{JSON.stringify(step_component)}</div>
      )}
    </React.Fragment>
  );
};

export default StepComponentRenderer;
