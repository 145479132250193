import { SSNMask } from '../SSNMask';
import {
  StringInputVariety,
  StringInputVarietyProps,
  StringInputVarietyResult,
} from './types';

export const ssn: StringInputVariety = ({
  step_component,
  updateValue,
}: StringInputVarietyProps): StringInputVarietyResult => {
  const Component = SSNMask;
  return {
    Component,
    additionalProps: { step_component, updateValue },
    narrow: !!step_component.narrow,
  };
};
