import type { IncidentLocationEntryWorkflowState } from '@assured/shared-types/ClaimWorkflow/IncidentLocationWorkflowState';
import type { StepComponentSpec } from '../stepComponentSpec';
import { LocationFragment } from '@assured/shared-types/Location/Location';

export enum IntersectionWizardScreen {
  // Sidekick
  stateCity,
  bothStreets,

  // Self-service
  // state, not used (handled by standard workflow)
  // city, not used (handled by standard workflow)
  street1,
  street2,
}

export interface IntersectionWizardFields {
  screen: IntersectionWizardScreen;
  workflowState: Pick<
    IncidentLocationEntryWorkflowState,
    | 'state'
    | 'city'
    | 'intersectionStreet1'
    | 'intersectionStreet2'
    | 'geocodedCityState'
  >;
  existing_location?: LocationFragment | string;
}

type IntersectionWizardStepComponentSpec = StepComponentSpec<
  IntersectionWizardFields,
  'intersection_wizard'
>;

export default IntersectionWizardStepComponentSpec;
