import { gql } from '@apollo/client';

export const BEGIN_2_FAC_AUTH = gql`
  mutation Begin2FacAuth {
    begin2FacAuth {
      success
      phoneNumber
    }
  }
`;
