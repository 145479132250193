import { AnyStepComponentSpec } from '../types/step-components';

export const stepComponentDataTestIdBuilder = (
  step_component: AnyStepComponentSpec,
  additionalArgs?: string | number | string[],
) => {
  if (step_component.dataTestId) {
    return step_component.dataTestId;
  }
  let args = [];
  if (step_component.field) {
    args.push(step_component.field);
  }
  if ('mode' in step_component) {
    args.push(step_component.mode);
  }
  args.push('input');
  if (additionalArgs) {
    const argArray = Array.isArray(additionalArgs)
      ? additionalArgs
      : [additionalArgs.toString()];
    args = [...args, ...argArray];
  }
  return args.join('_');
};
