import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { ApolloError } from '@apollo/client';

import { useAuth } from '../hooks';
import GenericErrorPage from './GenericErrorPage';
import PageLoader from './PageLoader';

interface WrapperComponentInput {
  Component: React.ComponentType;
  authenticationRequired?: boolean;
  location: Location;
  [key: string]: any;
}

const WrapperComponent: React.FC<WrapperComponentInput> = ({
  Component,
  authenticationRequired,
  path,
  ...rest
}) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth();

  useEffect(() => {
    if (authenticationRequired && !loading && !isAuthenticated) {
      loginWithRedirect({
        appState: {
          target: location.pathname,
          search: location.search,
        },
      });
    }
  }, [authenticationRequired, loading, isAuthenticated]);

  if (loading) {
    return <PageLoader />;
  }

  if (authenticationRequired && !isAuthenticated) {
    return <GenericErrorPage isExpiredToken />;
  }
  return <Component {...rest} />;
};

interface RouterProps extends RouteProps {
  authenticationRequired?: boolean;
  [key: string]: any;
}

const Router: React.FC<RouterProps> = ({
  component,
  path,
  location,
  authenticationRequired,
  ...rest
}) => {
  const render = (props: any) => (
    <WrapperComponent
      location={location}
      Component={component}
      authenticationRequired={authenticationRequired}
      {...props}
    />
  );
  return <Route path={path} render={render} {...rest} />;
};

export default Router;
