import classNames from 'classnames';
import React from 'react';

import {
  makeStepComponentFieldID,
  makeStepComponentLabelID,
} from '@assured/step-renderer/helpers/stringsUtils';

interface LabelProps {
  step_component: {
    label?: string;
    id?: string;
    field?: string;
    required?: boolean;
    optional?: boolean;
  };
  htmlFor?: string | null;
  className?: string;
}

const Label: React.FC<LabelProps> = ({ step_component, className }) => {
  if (!step_component?.label) return null;

  return (
    <label
      className={classNames(
        'block text-cool-gray-700 text-sm font-semibold mb-2 text-left',
        className,
      )}
      id={makeStepComponentLabelID(step_component) || ''}
      htmlFor={makeStepComponentFieldID(step_component) || ''}
    >
      {step_component.label}
      {step_component.optional ? (
        <span className="ml-2 text-cool-gray-400 text-xs uppercase">
          Optional
        </span>
      ) : null}
      {/* {step_component.required ? <span className="text-red-500">*</span> : null} */}
    </label>
  );
};
export default Label;
