import { useEffect, useRef, useState } from 'react';

import { HealthProviderType } from '@assured/shared-types/HealthProvider';
import { HealthSearchResult } from '@assured/shared-types/Services';

const QUERIES = {
  npi_individual: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_idv/v3/search`,
    q: '',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.zip',
      'addr_practice.phone',
      'addr_practice.state',
      'addr_practice.city',
    ],
  },
  npi_organization: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search`,
    q: '',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.zip',
      'addr_practice.phone',
      'addr_practice.state',
      'addr_practice.city',
    ],
  },
  npi_hospital: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/npi_org/v3/search`,
    q: 'provider_type:(*hospital* OR *clinic* OR *health*)',
    fields: [
      'NPI',
      'name.full',
      'provider_type',
      'addr_practice.full',
      'addr_practice.zip',
      'addr_practice.phone',
      'addr_practice.state',
      'addr_practice.city',
    ],
  },
  rxterm: {
    endpoint: `https://clinicaltables.nlm.nih.gov/api/rxterms/v3/search`,
    q: '',
    fields: [
      'SXDG_RXCUI',
      'DISPLAY_NAME',
      'STRENGTHS_AND_FORMS',
      'RXCUIS',
      'DISPLAY_NAME_SYNONYM',
    ],
  },
} as const;

export type Mode = keyof typeof QUERIES;

// Remove "duplicate" looking results
const N_CHARS_NAME_MATCH_DEFAULT = 5;
const N_CHARS_NAME_MATCH_ENHANCED = 10;
export const getTransformedHealthSearchResults = (
  results: HealthProviderType[],
  showMoreResults?: boolean,
) => {
  const nameMatchMin = showMoreResults
    ? N_CHARS_NAME_MATCH_ENHANCED
    : N_CHARS_NAME_MATCH_DEFAULT;
  return (
    results
      .filter((r, i) => {
        const firstOccurrence = results.findIndex(candidate => {
          const zipMatch =
            'addr_practice.zip' in r &&
            candidate['addr_practice.zip'] === r['addr_practice.zip'];
          const cityStateMatch =
            'addr_practice.city' in r &&
            'addr_practice.state' in r &&
            candidate['addr_practice.city'] === r['addr_practice.city'] &&
            candidate['addr_practice.state'] === r['addr_practice.state'];
          const nameMatch =
            'name.full' in r &&
            candidate['name.full']
              ?.toLowerCase()
              ?.substring(0, nameMatchMin) ===
              r['name.full'].toLowerCase()?.substring(0, nameMatchMin);
          if (showMoreResults) {
            return zipMatch && cityStateMatch && nameMatch;
          }
          return (zipMatch || cityStateMatch) && nameMatch;
        });

        if (firstOccurrence !== -1 && firstOccurrence !== i) {
          return false;
        }

        return true;
      })
      // Max 5 best results for UX to prevent long scroll height
      .slice(0, 5)
  );
};

const query = async (mode: Mode, term: string): Promise<HealthSearchResult> => {
  if (!term) {
    return { term, results: [] };
  }

  const spec = QUERIES[mode];
  const params = new URLSearchParams({
    terms: term,
    q: spec.q,
    df: spec.fields.join(','),
    maxList: '8',
  });
  const res = await fetch(`${spec.endpoint}?${params}`).then(r => r.json());
  const [total_matched, codes, _, fields, __]: [
    number,
    string[],
    undefined,
    string[],
    undefined,
  ] = res;

  let results = codes.map((code, resultIndex) => {
    return {
      _id: code,
      ...(spec.fields as unknown as string[]).reduce(
        (obj, k, fieldIndex) => ({
          ...obj,
          [k]: fields[resultIndex][fieldIndex],
        }),
        {},
      ),
    } as HealthProviderType;
  });

  results = getTransformedHealthSearchResults(results);

  return {
    term,
    results,
  };
};

export function useHealthSearchNlm({
  mode,
  searchTerm,
  skip,
}: {
  mode: Mode;
  searchTerm: string;
  skip?: boolean;
}): [
  HealthSearchResult | null,
  (searchRes: HealthSearchResult | null) => void,
] {
  const [searchRes, setSearchRes] = useState<HealthSearchResult | null>(null);

  // Since the results of the query() call can come back in a nondeterministic order,
  // only apply a result if a later result has not yet been returned. This prevents
  // race conditions where stale data overwrites the res *after* newer data has already
  // been loaded successfully.
  const queryOrderCounter = useRef({ applied: -1, dispatched: -1 });
  useEffect(() => {
    if (!skip) {
      const queryIdx = ++queryOrderCounter.current.dispatched;
      query(mode, searchTerm).then(r => {
        if (queryIdx > queryOrderCounter.current.applied) {
          queryOrderCounter.current.applied = queryIdx;
          setSearchRes(r);
        }
      });
    }
  }, [searchTerm, skip]);

  return [searchRes, setSearchRes];
}
