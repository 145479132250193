import { useLDClient } from 'launchdarkly-react-client-sdk';

import { StepComponentFC } from '@assured/step-renderer';

import StringV1 from './StringV1';
import { String as StringV2 } from './StringV2';
import { StringProps } from './varieties/types';

const String: StepComponentFC<StringProps> = ({
  step_component,
  primaryValue,
  updateValue,
  attemptSubmit,
  error,
  showErrorMessages,
  className,
  showsPrefill,
}) => {
  const ldClient = useLDClient();
  const useStringV2 = ldClient?.variation(
    'enable-string-input-v2-TEST-8-29-24',
    false,
  );

  return useStringV2 ? (
    <StringV2
      step_component={step_component}
      primaryValue={primaryValue}
      updateValue={updateValue}
      attemptSubmit={attemptSubmit}
      error={error}
      showErrorMessages={showErrorMessages}
      className={className}
      showsPrefill={showsPrefill}
    />
  ) : (
    <StringV1
      step_component={step_component}
      primaryValue={primaryValue}
      updateValue={updateValue}
      attemptSubmit={attemptSubmit}
      error={error}
      showErrorMessages={showErrorMessages}
      className={className}
      showsPrefill={showsPrefill}
    />
  );
};

export default String;
String.stepConfig = {
  manualSubmit: true,
  controlsError: true,
};
